import React from "react";
import classNames from "classnames";

import {
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  makeStyles,
} from '@material-ui/core';

import Close from "@material-ui/icons/Close";
import { paymentSelector } from "modules/Finance/Pay/selectors/payments";
import { useSelector } from "react-redux";
import SimplePaymentDialogCheckout from './components/SimplePaymentDialogCheckout';

const useStyles = makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: "98%",
  },
  buttonClose: {
    position: "absolute",
    right: 0,
  },
}));

export default ({ open, onClose, setIsLoading, onSuccess, onError, selectedMatricula, setAcceptanceTermObj }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { loading, waitingRede } = useSelector(paymentSelector);

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="automatic-debit-dialog-title"
      disableBackdropClick
      classes={{ paper: !fullScreen && classes.paperScrollPaper }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          disabled={loading || waitingRede}
          className={classNames(!fullScreen && classes.buttonClose)}
          aria-label="close-payment"
          id="ico-mdl-pag-fch"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <SimplePaymentDialogCheckout setIsLoading={setIsLoading} onSuccess={onSuccess} onError={onError} selectedMatricula={selectedMatricula} setAcceptanceTermObj={setAcceptanceTermObj} />
    </Dialog>
  );
};
