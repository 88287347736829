const loginTypes = {
  SSOV1: 'SSOV1',
  SSO: 'SSO',
  EXTERNAL: 'EXTERNAL',
  EXTERNAL_SMO: 'EXTERNAL_SMO',
  SMO: 'SMO',
  LOGIN_AD: 'LOGIN_AD',
}

Object.freeze(loginTypes)

export default loginTypes