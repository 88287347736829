import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ErrorAlert from '@material-ui/icons/ErrorOutline'
import WarningAlert from '@material-ui/icons/WarningOutlined'
import SuccessAlert from '@material-ui/icons/CheckCircleOutline'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 230,
    padding: '15px 0',
    textDecoration: 'none',
    cursor: 'auto',
    display: 'block'
  },
  rootNoPadding: {
    maxHeight: 230,
  },
  verticalCenter: {
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  icon: {
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 48
  },
  text: {
    padding: '10px 20px'
  },
  bannerImg: {
    maxHeight: 200
  },
  pointer: {
    cursor: 'pointer'
  },
  description: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      textAlign: 'center'
    },
  }
}))

export const BannerType = {
  Error: '1',
  Warning: '2',
  Success: '3'
}
  
export const BannerTypeData = {
  [BannerType.Error]: {
    name: 'Erro',
    color: '#ef5350',
    icon: ErrorAlert
  },
  [BannerType.Success]: {
    name: 'Sucesso',
    color: '#66bb6A',
    icon: SuccessAlert
  },
  [BannerType.Warning]: {
    name: 'Atenção',
    color: '#ffbb55',
    icon: WarningAlert
  }
}

export default (props) => {
  const classes = useStyles()
  const { messagingType: type, urlClick, openInNewTab, description } = props
  const { icon: Icon, color } = BannerTypeData[type] || BannerTypeData[1]
    
  const onClick = ev => !urlClick && ev.preventDefault()
  
  return (
    <Paper
      data-tut='tour-notification'
      onClick={onClick} component='a' href={urlClick} 
      className={clsx(classes.root, urlClick && classes.pointer)} 
      target={openInNewTab ? '_blank' : '_self'}
      style={{ backgroundColor: color }}>
      <Grid container>
        <Grid className={clsx(classes.icon, classes.verticalCenter)} item xs={12} sm={2} >
          {Icon && <Icon fontSize={'large'} />}
        </Grid>
        <Grid className={clsx(classes.text, classes.verticalCenter)} item xs={12} sm={10} >
          <Typography component='div' className={classes.description}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
  