import { reduceReducers } from 'utils/API/reducer/reducer-utils'
import {
  INFO,
  RESET_INFO,
  REFRESH_INFO
} from '../constants'
import { apiReducer } from 'utils/API'
import { map } from 'lodash'
import moment from 'moment'
import { currency } from 'utils/utils'

const mapSuccessData = (action) => map(action.data, el => ({
  ...el,
  vencimentoFormatted: moment(el.vencimento).format('DD/MM/YYYY'),
  valorFormatted: currency(el.valor),
}))

export default reduceReducers(
  apiReducer({
    defaultData: [],
    entity: INFO,
    mapSuccessData,
    resetActions: [RESET_INFO],
  }), (states, action) => {
    switch (action.type) {
    case REFRESH_INFO:
      return {
        ...states,
        data: [...action.data, ...states.data]
      }
    default:
      return states
    }
  })
