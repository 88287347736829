import { apiGet } from 'utils/API/actions/request'
import { ACCEPT_TERM_NEGOTIATION } from '../constants'

export const getAcceptTermNegotiation = (appId, invoiceNumber, agreementId) => apiGet({
  entity: ACCEPT_TERM_NEGOTIATION,
  url: `/acceptanceterm/${appId}/term`,
  config: {
    params: { appId, invoiceNumber, agreementId }
  },
  isChainable: true
})
