import { appIdSelector, accessIdSelector } from 'modules/Login/selectors/user'
import { fetchUserMessage } from 'modules/Home/services/messaging'
import { isMockMessage, isFetchMessage } from 'modules/Home/selectors/userMessage'

const urlRegex = /messaging\/clientcode/

export const addMock = (mock) => {
  mock
    .onGet(urlRegex).reply(200, response)
}

export const requestApi = (store) => {
  const accessId = accessIdSelector(store.getState())
  const appid = appIdSelector(store.getState())
  store.dispatch(fetchUserMessage(appid, accessId))

}

const initializeMock = (mock, store) => {
  if (isFetchMessage(store.getState())) {
    requestApi(store)
  } else if (isMockMessage(store.getState())) {
    addMock(mock, store)
    requestApi(store)
  }
}

const response = {
  'response': {
    'idMessaging': '39',
    'messagingType': '3',
    'description': 'Exemplo de mensagem!',
    'html': null,
    'imageUrl': null,
    'urlClick': 'http://www.gmail.com',
    'expirationStartDate': '2019-09-24T14:54:00',
    'expirationEndDate': '2019-09-28T14:54:00',
    'isActive': true,
    'openInNewTab': false,
    'createdUser': 'SEBC-057230',
    'createdDate': '2019-09-24T17:58:32.263',
    'modifiedUser': 'SEBC-057230',
    'modifiedDate': '2019-09-25T20:17:33.59'
  }
}

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}