import { apiPost , apiGet } from 'utils/API/actions/request'
import { INCOME_TAX_DECLARATIONS, STUDENTS_DECLARATIONS } from '../constants'

export const getIncomeTax = (appId, data) => apiPost({
  entity: INCOME_TAX_DECLARATIONS,
  url: `/incometax/${appId}`,
  data,
  isChainable: true
})

export const getIncomeTaxTotal = (appId, data) => apiPost({
  entity: INCOME_TAX_DECLARATIONS,
  url: `/incometax/${appId}`,
  data,
  isChainable: true
})

export const getStudents = (appId, clientcode) => apiGet({
  entity: STUDENTS_DECLARATIONS,
  url: `/incometax/${appId}?clientCode=${clientcode}`,
  isChainable: true
})