
import {  makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  billetForm: {
    display: 'contents',
  },
  billetFormFlex: {
    display: 'flex',
  },
  billetFormItem: {
    minHeight: 70
  },
  billetFormPaymentTotal : {
    display: 'inline',
    fontWeight: 'bolder',
  },
  billetFormInput: {
    paddingRight: theme.spacing(1)
  },
  billetFormButton: {
    margin: theme.spacing(1),
  },
  billetFormLoading: {
    width: 40,
    height: 40
  },
  billetFormType: {
    marginBottom: theme.spacing(1),
    minHeight: 0,
    paddingLeft: 8,
  },
  root: {
    color: '#757575',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    '&$disabled': {
      background: 'rgba(0, 0, 0, 0.05)',
      color: '#757575',
      boxShadow: 'none',
    }
  },
  disabled: {
    color: '#757575',
  },
  rootTotal: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'grey',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    borderRadius: 4,
  },
}))
