import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseAdminSelector = prop('admin')

export const smsSelector = pipe(baseAdminSelector, prop('sms'))

export const smsDataSelector = getAPIDataSelector(smsSelector)

export const hasLoadedSmsSelector = hasLoadedAPISelector(smsSelector)

export const hasErrorSmsSelector = hasErrorAPISelector(smsSelector)

export const isLoadingSmsSelector = isLoadingAPISelector(smsSelector)
