import React, { Fragment } from "react";
import classNames from "classnames";
import {
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import useStyles from "./style";

export default ({ open, onClose, selectedUnidade, discount = 0 }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function handleClose() {
    onClose();
  }


  return (
    <Dialog
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="automatic-debit-dialog-title"
      disableBackdropClick
      classes={{ paper: !fullScreen && classes.paperScrollPaper }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          className={classNames(!fullScreen && classes.buttonClose)}
          aria-label="close-payment"
          id="ico-mdl-pag-fch"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Grid
        container
        justify="space-between"
        direction="row"
        alignItems="center"
        className={classes.content}
      >
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            Débito Automático
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.body}>
          <Typography className={classes.subtitle}>Débito em conta</Typography>

          <br />

          <Typography>
            Nesta opção, o pagamento dos serviços <b>é automático</b> e vem
            debitado mês a mês na sua conta.
          </Typography>

          <br />

          {(discount > 0) && (
          <Typography>
            Além da comodidade, você ganha <b>{discount}% de desconto</b> nas parcelas do
            serviço regular e não precisa se preocupar com datas de vencimento
            <b>!</b> 😉
          </Typography>
          )}

          <br />

          <Typography>
            ⚠ <b>Não esqueça</b> de autorizar este débito junto ao seu Banco,
            esta autorização poderá ser anual ou mensal. Caso não haja
            autorização, não será possível realizar o débito.
          </Typography>

          <br />

          <Typography>
            Após a conclusão da operação haverá período de carência para nova
            alteração.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.buttons}>
          <Button
            onClick={handleClose}
            className={classes.button}
            variant="outlined"
            color="secondary"
          >
            Fechar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
