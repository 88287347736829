import React from 'react'
import { FormControlLabel, Radio } from '@material-ui/core'

export default ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <div>
      
    <FormControlLabel value={props.label} label={props.label} control={<Radio
      {...props}
    />} />
    {touched[field.name] &&
        errors[field.name] && <div className='error'>{errors[field.name]}</div>}
  </div>
)
