import { apiPost } from "utils/API/actions/request";
import { ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE } from '../constants/index';

export const acceptTermAgreementPaymentType = (appId, acceptanceTermJson) =>
  apiPost({
    entity: ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE,
    url: `/acceptanceterm/${appId}/acceptance-term-change-payment-mode`,
    data: {
      acceptanceTerm: acceptanceTermJson
    },
    isChainable: true,
  });
