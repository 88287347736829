import { apiReducer } from 'utils/API'
import { reduceReducers } from 'utils/API/reducer/reducer-utils'
import { EXPIRATED_FINANCIALS, SELECTED_EXPIRATED_FINANCIALS } from '../constants'
import { map } from 'lodash'
import moment from 'moment'
import { currency } from 'utils/utils'
import { RESET_ACCESS } from 'layouts/constants'
import { USER_CLEAR } from 'modules/Login/constants'

const mapSuccessData = (action, states) => {
  return (
    map(action.data, el => ({
      ...el,
      studentnameFormatted: el.studentname ? el.studentname.split(' ')[0] : '---',
      yearMonthFormatted: moment(el.transactionDate).format('MM/YY'),
      expirationdateFormatted: moment(el.expirationdate).format('DD/MM/YYYY'),
      documentvalueFormatted: currency(el.documentvalue),
      correctvalueFormatted: currency(el.correctvalue),
    }))
  )
}

const mapError = (action, states) => ({
  ...states,
  data: []
})

export default reduceReducers(
  apiReducer({
    entity: EXPIRATED_FINANCIALS,
    defaultData: [],
    mapSuccessData,
    mapError,
    resetActions: [USER_CLEAR, RESET_ACCESS],
  }),
  (states, action) => {
    switch (action.type) {
    case SELECTED_EXPIRATED_FINANCIALS:
      return {
        ...states,
        dataSelected: action.data
      }
    default:
      return states
    }
  })
