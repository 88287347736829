import { forEach } from 'lodash'
import { defaultNames } from '../registration'

import { accessIdSelector, appIdSelector } from 'modules/Login/selectors/user'
import { fetchExpirated, fetchFinancial } from 'modules/Negotiation/Finance/actions'
import { isMockExpiredSelected, isFetchExpiredSelected } from 'modules/Negotiation/Finance/selectors/expirated'
import { getCompanyInformations } from 'modules/Negotiation/Finance/services/company'
import { PAY_WAYS } from 'modules/Negotiation/Finance/utils'
import create from './createFinancial'
import company from './company'

const urlRegex = /installment\/*.*\/getrenegotiationinstallment/

export const addMock = (mock, store) => {
  const elements = []

  const students = defaultNames(store)
  forEach(students, name => elements.push(...response(name)))

  mock
    .onGet(urlRegex).reply(200, {
      'response': elements
    })
}

export const requestApi = (store, mock, isMock = false) => {
  const accessId = accessIdSelector(store.getState())
  const appId = appIdSelector(store.getState())

  store.dispatch(fetchExpirated({ accessId })).then(result => {
    const [item] = result
    if (isMock) {
      create.addMock(mock, store)
      company.addMock(mock, store)
    }
    store.dispatch(fetchFinancial(1, result, 0, PAY_WAYS.CARD))
    store.dispatch(getCompanyInformations(appId, item.unityid))
  })
}

const initializeMock = (mock, store) => {
  if (isFetchExpiredSelected(store.getState())) {
    requestApi(store, mock)
  }
  else if (isMockExpiredSelected(store.getState())) {
    addMock(mock, store)
    requestApi(store, mock, true)
  }
}

const response = (studentname) => (
  [
    {
      'transactionDate': '2020-01-01T00:00:00',
      'journaltransrecid': 5653047250,
      'installmentfine': '33.8400000000000000',
      'dailyinterest': '0.5600000000000000',
      'punctualitydiscount1': '80.6500000000000000',
      'punctualitydiscount2': '0.0000000000000000',
      'punctualitydiscount3': '0.0000000000000000',
      'rtacode': null,
      'liquidated': null,
      'digitableline': '03399.88305 83500.000019 14388.301013 3 81510000169165',
      'documentvalue': '1691.6500000000000000',
      'correctvalue': '1771.41',
      'studentname': studentname,
      'studentid': 'CDBC-020638',
      'studentgender': null,
      'registrationDate': '9/5/2019',
      'courseDescription': '2020 - 1401 - EFII - 9º ANO - M',
      'renegotiation': true,
      'paymentWayId': null,
      'paymentavailability': 'EXPIRED',
      'idacceptanceterm': null,
      'billetDemonstrativeGroupDetails': null,
      'schoolyear': '2020',
      'enables_installment_payment': false,
      'maximum_installment_number': null,
      'dataareaid': 'CDB',
      'referenceid': '2020-01-31CDBC-005756CDB-0386679BOL_CDB56530472501',
      'servicedescription': '',
      'registrationid': 'CDB-0386679',
      'unityname': 'DOM BOSCO AHU',
      'accountresponsible': 'CDBC-005756',
      'unityid': '1401',
      'paymenttype': 'BOL_CDB',
      'expirationdate': '2020-01-31T00:00:00',
      'accord': null,
      'internalnumber': '000000114388-3',
      'invoice': 'CDB-0386679 1/1',
      'paymentstatus': 'Cancel',
      'scholarshipdiscount': '-604.1600000000000000',
      'servicegroup': '1',
      'automaticdebit': false,
      'salesreceiptnumber': null,
      'paymentdate': null,
      'flag': null,
      'billettransId': '0'
    }
  ])

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}
