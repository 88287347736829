import Payment from 'payment'

export default () => {
  Payment.removeFromCardArray('hipercard')
  Payment.addToCardArray({
    type: 'hipercard',
    pattern: /^(384100|384140|384160|606282|637568|60(?!11))/,
    format: /(\d{1,4})/g,
    length: [14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  })
  Payment.addToCardArray({
    type: 'hiper',
    pattern: /^637095/,
    format: /(\d{1,4})/g,
    length: [16],
    cvcLength: [3],
    luhn: true
  })
  Payment.addToCardArray({
    type: 'mastercard',
    pattern: /^502121/,
    format: /(\d{1,4})/g,
    length: [16],
    cvcLength: [3],
    luhn: true
  })
}