import { pipe, prop } from "ramda";
import { isEmpty } from "lodash";
import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from "utils/API/selectors";
import { createSelector } from "reselect";

const baseFinanceSelector = prop("finance");

export const paymentTypeSelectedSelector = pipe(
  baseFinanceSelector,
  prop("paymentTypes")
);

export const paymentTypeSelectedDataSelector = getAPIDataSelector(
  paymentTypeSelectedSelector
);

export const hasLoadedPaymentTypeSelectedSelector = hasLoadedAPISelector(
  paymentTypeSelectedSelector
);

export const hasErrorPaymentTypeSelectedSelector = hasErrorAPISelector(
  paymentTypeSelectedSelector
);

export const isLoadingPaymentTypeSelectedSelector = isLoadingAPISelector(
  paymentTypeSelectedSelector
);

export const isMockRegistration = createSelector(
  hasLoadedPaymentTypeSelectedSelector,
  hasErrorPaymentTypeSelectedSelector,
  paymentTypeSelectedDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
);

export const isFetchRegistration = createSelector(
  hasLoadedPaymentTypeSelectedSelector,
  isLoadingPaymentTypeSelectedSelector,
  hasErrorPaymentTypeSelectedSelector,
  paymentTypeSelectedDataSelector,
  (hasLoaded, isLoading, hasError, data) =>
    isEmpty(data) && !hasLoaded && !isLoading && !hasError
);
