import React from 'react'
import { useSelector } from 'react-redux'
import { FINANCIAL_ADMIN, hasAny } from 'utils/permissions'

import { permissionsSelector } from 'modules/Login/selectors/user'

const listAdmin = [FINANCIAL_ADMIN]

export const PermissionFilter = ({ list, children }) => {
  const permissions = useSelector(permissionsSelector)
  return hasAny(permissions, list) ? children : null
}

export const Admin = ({ children }) => (
  <PermissionFilter list={listAdmin}>
    {children}
  </PermissionFilter>)