import { combineReducers } from "redux";
import payment from "../Pay/reducers/payments";
import opened from "../Pay/reducers/opened";
import info from "../Pay/reducers/info";
import closed from "../Paid/reducers/closed";
import receipt from "../reducers/receipt";
import billet from "../Pay/reducers/billet";
import registrations from "../PaymentTypes/reducers/registrations";
import paymentTypes from "../PaymentTypes/reducers/paymentTypes";
import informationRecurrentCard from "../PaymentTypes/reducers/informationRecurrentCard";

export default combineReducers({
  payment,
  opened,
  info,
  billet,
  closed,
  receipt,
  registrations,
  paymentTypes,
  informationRecurrentCard,
});
