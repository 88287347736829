import React, { useEffect } from 'react'
import { Paper } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { featchDashboard } from './services/dashboard'
import { appIdSelector } from 'modules/Login/selectors/user'
import { dashboardDataSelector, isLoadingDashboardSelector } from './selectors/dashboard'
import { isNil } from 'ramda'
import Loading from 'components/loading'
import ErrorLoadingData from 'components/ErrorLoadingData'
import GraphDay from './component/graphDay'
import AdicionalInfos from './component/adicionalInfos'

export default () => {
  const dispatch = useDispatch()
  const appId = useSelector(appIdSelector)
  const data = useSelector(dashboardDataSelector)
  const isLoading = useSelector(isLoadingDashboardSelector)

  useEffect(() => {
    dispatch(featchDashboard(appId))
  }, [])

  if (isLoading) {
    return <Loading />
  }

  if (isNil(data)) {
    return <ErrorLoadingData id='dashboard-dialog-error' />
  }

  return (
    <>
      <AdicionalInfos />
      <Paper>
        <GraphDay />
      </Paper>
    </>
  )
}