import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseTaxSelector = prop('tax')

export const declarationSelector = pipe(baseTaxSelector, prop('declaration'))

export const declarationDataSelector = getAPIDataSelector(declarationSelector)

export const hasLoadedDeclarationSelector = hasLoadedAPISelector(declarationSelector)

export const hasErrorDeclarationSelector = hasErrorAPISelector(declarationSelector)

export const isLoadingDeclarationSelector = isLoadingAPISelector(declarationSelector)
