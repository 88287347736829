import { appIdSelector, accessIdSelectedSelector } from 'modules/Login/selectors/user'
import { isNil, forEach } from 'lodash'
import { isFetchStudents, isMockStudents } from 'modules/Tax/selectors/students'
import { getStudents } from 'modules/Tax/services/incometax'
import { defaultNames } from './registration'

const urlRegex = /incometax/
const addMock = (mock, store) => {
  const elements = []

  const students = defaultNames(store)
  forEach(students, studentname => elements.push(response(studentname)))
  
  mock
    .onGet(urlRegex).reply(200, 
      {
        'response': elements
        
      })
}

const requestApi = (store) => {
  const clientCode = accessIdSelectedSelector(store.getState())
  const appId = appIdSelector(store.getState())
  if (!isNil(clientCode)) {
    store.dispatch(getStudents(appId, clientCode))
  }
}

const initializeMock = (mock, store) => {
  if (isFetchStudents(store.getState())) {
    requestApi(store)
  } else if (isMockStudents(store.getState())) {
    addMock(mock, store)
  }
}

const response = (studentname) => ( {
  'clientCode': 'INTC-006021',
  'studentName': studentname,
  'studentCode': 'INTC-000212',
  'year': '2019'
})

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}
