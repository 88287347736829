import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const accessTokenState = atom({
  key: 'accessTokenState',
  default: '',
  effects: [
    persistAtomEffect
  ]
});
