import React from 'react'
import { textIntl } from 'utils/intl'
import { TextField } from 'formik-material-ui'
import { Grid } from '@material-ui/core'
import { FastField } from 'formik'

export default (props) => {

  const { classes } = props

  const validate = (value) => {
    let error
    if (!value) {
      error = textIntl('admin-send-sms-required')
    } 
    return error
  }
  

  return (
    <Grid item xs={6} className={classes.item}>
      <FastField
        InputLabelProps={{
          shrink: true,
        }}
        variant='outlined'
        validate={validate}
        type='number'
        label={textIntl('admin-send-sms-day-before')}
        name='daysToExpiration'
        id='daysToExpiration'
        fullWidth
        component={TextField}
      />
    </Grid>
  )
}