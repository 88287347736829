import { UI_APP_BAR_SHOW, UI_APP_BAR_HIDE } from '../constants'

const defaultState = {
  show: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
  case UI_APP_BAR_SHOW:
    return {
      ...state,
      show: true
    }
  case UI_APP_BAR_HIDE:
    return {
      ...state,
      show: false,
    }
  default:
    return state
  }
}