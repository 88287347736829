import React from 'react'
import { Avatar } from '@material-ui/core'

import avatarBoy from 'assets/img/avatar-boy.svg'
import avatarGirl from 'assets/img/avatar-girl.svg'
import avatarUndefined from 'assets/img/avatar-undefined.png'

const Genders = {
  Unknown: '0',
  Male: '1',
  Female: '2',
}
  
const GendersAvatar = {
  [Genders.Male]: avatarBoy,
  [Genders.Female]: avatarGirl,
  [Genders.Unknown]: avatarUndefined,
}

export default ({ gender, className }) => {
  const src = GendersAvatar[gender] || GendersAvatar[Genders.Unknown]
  return <Avatar src={src} className={className} />
}

  