import React from 'react'
import { FINANCIAL_TAX } from 'utils/permissions'
import { PATH_ROUTE } from '../../../routes'
import ContentTour from '../components/ContentTour'
import moment from 'moment'
import { textIntl } from 'utils/intl'

export default [
  {
    permissions: [FINANCIAL_TAX],
    selector: '[data-tut="tur-tax-menu"]',
    prevTo: PATH_ROUTE.NEGOTIATION_HISTORY,
    content: (props) => (
      <ContentTour
        title={textIntl('tour-declaration-menu')}
        content={textIntl('tour-declaration-menu-description', { date: moment().subtract(1, 'year').format('YYYY') } )}
      />
    )
  },
  {
    permissions: [FINANCIAL_TAX],
    selector: 'th.MuiTableCell-root.MuiTableCell-head:nth-child(3)',
    content: () => (
      <ContentTour
        title={textIntl('tour-declaration-tax')}
        content={textIntl('tour-declaration-tax-description', { date: moment().subtract(1, 'year').format('YYYY') } )}
      />
    ),
  },
  {
    permissions: [FINANCIAL_TAX],
    selector: 'th.MuiTableCell-root.MuiTableCell-head:nth-child(4)',
    content: () => (
      <ContentTour
        title={textIntl('tour-declaration-tax-total')}
        content={textIntl('tour-declaration-tax-total-description', { date: moment().subtract(1, 'year').format('YYYY') } )}
      />
    ),
  }
]