import MaskedInput from "react-text-mask";
import React from "react";

export function InputCpf(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ".",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ".",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
      ]}
      showMask={false}
      guide={false}
    />
  );
}
