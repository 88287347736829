import { combineReducers } from 'redux'
import messaging from '../Message/reducers/messaging'
import billet from '../Billet/reducers'
import sms from '../SMS/reducers'
import dashboard from '../Dashboard/reducers'
import configureInstallment from '../ConfigureInstallment/reducers/configureInstallment'

export default combineReducers({
  billet,
  configureInstallment,
  sms,
  dashboard,
  messaging,
})
