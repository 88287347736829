import { apiPost } from 'utils/API/actions/request'
import { CREATE_FINANCIAL, POST_FINANCE, PAYMENT_CREDIT_NEGOTIATION } from '../constants'

export const createNegotiation = (body, params) => apiPost({
  entity: CREATE_FINANCIAL,
  url: '/financial/createfinancialagreement',
  data: body,
  config: {
    params
  },
  isChainable: true
})


export const postAgreementBillet = (body, appId) => apiPost({
  entity: POST_FINANCE,
  url: `/financial/${appId}/postagreementbillet`,
  data: body,
  isChainable: true
})

export const postAgreementCredit = (body, appId, cpfCnpj) => apiPost({
  entity: POST_FINANCE,
  url: `/financial/${appId}/postagreementcreditcard?cpfCnpj=${cpfCnpj}`,
  data: body,
  isChainable: true
})

export const paymentRede = (body, cpfCnpj) => apiPost({
  entity: PAYMENT_CREDIT_NEGOTIATION,
  url: `/financial/paymentgetnetnegotiation?cpfCnpj=${cpfCnpj}`,
  data: body,
  analytics: {
    info: 'crédito'
  },
  isChainable: true
})
