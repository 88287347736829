
import { apiReducer } from 'utils/API'
import { reduceReducers } from 'utils/API/reducer/reducer-utils'
import { sortBy } from 'lodash'
import { USER_CLEAR, USER_SET_ACCESS, USER_CLEAR_ACCESS } from 'modules/Login/constants'
import { REGISTRATION_EXPIRED, SELECTED_REGISTRATION_EXPIRED } from '../constants'
import { RESET_ACCESS } from 'layouts/constants'

const mapSuccessData = (action, states) => {
  const registrations = action.data ? sortBy(action.data, ['studentname']) : []
  return registrations
}

export default reduceReducers(
  apiReducer({
    entity: REGISTRATION_EXPIRED,
    resetActions: [USER_CLEAR, USER_SET_ACCESS, USER_CLEAR_ACCESS, RESET_ACCESS],
    mapSuccessData
  }),
  (states, action) => {
    switch (action.type) {
    case SELECTED_REGISTRATION_EXPIRED:
      return {
        ...states,
        dataSelected: action.data
      }
    default:
      return states
    }
  })
