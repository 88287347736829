import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import Table from 'components/Table'
import { MTableAction } from 'material-table'
import { appIdSelector, accessIdSelector, userSelectedSelector, cpfCnpjSelector } from 'modules/Login/selectors/user'
import ReceiptDialog from '../components/ReceiptDialog'
import { fetchClosed, resetPaymentClosed } from './actions'
import { closedDataSelector, isLoadingClosedSelector } from './selectors/closed'
import { smColumns, otherMediumColumns, otherColumns } from './constants'
import CustomCellHighlight from 'components/CustomCellHighlight'
import {useRecoilValue} from "recoil";
import {unidadeGetResponseState} from "../../../recoil/atoms/unidadeGetResponseState";
import {Redirect} from "react-router-dom";
import {PATH_ROUTE} from "../../../routes";
import Alert from "@material-ui/lab/Alert";
// import { PAYMENT_TYPE } from '../constants'
// import { receiptLoadingSelector, fetchReceipt } from '../components/ReceiptDialog/reducer/installment'

// const useStyles = makeStyles(() => ({
//   receiptIcon: {
//     width: 24,
//     height: 24
//   }
// }))

const CustomAction = (props) => {
  const { id } = props.data.tableData
  return <div id={id}>
    <MTableAction {...props} />
  </div>
}

// const SmallCircularProgress = () => (
//   <span style={{ fontSize: 18 }}>
//     <CircularProgress size={18} />
//   </span>)

const defaultReceipt = { open: false, correctvalue: 0 }

const Paid = () => {
  const appid = useSelector(appIdSelector)
  const dispatch = useDispatch()
  const data = useSelector(closedDataSelector)
  const isLoadingData = useSelector(isLoadingClosedSelector)
  const accessId = useSelector(accessIdSelector)
  const cpfCnpj = useSelector(cpfCnpjSelector)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const tableRef = React.useRef()
  const [openModal, setOpenModal] = React.useState(defaultReceipt)
  // const loadingReceipt = useSelector(receiptLoadingSelector)
  const [isFirst, setIsFirst] = React.useState(true)
  const userSelected = useSelector(userSelectedSelector)

  const unidade = useRecoilValue(unidadeGetResponseState);

  useEffect(() => {
    try {
      if (isFirst) {
        tableRef.current.unity = 0
        setIsFirst(false)
        dispatch(resetPaymentClosed())
        return
      }
    } catch (e) {
      console.error(e)
    }
  }, [appid, data])

  // It control the refresh when change the accessId
  useEffect(() => {
    dispatch(fetchClosed({ accessId, cpfCnpj }))
  }, [accessId, dispatch, userSelected])

  const columns = [
    ...smColumns,
    ...(isMedium ? [] : otherMediumColumns),
    ...(isSmall ? [] : otherColumns)
  ]

  // const openModalReceipt = async (item) => {
  //   await dispatch(fetchReceipt(item.refrecid))
  //   setOpenModal({ open: true, correctvalue: item.correctvalue })
  // }


  // if (unidade && unidade.id === "c31ffd9a-1d4f-ed11-bba3-000d3a88f03a") {
  //   return (
  //     <Alert severity="warning">
  //       Portal financeiro bloqueado temporariamente. Por favor, tente novamente mais tarde.
  //     </Alert>
  //   )
  // }

  return <Fragment>
    <ReceiptDialog
      {...openModal}
      onClose={() => setOpenModal(defaultReceipt)}
    />
    <Table
      isLoading={isLoadingData}
      components={{
        Action: CustomAction,
        Cell: (props) => <CustomCellHighlight {...props} tableref={tableRef} />
      }}
      tableRef={tableRef}
      // Aguardando definição de busca de dados
      // actions={[
      //   rowData => {
      //     const isNotValidReceipt = rowData.flag === null && rowData.paymenttype === PAYMENT_TYPE.CARD
      //     return ({
      //       icon: () => loadingReceipt ? <SmallCircularProgress /> : <FontAwesomeIcon icon={faReceipt} className={classes.receiptIcon} />,
      //       tooltip: !isNotValidReceipt && 'Comprovante',
      //       hidden: rowData.paymenttype === PAYMENT_TYPE.BILLET,
      //       disabled: isNotValidReceipt,
      //       appid,
      //       accessId,
      //       onClick: async (e, item) => { openModalReceipt(item) },
      //       id: 'btn-gen-receipt-'
      //     })
      //   }
      // ]}
      columns={columns}
      options={{
        selection: false,
        headerStyle: {
          paddingRight: 5,
          padding: '16px 0px',
        }
      }}
      data={data}
      title={'Parcelas liquidadas'}
    />
  </Fragment>
}

export default Paid
