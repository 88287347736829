
import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { GET_COMPANY_INFORMATIONS, RESET_CREATE_FINANCIAL } from '../constants'
import { RESET_ACCESS } from 'layouts/constants'


export default apiReducer({
  entity: GET_COMPANY_INFORMATIONS,
  resetActions: [USER_CLEAR, RESET_CREATE_FINANCIAL, RESET_ACCESS],
  defaultData: {},
})
