import React, { useEffect } from 'react'
import 'moment/locale/pt'

import messages from 'utils/intl'
import moment from 'moment'
import { IntlProvider } from 'react-intl'

export const localeMessages = messages.ptBR

export default ({ children, locale }) => {
  
  useEffect(() => {
    moment.updateLocale(locale, {
      ...moment.localeData(locale)
    })
    moment.locale(locale)
  })

  const localeMessages = messages.ptBR

  const formats = {
    number: {
      currency: {
        style: 'currency',
        displayCurrency: 'symbol',
        currency: localeMessages.currency,
      },
    },
  }

  return (
    <IntlProvider locale={locale} messages={localeMessages} key={locale} formats={formats}>
      {children}
    </IntlProvider>
  )
}
