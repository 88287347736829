import { LOGIN, LOGINV1 } from '../constants'
import { apiPost, apiGet } from 'utils/API/actions/request'

export const authenticationSSO = (token) => apiPost({
  entity: LOGIN,
  url: '/authentication/oauth/token',
  requiresAuthentication: false,
  data: { token },
  isChainable: true
})

export const authenticationAD = (token) => apiPost({
  entity: LOGIN,
  url: '/authentication/oauth/admin',
  requiresAuthentication: false,
  data: { token },
  isChainable: true
})

export const authenticationRedirect = (value) => apiPost({
  entity: LOGINV1,
  url: '/authentication/external',
  requiresAuthentication: true,
  isChainable: true,
  data: { value  },
  config: {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTHENTICATION}`,
    }
  }
})

export const authenticationSSOV1 = (identity, token) => apiGet({
  entity: LOGINV1,
  url: `/authentication/oauth/${identity}/${token}`,
  requiresAuthentication: false,
  isChainable: true
})

export const authenticationRedirectSMO = (value) => apiPost({
  entity: LOGINV1,
  url: '/authentication/externalsmo',
  requiresAuthentication: true,
  isChainable: true,
  data: { value  },
  config: {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTHENTICATION}`,
    }
  }
})