import React from 'react'
import { textIntl } from 'utils/intl'
import { Grid } from '@material-ui/core'
import { FastField } from 'formik'
import DatePicker from 'modules/Admin/components/DatePicker'

export default (props) => {

  const { classes } = props

  
  const validate = (value) => {
    let error
    if (!value) {
      error = textIntl('admin-send-sms-required')
    } 
    return error
  }

  return (
    <Grid item xs={6} className={classes.item}>
      <FastField
        disableToolbar
        inputVariant='outlined'
        fullWidth
        format='dd/MM/yyyy HH:mm'
        variant='outlined'
        id='scheduleToSend'
        name='scheduleToSend'
        validate={validate}
        minDate={new Date()}
        value={props.values.scheduleToSend}
        label={textIntl('admin-send-sms-day-schedule')}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        component={DatePicker}
      />
    </Grid>
  )
}