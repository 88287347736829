import React, { useState, useEffect } from 'react'
import Form from './components/form'
import MessageList from './components/messageList'
import { useDispatch, useSelector } from 'react-redux'
import { appIdSelector } from 'modules/Login/selectors/user'
import { getSMS } from './services/sms'
import { fetchAllUnities } from 'utils/request/actions'

export default () => {

  const dispatch = useDispatch()
  const appId = useSelector(appIdSelector)
  const [ selected, setSelected ] = useState({})
  
  useEffect(() => {
    dispatch(getSMS(appId))
    dispatch(fetchAllUnities(appId))
  }, [appId])
  
  return (
    <>
      <Form {...selected} setSelected={setSelected} />
      <MessageList setSelected={setSelected} />
    </>
  )
}