import React from 'react'
import { withRouter } from 'react-router-dom'
import { Grid, CircularProgress, makeStyles } from '@material-ui/core'
import { setCookieMin } from 'utils/persistCookie'
import { PATH_ROUTE } from 'src/routes'
import { IS_SUCCESS_REDE } from '../Finance/Pay/constants'

const useStyle = makeStyles(() => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },
  loading: {
    width: 40,
    height: 40
  }
}))

const Success = () => {
  const classes = useStyle()
  
  React.useEffect(() => {
    setCookieMin(IS_SUCCESS_REDE,  window.location.pathname === PATH_ROUTE.SUCCESS, 30)
    window.close()
  }, [])

  return <Grid className={classes.root} container justify='center' alignContent='center'>
    <CircularProgress className={classes.loading} />
  </Grid>
}

export default withRouter(Success)
