import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseNegotiationSelector = prop('negotiation')

const paymentsSelector = pipe(baseNegotiationSelector, prop('payments'))

export const paymentsDataSelector = getAPIDataSelector(paymentsSelector)

export const hasLoadedPaymentsSelector = hasLoadedAPISelector(paymentsSelector)

export const hasErrorPaymentsSelector = hasErrorAPISelector(paymentsSelector)

export const isLoadingPaymentsSelector = isLoadingAPISelector(paymentsSelector)