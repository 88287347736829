import React, { Fragment, useRef } from 'react'
import { Grid, } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import useStyles from './style'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInstallmentsInfo } from 'modules/Finance/Pay/actions'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MaterialTable from 'components/Table'
import InstallmentRowWithCardInfo
  from 'modules/Finance/PaymentTypes/components/RecurrencePaymentDialog/components/InstallmentsAndCards/components/InstallmentRowWithCardInfo'
import { infoDataSelector, isLoadingInfoSelector } from 'modules/Finance/Pay/selectors/info'

const smColumns = (isSmall) => ([
  {
    title: 'Parcela',
    field: 'referencia',
    cellStyle: {
      width: '20%',
      paddingRight: 0,
      paddingLeft: 0
    },
  },
  {
    title: 'Vencimento',
    field: 'vencimentoFormatted',
    cellStyle: {
      width: '20%',
      paddingRight: 0,
      paddingLeft: 0
    },
  },
  {
    title: 'Valor',
    field: 'valorFormatted',
    cellStyle: {
      width: '20%',
      paddingLeft: 0,
      paddingRight: 0
    },
  },
  {
    title: 'Método de Pagamento',
    field: 'cardInfo',
    cellStyle: {
      width: '40%',
      paddingLeft: 0,
      paddingRight: 0
    },
  }
])

const otherColumns = (classes) => ([
  {
    title: 'Situação',
    field: 'status',
    cellStyle: {
      width: '20%',
      paddingLeft: 0,
      paddingRight: 0
    },
  },
])

const otherMediumColumns = () => ([
])

export default injectIntl(
  ({
     intl: { messages },
     setIsLoading,
     selectedMatricula,
     selectedInfo,
   }) => {
    const tableRef = useRef()
    const classes = useStyles()
    const dispatch = useDispatch()
    const installments = useSelector(infoDataSelector)
    const isLoadingInstallmentsInfo = useSelector(isLoadingInfoSelector)
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'))
    const isLoading = isLoadingInstallmentsInfo

    React.useEffect(() => {
      if (false === isLoadingInstallmentsInfo) {
        dispatch(
          fetchInstallmentsInfo({
            codigoMatriculaErp: selectedInfo.selectedMatriculaId,
            codigoResponsavelErp: selectedInfo.selectedResponsibleId,
            entidadeLegalUnidadeErp: selectedInfo.selectedDataAreaId,
          })
        )
      }
    }, [dispatch])

    const columns = (isSmall) => {
      return [
        ...smColumns(isSmall),
        ...(isSmall ? [] : otherColumns(classes)),
        ...(isMedium ? [] : otherMediumColumns()),
      ]
    }

    const CustomRow = (props) => {
      const {
        data: {
          cartao,
          metodoPagamento
        }
      } = props

      const {
        bandeira: cardBrand = null,
        quatroUltimosDigitos: cardNumber = null,
      } = cartao || {}

      return (
        <InstallmentRowWithCardInfo
          selectedInfo={selectedInfo}
          cardBrand={cardBrand}
          cardNumber={cardNumber}
          metodoPagamento={metodoPagamento}
          {...props}
        />
      )
    }

    return (
      <Grid
        container
        justifyContent='space-between'
        direction='row'
        alignItems='center'
        className={classes.content}
      >
        <Fragment>
          <Grid item xs={12}>
            <MaterialTable
              ref={tableRef}
              components={{
                Row: CustomRow
              }}
              isLoading={isLoading}
              options={{
                showTitle: false,
                toolbar: false,
                selection: false,
                search: false,
                emptyRowsWhenPaging: true,
                sorting: false,
                paging: true,
                tableLayout: 'fixed'
              }}
              columns={columns(isSmall)}
              data={(installments || []).filter((installment) => String(installment?.status).toLowerCase() !== 'fechado')}
            />
          </Grid>
        </Fragment>
      </Grid>
    )
  }
)
