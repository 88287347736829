import fetch from "../utils/fetch";

export function validateSignature(text, signature) {

    return fetch("api/Criptografia/VerificarAssinatura/", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            text: text,
            signature: signature
        })
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return false;
            }
        )
        .catch(error => {
            console.log(error.message);
            return false;
        });
}
