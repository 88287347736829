export const setCookieMin = (name, value, duration) => {
  const date = new Date()
  date.setTime(date.getTime() + (duration * 60 * 1000))
  document.cookie = `${name}=${value};path=/;expires=${date.toGMTString()}`
}

export const setCookieHora = (name, value, duration) => {
  const date = new Date()
  date.setTime(date.getTime() + ( duration * 60 * 60 * 1000))
  document.cookie = `${name}=${value};path=/;expires=${date.toGMTString()}`
}

export const setCookieDias = (name, value, duration) => {
  const date = new Date()
  date.setTime(date.getTime() + ( duration * 24 * 60 * 60 * 1000))
  document.cookie = `${name}=${value};path=/;expires=${date.toGMTString()}`
}

export const deleteCookie = (name) => {
  if (getCookie(name)) {
    document.cookie = `${name}=; expires=Thu, 01-Jan-70 00:00:01 GMT`
  }
}

export const getCookie = (name) => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
  return v ? v[2] : null
}
