import TagManager from 'react-gtm-module'
import { findLast } from 'lodash'
import { getTitle } from '../../routes'
import {gtmConfig} from "../../config";


export const GTM_EVENT_PAGE_VIEW = 'PAGE_VIEW'
export const GTM_EVENT_PURCHASE = 'purchase'

export const initializeGtm = () => {
  TagManager.initialize({
    gtmId: gtmConfig.containerId,
    dataLayer: window.dataLayer || [],
    events: {
      purchase: GTM_EVENT_PURCHASE,
      pageView: GTM_EVENT_PAGE_VIEW
    }
  })
}

export const logPageView = (appName) => {
  const lastPageView = findLast(window.dataLayer, dataLayer => dataLayer.event === GTM_EVENT_PAGE_VIEW)
  if (lastPageView) {
    if (lastPageView.pagePath === window.location.pathname) {
      return
    }
  }
  const selecao = sessionStorage.getItem("selecaoServicos");
  let decoded = {};

  if (selecao) {
    try {
      decoded = JSON.parse(selecao);
    } catch (e) {}
  }

  const title = getTitle()
  sendEventDataLayer({
    event: GTM_EVENT_PAGE_VIEW,
    pagePath: window.location.pathname,
    title,
    currency: "BRL",
    value: decoded?.carrinho?.servicoPrincipal?.valor,
    items: decoded
  })
}

export const logPurchase = () => {
  const title = getTitle()
  const selecao = sessionStorage.getItem("selecaoServicos");

  if (selecao) {
    const decoded = JSON.parse(selecao);
    sendEventDataLayer({
      event: GTM_EVENT_PURCHASE,
      pagePath: window.location.pathname,
      title,
      currency: "BRL",
      value: decoded?.carrinho?.servicoPrincipal?.valor,
      items: decoded
    })
  }
}

export const sendEventDataLayer = (event) => {
  TagManager.dataLayer({dataLayer: event})
}
