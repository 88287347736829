import {
  CONFIG_INSTALLMENT,
  REQUEST_CONFIG_INSTALLMENT,
  SUCCESS_CONFIG_INSTALLMENT,
  ERROR_CONFIG_INSTALLMENT
} from '../constants'
import { map } from 'lodash'
import { reduceReducers } from 'utils/API/reducer/reducer-utils'
import { apiReducer } from 'utils/API'

const mapSuccessData = (action, states) => {
  return (
    map(action.data, el => ({
      ...el,
    }))
  )
}

const mapError = (action, states) => ({
  ...states,
  data: []
})

export default reduceReducers(
  apiReducer({
    entity: CONFIG_INSTALLMENT,
    defaultData: [],
    mapSuccessData,
    mapError
  }),
  (states, action) => {
    switch (action.type) {
    case REQUEST_CONFIG_INSTALLMENT:
      return {
        ...states,
        dataSelected: action.data
      }
    case SUCCESS_CONFIG_INSTALLMENT:
      return {
        ...states,
        dataSelected: action.data
      }
    case ERROR_CONFIG_INSTALLMENT:
      return {
        ...states,
        dataSelected: action.data
      }
    default:
      return states
    }
  })