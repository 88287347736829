import FetchStatus from 'utils/fetchStatus'
import { 
  REQUEST_MESSAGE,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  DELETE_MESSAGE,
  SUCCESS_EDITED_MESSAGE,
} from '../constants'

const defaultState = {
  status: FetchStatus.INITIAL,
  data: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
  case REQUEST_MESSAGE:
    return {
      ...state,
      data: [],
      status: FetchStatus.FETCHING
    }
  case SUCCESS_MESSAGE:
    return {
      ...state,
      status: FetchStatus.DONE,
      data: [...state.data, ...action.payload]
    }
  case ERROR_MESSAGE:
    return {
      ...state,
      status: FetchStatus.ERROR,
    }
  case DELETE_MESSAGE:
    return {
      ...state,
      data: [
        ...state.data.filter(item => item.idMessaging !== action.payload),
      ],
    }
  case SUCCESS_EDITED_MESSAGE:
    return {
      ...state,
      data: [
        ...state.data.filter(item => item.idMessaging !== action.payload.idMessaging),
        action.payload
      ],
    }
  default:
    return state
  }
}
