import { reduceReducers } from 'utils/API/reducer/reducer-utils'
import { OPENED, RESET_OPENED, REFRESH_OPENED } from '../constants'
import { apiReducer } from 'utils/API'
import { map } from 'lodash'
import { InstallmentStatusToLabel, InstallmentStatus, isAvailableInstallment, isPrintInstallment, isBillet, isRecurrentPayment, isAutomaticDebit } from 'utils/InstallmentStatus'
import { currency } from 'utils/utils'
import moment from 'moment'

const isExpiredD2 = (element) => {
  let response = false;

  const expirationDate = moment(element.expirationdate);
  const dateNow = moment();

  if (dateNow.diff(expirationDate, 'days') > 30) {
    response = true;
  }

  return response;
}

const mapSuccessData = (action) => map(action.data, el => ({
  ...el,
  studentnameFormatted: el.studentname ? el.studentname.split(' ')[0] : '---',
  canPay: isAvailableInstallment(el.paymentavailability, el.paymentstatus.toUpperCase(), el.paymenttype, el.expirationdate) && isExpiredD2(el) == false,
  canPrint: isPrintInstallment(el.paymentavailability, el.paymenttype) && isExpiredD2(el) == false,
  viewRecept: el.paymentavailability === InstallmentStatus.WAITING_PAYMENT_PROCESSING,
  yearMonthFormatted: moment(el.expirationdate).format('MM/YY'),
  expirationdateFormatted: moment(el.expirationdate).format('DD/MM/YYYY'),
  documentvalueFormatted: currency(el.documentvalue),
  correctvalueFormatted: currency(el.correctvalue),
  automaticdebitFormatted: el.automaticdebit ? 'Sim' : 'Não',
  paymentavailabilityFormatted: InstallmentStatusToLabel(el.paymentavailability),
  automaticdebit: isAutomaticDebit(el),
  recurrencepayment: isRecurrentPayment(el),
  billetpayment: isBillet(el) 
}))

export default reduceReducers(
  apiReducer({
    defaultData: [],
    entity: OPENED,
    mapSuccessData,
    resetActions: [RESET_OPENED],
  }), (states, action) => {
    switch (action.type) {
    case REFRESH_OPENED:
      return {
        ...states,
        data: [...action.data, ...states.data]
      }
    default:
      return states
    }
  })
