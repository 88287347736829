import React, { useState } from "react";
import classNames from "classnames";
import { currency } from "utils/utils";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import Payment from "payment";
import { range, trim, map, isEqual, omit } from "lodash";
import { Form, Field, withFormik } from "formik";
import * as yup from "yup";
import Card from "react-credit-cards";
import {
  Typography,
  Button,
  MenuItem,
  useMediaQuery,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Divider,
  Link
} from "@material-ui/core";
import { TextCardNumber, TextCVV } from "../Input/InputsValidation";
import "react-credit-cards/es/styles-compiled.css";
import useStyles from "./style";
import { BRANDS_CONFIG } from "modules/Finance/constants";
import CustomRadio from "components/CustomRadio";
import Installments from "../Installments";
import { useSelector } from "react-redux";
import { PermissionFilter } from "components/PermissionsFilter";
import { DEBIT_FUNCTION } from "utils/permissions";
import { FormattedMessage } from "react-intl";
import { InputCpf } from "components/InputCpf";

const CARD_TYPE = {
  CREDIT: "credit",
  DEBIT: "debit",
};

const MyFormWithFormik = withFormik({
  mapPropsToValues: (props) => ({
    cardName: props.payment.cardName,
    cardNumber: props.payment.cardNumber,
    expiryDateMonth: props.payment.expiryDateMonth,
    expiryDateYear: props.payment.expiryDateYear,
    cvv: props.cvv || "",
    installment: props.payment.installment,
    cpf: props.payment.cpf,
  }),
  validationSchema: yup.object().shape({
    cardName: yup
      .string()
      .trim()
      .required("Obrigatório")
      .matches(/^[a-zA-Z ]+$/, "Nome deve conter apenas letras"),
    cardNumber: yup.string().required("Obrigatório"),
    expiryDateMonth: yup.string().required("Obrigatório"),
    expiryDateYear: yup.string().required("Obrigatório"),
    cvv: yup.string().required("Número de cvv inválido"),
    cpf: yup
      .string()
      .required("Obrigatório")
      .test("cpf-invalid", "Inválido", (value) => {
        if (value) {
          let valueReplaced = value.replace(/\./g, "").replace(/-/g, "");
          const valid = valueReplaced && valueReplaced.length == 11;
          return valid;
        }
      }),
  }),
  handleSubmit: (values, props) => {
    const { expiryDateMonth, expiryDateYear, cardNumber } = values;
    if (Payment.fns.validateCardExpiry(expiryDateMonth, expiryDateYear)) {
      const brandType = cardNumber && Payment.fns.cardType(cardNumber);
      const brand = BRANDS_CONFIG[brandType];

      values.cpf = values.cpf.replace(/\./g, "");
      values.cpf = values.cpf.replace(/-/g, "");

      props.props.setPayment({
        ...props.props.payment,
        ...values,
        brandType: brand,
      });
      props.props.handleNext({
        ...props.props.payment,
        ...values,
        brandType: brand,
      });
    } else {
      props.setErrors({ ...props.errors, expiryDateMonth: "Mês inválido" });
      props.setSubmitting(false);
    }
  },
});

export default MyFormWithFormik((props) => {
  const classes = useStyles();

  const currentYear = new Date().getFullYear();
  const isLoading = useSelector(props.isLoadingSelector);
  const { cardType, total, isNegotiation } = props.payment;
  const {
    cardName,
    cardNumber,
    expiryDateMonth,
    expiryDateYear,
    cvv,
    installment,
  } = props.values;
  const brandType = cardNumber && Payment.fns.cardType(cardNumber);
  const hasInstallment = props.payment.installment !== undefined;
  const totalFormatted = currency(total);
  const expirate = `${expiryDateMonth}/${expiryDateYear}`;

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [accept, setAccept] = useState(isNegotiation ? false : true);
  const acceptTerm = () => {
    setAccept(!accept);
    props.setDisabled(accept);
  };

  const openTermAgreement = () => {
    props.openTermAgreement();
  }

  const onFocus = (event) => {
    if (event.target.value && !trim(event.target.value)) {
      event.target.setSelectionRange(0, 0);
    }
  };

  const handleChange = (event) => {
    props.setPayment({ ...props.payment, cardType: event.target.value });
  };

  return (
    <React.Fragment>
      <Grid container>
        <Form onSubmit={(values) => props.handleSubmit(values, props)}>
          <Grid container justify="center">
            <Grid item xs={12} md={6} className={classes.card}>
              <Card
                number={cardNumber}
                name={cardName}
                expiry={expirate}
                cvc={cvv}
                placeholders={{ name: "Nome impresso no cartão" }}
                locale={{ valid: "BR" }}
                focused={cardName}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.item} md={6}>
            <Field
              name="cardName"
              variant="outlined"
              id="txt-mdl-pag-ttl"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              label="Nome do titular do cartão *"
              placeholder="Informe como está no cartão"
              InputProps={{ classes: { input: classes.input } }}
              inputProps={{ maxLength: 26 }}
              fullWidth
              component={TextField}
            />
          </Grid>
          <Grid item xs={12} className={classes.item} md={6}>
            <Field
              name="cardNumber"
              onClick={onFocus}
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="txt-mdl-pag-num-crt"
              label="Número do cartão *"
              InputProps={{
                inputComponent: TextCardNumber,
              }}
              component={TextField}
            />
          </Grid>

          <Grid item xs={12} className={classes.item} md={6}>
            <Field
              name="cpf"
              variant="outlined"
              onClick={onFocus}
              id="form-cpf"
              autoComplete="off"
              disabled={false}
              InputLabelProps={{ shrink: true }}
              label="Documento do titular *"
              // InputProps={{ classes: { input: classes.input } }}
              fullWidth
              component={TextField}
              InputProps={{
                inputComponent: InputCpf,
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.item} md={2}>
            <Field
              name="expiryDateMonth"
              id="slc-mdl-pag-mes"
              select
              label="Mês *"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              component={TextField}
            >
              {range(1, 13).map((option) => (
                <MenuItem
                  key={option}
                  value={option > 9 ? option : "0" + option}
                >
                  {option}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} className={classes.item} md={4}>
            <Field
              name="expiryDateYear"
              id="slc-mdl-pag-ano"
              select
              label="Ano *"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              component={TextField}
            >
              {range(currentYear, currentYear + 25).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} className={classes.item} md={6}>
            <Field
              name="cvv"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="txt-mdl-pag-cvv"
              onClick={onFocus}
              label="Código de segurança *"
              InputProps={{
                inputComponent: TextCVV,
              }}
              component={TextField}
            />
          </Grid>
          {hasInstallment && isEqual(cardType, CARD_TYPE.CREDIT) && (
            <Installments
              installment={installment}
              {...omit(props.payment, "installment")}
            />
          )}
          {!isNegotiation && (
            <KindCard cardType={cardType} handleChange={handleChange} />
          )}
          <Grid container justify="center" align="center">
            <Grid item xs={12}>
              <Typography
                className={classes.bands}
                variant="subtitle2"
                component="span"
              >
                Bandeiras Aceitas
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                {map(
                  BRANDS_CONFIG(isEqual(cardType, CARD_TYPE.DEBIT)),
                  (brand, index) => (
                    <img
                      key={index}
                      id={brand.idComponent}
                      className={classNames(
                        classes.imgCard,
                        brandType &&
                        index !== brandType &&
                        classes.imgCardOpacity
                      )}
                      src={brand.img}
                      alt="brand"
                    />
                  )
                )}
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          {isNegotiation && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accept}
                    onChange={acceptTerm}
                    value='accept'
                    color='primary'
                    disabled={isLoading}
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                }
              />
              <Typography variant="body1" className={classes.labelInline}>
                Li e concordo com o <Link className={classes.link} onClick={openTermAgreement}> Termo de Acordo  </Link> estabelecido.
              </Typography>
            </>
          )}

          <Grid
            item
            xs={12}
            align="right"
            className={classNames(isSmall && classes.buttonsSmall)}
          >
            <Typography className={classes.paymentTotal}>
              {`Valor total corrigido: ${totalFormatted}`}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading || !accept}
              id="btn-mdl-pag-cfm-rev"
              className={classes.button}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress size={24} className={classes.loading} />
              ) : (
                isNegotiation ? "Finalizar Acordo" : "Avançar"
              )}
            </Button>
          </Grid>
        </Form>
      </Grid>
    </React.Fragment>
  );
});

const KindCard = ({ cardType, handleChange }) => {
  const classes = useStyles();
  return (
    <PermissionFilter list={[DEBIT_FUNCTION]}>
      <Grid container>
        <Grid item xs={6} className={classes.cardType} md={3}>
          <Field
            name="credit"
            variant="outlined"
            checked={isEqual(cardType, CARD_TYPE.CREDIT)}
            color="primary"
            label="Crédito"
            id="ckc-mdl-pag-cred"
            value={CARD_TYPE.CREDIT}
            onClick={handleChange}
            component={CustomRadio}
          />
        </Grid>
        <Grid item sm xs={6} className={classes.cardType} md={3}>
          <Field
            name="debit"
            variant="outlined"
            checked={isEqual(cardType, CARD_TYPE.DEBIT)}
            id="ckc-mdl-pag-deb"
            value={CARD_TYPE.DEBIT}
            color="primary"
            label="Débito"
            onClick={handleChange}
            component={CustomRadio}
          />
        </Grid>
        {isEqual(cardType, CARD_TYPE.DEBIT) && (
          <Grid item xs={12}>
            <FormattedMessage id="payment-form-card-warning" />
            <ul className={classes.warningText}>
              <li>
                <FormattedMessage id="payment-form-card-warning-text1" />
              </li>
              <li>
                <FormattedMessage id="payment-form-card-warning-text2" />
              </li>
              <li>
                <FormattedMessage id="payment-form-card-warning-text3" />
              </li>
            </ul>
          </Grid>
        )}
      </Grid>
    </PermissionFilter>
  );
};
