import { pipe, prop, isEmpty } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  getAPIErrorSelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'

const baseNegotiationSelector = prop('negotiation')

const baseFinanceSelector = pipe(baseNegotiationSelector, prop('finance'))

export const financialAgreementSelector = pipe(baseFinanceSelector, prop('financialAgreement'))

export const financialAgreementDataSelector = getAPIDataSelector(financialAgreementSelector)

export const hasLoadedFinancialAgreementSelector = hasLoadedAPISelector(financialAgreementSelector)

export const hasErrorFinancialAgreementSelector = hasErrorAPISelector(financialAgreementSelector)

export const mensagemErrorAcordoSelector = getAPIErrorSelector(financialAgreementSelector)

export const isLoadingFinancialAgreementSelector = isLoadingAPISelector(financialAgreementSelector)

export const financialAgreementIdDataSelector = pipe(financialAgreementDataSelector, prop('financialAgreementId'))

export const financialAgreementCustSelector = pipe(financialAgreementDataSelector, prop('listCustTable'))

export const isMockFinancialSelector = createSelector(
  hasLoadedFinancialAgreementSelector,
  hasErrorFinancialAgreementSelector,
  financialAgreementDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
)

export const isFetchFinancialSelector = createSelector(
  hasLoadedFinancialAgreementSelector,
  isLoadingFinancialAgreementSelector,
  hasErrorFinancialAgreementSelector,
  financialAgreementDataSelector,
  (hasLoaded, isLoading,  hasError, data) =>  isEmpty(data.list) && !hasLoaded && !isLoading && !hasError)
