import { pipe, prop, isEmpty } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'

const baseNegotiationSelector = prop('negotiation')

const baseHistorySelector = pipe(baseNegotiationSelector, prop('history'))

export const installmentSelector = pipe(baseHistorySelector, prop('installments'))

export const installmentDataSelector = getAPIDataSelector(installmentSelector)

export const hasLoadedInstallmentSelector = hasLoadedAPISelector(installmentSelector)

export const hasErrorInstallmentSelector = hasErrorAPISelector(installmentSelector)

export const isLoadingInstallmentSelector = isLoadingAPISelector(installmentSelector)

export const isMockInstallmentSelector = createSelector(
  hasLoadedInstallmentSelector,
  hasErrorInstallmentSelector,
  installmentDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
)

export const isFetchInstallmentSelector = createSelector(
  hasLoadedInstallmentSelector,
  isLoadingInstallmentSelector,
  hasErrorInstallmentSelector,
  installmentDataSelector,
  (hasLoaded, isLoading,  hasError, data) =>  isEmpty(data) && !hasLoaded && !isLoading && !hasError)
