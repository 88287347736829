import React from 'react'
import { FINANCIAL, FINANCIAL_ADMIN } from 'utils/permissions'
import { PATH_ROUTE } from '../../../routes'
import ContentTour from '../components/ContentTour'
import { textIntl } from 'utils/intl'

export default [
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-icon-menu"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu')}
        content={textIntl('tour-main-menu-description')}
      />
    ),
    offSetPlus: 500,
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-menu-footer"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-contact')}
        content={textIntl('tour-main-menu-contact-description')}
      />
    ),
    offSetPlus: 500
  },
  {
    permissions: [FINANCIAL_ADMIN],
    selector: '[data-tut="tour-home-search-cpf"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-search')}
        content={textIntl('tour-main-menu-search-description')}
      />),
    position: 'left'
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-icon-search-account"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-user')}
        content={textIntl('tour-main-menu-user-description')}
      />
    ),
    position: 'right'
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tour-notification"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-notification')}
        content={textIntl('tour-main-menu-notification-description')}
      />
    )
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-table-pay"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-installment')}
        content={textIntl('tour-main-menu-installment-description')}
      />
    ),
  },
  //TO DO  Verificar uma melhor forma de acessar o selector da caixa de pesquisa da tabela.
  {
    permissions: [FINANCIAL],
    selector: 'div[data-tut="tur-table-pay"] > div > div > div > div',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-search-installment')}
        content={textIntl('tour-main-menu-search-installment-description')}
      />
    ),
    offSetPlus: -500
  },
  {
    permissions: [FINANCIAL],
    selector: '[title="Detalhes"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-detail')}
        content={textIntl('tour-main-menu-detail-description')}
      />
    ),
    offSetPlus: -500
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-home-check-pay"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-select')}
        content={textIntl('tour-main-menu-select-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-home-actions"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-actions')}
        content={textIntl('tour-main-menu-actions-description')}
      />
    ),
    offSetPlus: 500
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-home-pay-action"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-payment')}
        content={textIntl('tour-main-menu-payment-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-home-bol-action"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-billet')}
        content={textIntl('tour-main-menu-billet-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-home-receipt-action"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-receipt')}
        content={textIntl('tour-main-menu-receipt-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-home-students"]',
    nextTo: PATH_ROUTE.NEGOTIATION_FINANCE,
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-students')}
        content={textIntl('tour-main-menu-students-description')}
      />
    ),
    offSetPlus: -500
  }
]
