import { API_STATUS_ACTION_SUFFIXES, API_STATUS_TYPES } from '../constants'
import { isNil, forEach, startsWith, replace } from 'lodash'

const getActionType = (entity, type) => `${API_STATUS_ACTION_SUFFIXES[type]}_${entity}`

export const apiRequestActionType = entity => getActionType(entity, API_STATUS_TYPES.REQUEST)

export const apiErrorActionType = entity => getActionType(entity, API_STATUS_TYPES.ERROR)

export const apiSuccessActionType = entity => getActionType(entity, API_STATUS_TYPES.SUCCESS)

export const apiResetActionType = entity => getActionType(entity, API_STATUS_TYPES.RESET)

export const getEntityFromAction = action => {
  let entity = ''
  if (!isNil(action)) {
    forEach(API_STATUS_ACTION_SUFFIXES, type => {
      if (startsWith(action, type)) {
        entity = replace(action, `${type}_`, '')
      } 
    })
  }
  return entity
}
