import { SAVE_SMS, SMS_LIST, DELETE_SMS } from '../constants'
import { apiPost, apiGet, apiDelete, apiPut } from 'utils/API/actions/request'

export const saveSMS = (body, appId) => apiPost({
  entity: SAVE_SMS,
  url: `/sms/${appId}`,
  data: body,
  isChainable: true
})

export const updateSMS = (body, appId) => apiPut({
  entity: SAVE_SMS,
  url: `/sms/${appId}`,
  data: body,
  isChainable: true
})

export const getSMS = (appId) => apiGet({
  entity: SMS_LIST,
  url: `/sms/${appId}`,
  isChainable: true
})

export const deleteSMS = (smsId, appId) => apiDelete({
  entity: DELETE_SMS,
  url: `/sms/${appId}/${smsId}`,
  isChainable: true
})
