import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  title: {
    marginTop: 25,
  },
  cardInformationDiv: {
    width: "40%",
    // display: 'inline-block'
  },
  cardPaymentRecurrentTitle: {
    // width: '50%'
  },
  textInfo: {
    fontSize: 18
  },
  brandIcon: {
    height: 42,
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'inline-block'
  },
  last4digits: {
    marginTop: -5,
  },
  cardInfo: {
    display: 'inline-block'
  },
  paymentTitle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  paymentSubtitle: {
    marginTop: 5,
  },
  paymentKnowMore: {
    marginTop: 5,
    display: "inline-block",
    width: "auto",
    cursor: "pointer",
    textDecoration: "underline",
    fontWeight: "bold",
    '&:hover': {
      fontWeight: '500'
    }
  },
  boldText: {
    fontWeight: "500",
    cursor: "default",
  },
  buttonPrimary: {
    fontWeight: "bold",
    textAlign: "right",
    float: "right",
  },
  buttonSuccess: {
    color: "#006400 !important",
    fontWeight: "bold",
    textAlign: "right",
    float: "right",
    pointerEvents: "none",
  },
  buttonSuccessAutomaticDebit: {
    color: "#006400 !important",
    fontWeight: "bold",
    textAlign: "right",
    float: "right",
  },
  buttonSuccessRecurrent: {
    color: "#006400 !important",
    fontWeight: "bold",
    textAlign: "right",
    float: "right",
  },
  list: {
    width: "100%",
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    marginTop: 15,
    marginBottom: 15,
    border: 1,
    borderRadius: 5,
    borderRight: `25px solid ${theme.palette.primary.main}`,

    // '&:hover .MuiListItemText-multiline, &:hover .MuiAvatar-root': {
    //   color: 'white !important',
    //   zIndex: 999,
    //   pointerEvents: 'none',
    // },
  },
  listItemSuccess: {
    backgroundColor: '#006400',
    marginTop: 15,
    marginBottom: 15,
    border: 1,
    borderRadius: 5,
    borderRight: "25px solid #006400",

    '& p, & > *': {
      color: 'white !important'
    }

    // '&:hover .MuiListItemText-multiline, &:hover .MuiAvatar-root': {
    //   color: 'white !important',
    //   zIndex: 999,
    //   pointerEvents: 'none',
    // },
  },
  cardIconSuccess: {
    color: "#006400 !important",
  },
  loading: {
    margin: "0 auto",
    display: "block",
    marginTop: 25,
  },
  creditCardInfoButton: {
    margin: "0 60px",
  }
}));
