import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid, CircularProgress } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  loading: {
    minHeight: 200
  },
}))

export default ({ message }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Grid container className={classes.loading}>
        <Grid item xs={12} align='center'>
          <Typography variant='h5' gutterBottom>
            {message}
          </Typography>
        </Grid>
        <Grid item xs={12} align='center'>
          <CircularProgress />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
