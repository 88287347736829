
import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { RECEIPT, RESET_RECEIPT } from '../constants'

export default apiReducer({
  entity: RECEIPT,
  resetActions: [USER_CLEAR, RESET_RECEIPT],
})
