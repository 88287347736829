import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { tryAgainButtonSelector, isLoadingAuthenticationSelector } from '../selectors/authentication'
import { runWithAdal, adalGetToken } from 'react-adal'
import { authContext, adalConfig } from 'utils/adalConfig'
import LogoLoading from '../componentes/LoadingLogin'
import { setAdminFlag, fetchLoginAD } from '../actions'

const LoginAD = ({ history }) => {
  const dispatch = useDispatch()

  const tryAgain = useSelector(tryAgainButtonSelector)
  const loading = useSelector(isLoadingAuthenticationSelector)

  const [firstAuth, setFirstAuth] = useState(true)
  const DO_NOT_LOGIN = false

  useEffect(() => {
    if (firstAuth || tryAgain) {
      setFirstAuth(false)
      runWithAdal(authContext, () => { }, DO_NOT_LOGIN)

      adalGetToken(authContext, adalConfig.endpoints.graphApi).then((resp) => {
        dispatch(setAdminFlag({ adminFlag: true }))
        dispatch(fetchLoginAD(resp, history))
      })
    }
  }, [firstAuth, setFirstAuth, dispatch, history, tryAgain, DO_NOT_LOGIN])


  return (
    <LogoLoading isLoading={loading} />
  )
}

export default withRouter(LoginAD)
