import { persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import reducers from '../reducers'

const transformCircular = createTransform(
  (inboundState) => inboundState.user ? { user: inboundState.user } : inboundState,
  (outboundState) => outboundState.user ? { user: outboundState.user } : outboundState,
)

const persistConfig = {
  key: 'persist',
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [transformCircular],
  debug: false,
  whitelist: ['login', 'layout'],
}

export default persistReducer(persistConfig, reducers)
