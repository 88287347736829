import { apiReducer } from "utils/API";
import { PAYMENT_TYPE_SELECTED, PAYMENT_TYPE_SIMPLE_PAYMENT } from "../constants";
import { reduceReducers } from 'utils/API/reducer/reducer-utils'

const mapSuccessData = (action, states) => {
  const response = action.data;

  if (response && response.debito_automatico_conta) {
    response.debito_automatico_conta = response.debito_automatico_conta.replace(' ', '');
  }

  return response;
};

export default reduceReducers(
  apiReducer({
    entity: PAYMENT_TYPE_SELECTED,
    defaultData: null,
    mapSuccessData,
  })
)