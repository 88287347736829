import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {isLoadingAuthenticationSelector, tryAgainButtonSelector} from './selectors/authentication'
import {fetchLoginRedirect, fetchLoginRedirectSMO, fetchLoginSSO, fetchLoginSSOV1} from './actions'
import {getParamsRouter} from 'utils/utils'
import {KEY_LOGIN_EXTERNAL, KEY_SSO_ACCESS_TOKEN} from './constants'
import LogoLoading from './componentes/LoadingLogin'
import {PATH_ROUTE} from '../../routes'
import {getEnvDomain} from 'utils/domains/routerSSO'
import {KEY_ENV_DOMAIN} from 'utils/enums/entity'
import {showAppBar} from "../../layouts/actions";
import {appBarShowSelector} from "../../layouts/selectors/appBar";
import {useSetRecoilState} from "recoil";
import {loginTypeState} from "../../recoil/atoms/loginTypeState";
import loginTypes from "../../utils/loginTypes";

const Login = ({ history, match: { params: { id } } }) => {
  const dispatch = useDispatch()
  const tryAgain = useSelector(tryAgainButtonSelector)
  const path = getParamsRouter('path', history.location)
  const loading = useSelector(isLoadingAuthenticationSelector)
  const isShowAppBar = useSelector(appBarShowSelector);
  const setLoginType = useSetRecoilState(loginTypeState);

  const [firstAuth, setFirstAuth] = useState(true)

  useEffect(() => {
    if (firstAuth || tryAgain) {
      setFirstAuth(false)
      dispatch(showAppBar())
      //TO DO Implementação para aceitar o login pelo SSO do cliente via a api V1 de autenticação.
      //Remover quando utilizar o nosso SSO.
      if (id) {
        setLoginType(loginTypes.SSOV1);
        dispatch(fetchLoginSSOV1(id, getParamsRouter(KEY_SSO_ACCESS_TOKEN, history.location), history))
      }
      // SSO Dart
      else if (history.location.pathname.includes(PATH_ROUTE.LOGINSSO)) {
        setLoginType(loginTypes.SSO);
        dispatch(fetchLoginSSO(getParamsRouter(KEY_SSO_ACCESS_TOKEN, history.location), history))
      }
      // Login realizado atraves do portal do aluno
      else if (history.location.pathname.includes(PATH_ROUTE.LOGIN_EXTERNAL)) {
        setLoginType(loginTypes.EXTERNAL);
        const value = getParamsRouter(KEY_LOGIN_EXTERNAL, history.location)
        dispatch(fetchLoginRedirect(value, history))
      }
      // Login realizado atraves do portal do SMO
      else if (history.location.pathname.includes(PATH_ROUTE.LOGIN_EXTERNALSMO)) {
        setLoginType(loginTypes.SMO);
        const value = getParamsRouter(KEY_LOGIN_EXTERNAL, history.location)
        dispatch(fetchLoginRedirectSMO(value, history, path, isShowAppBar))
      }
      // Redireciona a pagina para o SSO de acordo com o localhost
      else if (!path) {
        setLoginType('');
        window.location.href = getEnvDomain(KEY_ENV_DOMAIN.LOGIN)
      }
    }
  }, [firstAuth, setFirstAuth, dispatch, history, tryAgain, id, path])

  return (
    <LogoLoading isLoading={loading} />
  )
}

export default withRouter(Login)
