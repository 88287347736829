import { combineReducers } from 'redux'
import finance from '../Finance/reducers'
import history from '../History/reducers'
import payments from  '../PaymentsList/reducers'

export default combineReducers({
  finance,
  history,
  payments
})
