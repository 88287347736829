
import { apiReducer } from 'utils/API'
import { map } from 'lodash'
import { USER_CLEAR } from 'modules/Login/constants'
import { HISTORY_NEGOTIATION } from '../constants'
import { currency } from 'utils/utils'
import moment from 'moment'

const mapSuccessData = (action) =>  map(action.data , el => ({
  ...el,
  amountAgreementFormated: currency(el.amountAgreement),
  nameFormatted: el.studentName ? el.studentName.split(' ')[0] : '---',
  agreementDateFormated: moment(el.agreementDate).format('DD/MM/YYYY')
}))

export default apiReducer({
  entity: HISTORY_NEGOTIATION,
  defaultData: [],
  mapSuccessData,
  resetActions: [USER_CLEAR],  
})
