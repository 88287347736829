export const returnValuesInstallment = (total, amountInstallment, initialValue) => {
  const value = total - initialValue
  const _installment = (Math.floor(parseFloat(value / amountInstallment) * 100) / 100)
  const installmentValue = parseFloat(_installment)
  const difference = value - (installmentValue * amountInstallment)
  const firstInstallment = parseFloat(initialValue) + difference
  return { firstInstallment, installmentValue }
}

export const PAY_WAYS = {
  BILLET: 'BOLETO',
  CARD: 'C. CREDITO'
}

export const isBilletWay = (type)  => type === PAY_WAYS.BILLET

export const isCardWay = (type)  => type === PAY_WAYS.CARD

export const PAYMENT_TYPE = {
  CASH_PAYMENT: 'cashPayment',
  CASH_INSTALLMENT: 'cashInstallment'
}

export const isTypeCashPayment = (type) => type === PAYMENT_TYPE.CASH_INSTALLMENT