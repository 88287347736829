
import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { POST_FINANCE, RESET_CREATE_FINANCIAL } from '../constants'
import { RESET_ACCESS } from 'layouts/constants'

export default apiReducer({
  entity: POST_FINANCE,
  resetActions: [USER_CLEAR, RESET_CREATE_FINANCIAL, RESET_ACCESS],  
})
