import { apiRequestActionType, apiSuccessActionType, apiErrorActionType, apiResetActionType } from '../action-types'
import { API_META_REDUCER_ATTRIBUTES } from '../constants'

export const apiRequestedAction = (entity, meta) => ({
  type: apiRequestActionType(entity),
  [API_META_REDUCER_ATTRIBUTES.META]: meta,
})

export const apiSuccessAction = (entity, data, pagination, meta) => ({
  type: apiSuccessActionType(entity),
  [API_META_REDUCER_ATTRIBUTES.DATA]: data,
  [API_META_REDUCER_ATTRIBUTES.PAGINATION]: pagination,
  [API_META_REDUCER_ATTRIBUTES.META]: meta,
})

export const apiErrorAction = (entity, error, meta) => ({
  type: apiErrorActionType(entity),
  [API_META_REDUCER_ATTRIBUTES.ERROR]: error || true,
  [API_META_REDUCER_ATTRIBUTES.META]: meta,
})

export const apiResetAction = (entity) => ({
  type: apiResetActionType(entity)
})
