import { ACTIONS_ACTIVE_ANALYTICS } from 'utils/GTM/constants'
import { sendEventDataLayer } from 'utils/GTM'

const sendGtm = (store) => (next) => (action) => {
  const actionAnalytics = ACTIONS_ACTIVE_ANALYTICS[action.type]
  if (actionAnalytics) {
    sendEventDataLayer({
      event: `${actionAnalytics}_${action.payload.entity}`,
      info: action.payload.analytics && action.payload.analytics.info, 
      value: action.payload.analytics && action.payload.analytics.value, 
    })
  }
  return next(action)
}

export default sendGtm
