/* eslint-disable no-unexpected-multiline */

import * as yup from 'yup'

import { Button, CircularProgress, MenuItem, Typography } from '@material-ui/core'
import { Field, Form, withFormik } from 'formik'
import { FormattedMessage, injectIntl } from 'react-intl'
import { PAYMENT_TYPE, PAY_WAYS, isTypeCashPayment } from 'modules/Negotiation/Finance/utils'
import { financialAgreementDataSelector, isLoadingFinancialAgreementSelector } from 'modules/Negotiation/Finance/selectors/financialAgreement'
import { isEmpty, range } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Installments from '../Installments'
import Loading from 'components/loading'
import Message from '../message'
import PaymentType from '../paymentType'
import React from 'react'
import { TextField } from 'formik-material-ui'
import classNames from 'classnames'
import { compose } from 'redux'
import { currency } from 'utils/utils'
import { fetchFinancial } from 'modules/Negotiation/Finance/actions'
import useStyles from './style'

const MyFormWithFormik = withFormik({
  mapPropsToValues: (props) => ({
    type: props.type || PAYMENT_TYPE.CASH_PAYMENT,
    installment: props.installment || 0,
    entryPercent: props.entryPercent || 50,
  }),
  validationSchema: yup.object().shape({
    entryPercent: yup.number()
      .min(50, 'Entrada de no mínimo 50%')
      .max(99, 'Selecione a opção de parcela única')
      .required('Obrigatório'),
    installment: yup.number()
      .min(1, 'Selecione no mínimo 1 parcela')
      .max(5)
      .required('Selecione as parcelas')
  }),
  validate: (values) => {
    const error = {}
    if (isTypeCashPayment(values.type)) {
      return error
    }
    return error
  }
})

export default compose(
  MyFormWithFormik,
  injectIntl
)
((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const financialAgreement = useSelector(financialAgreementDataSelector)
  const { isSubmitting, isValidating, setFieldValue, isValid, items, setInfos, infos, values, intl: { messages } } = props
  const { type, installment, entryPercent } = props.values
  const isInstalmment = isTypeCashPayment(type)
  const isLoadingFinancialAgreement = useSelector(isLoadingFinancialAgreementSelector)
  const total = financialAgreement && financialAgreement.list && financialAgreement.list.reduce((prev, curr) => prev + Number(curr.amountCur), 0)

  const handleChangeKindPayment = (event) => {
    setFieldValue('type', event.target.value)
    setFieldValue('installment', 0)
    setFieldValue('entryPercent', 50)
    dispatch(fetchFinancial(isTypeCashPayment(type) ? 1 : 2, items, isTypeCashPayment(type) ? 100 : 50, PAY_WAYS.BILLET))
  }

  const onSubmit = () => {
    props.handleNext()
    setInfos({ ...infos, ...props.values })
  }

  const calculate = () => {
    dispatch(fetchFinancial(installment + 1, items, entryPercent, PAY_WAYS.BILLET))
    setInfos({ ...infos, installment, entryPercent, type })
  }

  if (isLoadingFinancialAgreement) {
    return <Loading message={messages['finance-negotiation-comp-billet-form-loaging']} />
  }

  if (!financialAgreement || isEmpty(financialAgreement.financialAgreementId) || total === 0) {
    return <Message
      title={messages['finance-negotiation-comp-billet-form-title-error']}
      message={messages['finance-negotiation-comp-billet-form-error']} />
  }

  return (
    <React.Fragment>
      <Form className={classes.billetForm} onSubmit={onSubmit}>
        <Grid container className={classes.billetFormFlex} alignContent='flex-start' >
          <PaymentType type={type} handleChange={handleChangeKindPayment} classes={classes} />
          {isInstalmment &&
            <>
              <Grid item xs={6} className={classNames(classes.billetFormItem, classes.billetFormInput)} md={6} >
                <Field
                  label={messages['finance-negotiation-comp-billet-initial-percent']}
                  name={'entryPercent'}
                  variant='outlined'
                  fullWidth
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  id={'lbl-mdl-bil-info-percent'}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      disabled: classes.disabled
                    },
                  }}
                  component={TextField}
                />
              </Grid>
                <Grid item xs={6} className={classes.billetFormItem} md={6} >
                  <Field
                    name='installment'
                    id='slc-mdl-bil-ins'
                    select
                    label={messages['finance-negotiation-comp-billet-instalment']}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant='outlined'
                    component={TextField}
                  >
                    {range(1, 6).map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={12} >
                  <Button
                    fullWidth
                    id='btn-mdl-pag-ngt'
                    variant='contained'
                    color='primary'
                    onClick={calculate}
                    disabled={installment === 0}
                  >
                    <FormattedMessage id='finance-negotiation-comp-billet-button-calc' />
                  </Button>
                </Grid>
              </>
          }
          {financialAgreement.list && financialAgreement.list.length > 0 && <Installments listInstallment={financialAgreement.list} />}
        </Grid>
        <Grid item xs={12} align='right' >
          {total && <Typography className={classes.billetFormPaymentTotal} >
            <FormattedMessage id='finance-negotiation-comp-billet-amount' values={{ value: currency(total) }} />
          </Typography>}
          <Button
            variant='contained'
            color='primary'
            id='btn-mdl-pag-cfm-rev'
            disabled={(isInstalmment && !isValid) || ((values.installment + 1) !== financialAgreement.list.length)}
            className={classes.billetFormButton}
            type='submit' >
            {isValidating || isSubmitting ?
              <CircularProgress className={classes.billetFormLoading} /> :
              <FormattedMessage id='finance-negotiation-comp-billet-next' />
            }
          </Button>
        </Grid>
      </Form>
    </React.Fragment>
  )
})