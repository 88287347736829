import { appIdSelector } from 'modules/Login/selectors/user'
import { getCompanyInformations } from 'modules/Negotiation/Finance/services/company'
import { hasLoadedExpiratedSelector, expiratedSelectedSelector } from 'modules/Negotiation/Finance/selectors/expirated'
import { isFetchCompanySelector, isMockCompanySelector } from 'modules/Negotiation/Finance/selectors/companyInformations'

const urlRegex = /company\/*.*/

const addMock = (mock) => {
  mock
    .onGet(urlRegex).reply(200, response)
}

const requestApi = (store) => {
  const appId = appIdSelector(store.getState())
  const expirated = expiratedSelectedSelector(store.getState())
  const [ item ] = expirated
  store.dispatch(getCompanyInformations(appId, item.unityid))
}

const initializeMock = (mock, store) => {
  const hasExpirated = hasLoadedExpiratedSelector(store.getState())
  if (hasExpirated) {
    if (isFetchCompanySelector(store.getState())) {
      requestApi(store, mock)
    } else if (isMockCompanySelector(store.getState())) {
      addMock(mock, store)
    }
  }
}

const response = {
  'response': {
    'dataAreaId': 'CDB',
    'name': '',
    'companyName': 'ESCOLA.',
    'cnpj': '',
    'address': '',
    'stateRegistration': '',
    'municipalRegistration': '',
    'city': '',
    'zipCode': '',
    'unityId': ''
  },
  'message': null
}

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}
