import React from 'react'
import { setCredentials } from 'utils/axios'
import { keys, filter, forEach } from 'lodash'
import {
  USER_SET_SELECTED,
  USER_CLEAR,
  USER_SET_APP_CONFIGS,
  USER_SET_ACCESS,
  USER_CLEAR_ACCESS,
  USER_STATUS_POPUP,
  USER_REFRESH_TOKEN,
  USER_SET_ADMIN_FLAG,
  REQUEST_LOGOUT,
  REQUEST_TRY_AGAIN,
  ERRORS_LOGIN,
  USER_DISABLE_CLIENTS_POPUP,
  USER_DISABLE_NOTIFICATION
} from '../constants'
import { LIST_ERROR_MESSAGE, ERROR_LABEL_BUTTON } from '../LoginAD/constants'
import { PATH_ROUTE } from 'src/routes'
import {
  authenticationSSO,
  authenticationAD,
  authenticationSSOV1,
  authenticationRedirect,
  authenticationRedirectSMO
} from '../service/authentication'
import { setWithConfig } from 'layouts/actions'
import { SnackLogin } from '../componentes/SnackLogin'
import { getEnvDomain } from 'utils/domains/routerSSO'
import { KEY_ENV_DOMAIN } from 'utils/enums/entity'
import { isOnlyOneExhibitionPopUpSelector, isOnlyOneExhibitionNotificationSelector, idMessageNotificationSelector } from 'modules/Home/selectors/userMessage'
import { fetchClientMessages } from 'modules/Home/actions'
import {appBarShowSelector} from "../../../layouts/selectors/appBar";
import appBar from "../../../layouts/reducers/appBar";
import {hideAppBar} from "../../../layouts/actions";
import {setRecoil} from "recoil-nexus";
import {portalAuthState} from "../../../recoil/atoms/portalAuthState";

// quando receber 401:
// - limpa o token do axios
// - remove os dados do usuario do redux e consequentemente
// - do local storage
// - isso vai forçar o render do Dashboard que é uma private route,
// sendo assim vai redirecionar para a tela de login
export const logout = () => (dispatch, getState) => {
  //clearToken()
  //dispatch(clear())

  //TO DO - Devido a utilização do SSO do cliente o processo de logout será feito via redirecionamento para o endereço abaixo
  //Remover essa implementação quando for utilizar o nosso SSO
  const localStorageAdmin = filter(keys(window.localStorage), key => key.toString().includes('adal'))
  forEach(localStorageAdmin, key => localStorage.removeItem(key))
  localStorage.removeItem("persist:persist")
  window.location.href = getEnvDomain(KEY_ENV_DOMAIN.LOGOUT)
}

export const setSelected = appconfig => ({
  type: USER_SET_SELECTED,
  payload: appconfig
})

export const setAccess = access => ({
  type: USER_SET_ACCESS,
  payload: access
})

export const clearAccess = () => ({
  type: USER_CLEAR_ACCESS,
})

export const clear = () => ({
  type: USER_CLEAR
})

export const disableNotification = () => (dispatch, getState) => {
  const onlyOneExhibition = isOnlyOneExhibitionNotificationSelector(getState())
  if (onlyOneExhibition) {
    const idNotification = idMessageNotificationSelector(getState())

    dispatch({
      type: USER_DISABLE_NOTIFICATION,
      payload: idNotification
    })
  }
}

export const disablePopUp = id => ({
  type: USER_DISABLE_CLIENTS_POPUP,
  payload: id
})

export const changeStatusPopUp = (isEnabled, id) => (dispatch, getState) => {
  if ( id && !isEnabled ) {
    const onlyOneExhibitionSelector = isOnlyOneExhibitionPopUpSelector(getState())
    if (onlyOneExhibitionSelector) {
      dispatch(disablePopUp(id))
    }
  }
  dispatch({
    type: USER_STATUS_POPUP,
    payload: isEnabled
  })
}

export const setAppConfigs = response => ({
  type: USER_SET_APP_CONFIGS,
  payload: response
})

export const refreshToken = response => ({
  type: USER_REFRESH_TOKEN,
  payload: response
})

export const setAdminFlag = response => ({
  type: USER_SET_ADMIN_FLAG,
  payload: response
})

export const requestLogout = () => ({
  type: REQUEST_LOGOUT,
})

export const requestTryAgain = () => ({
  type: REQUEST_TRY_AGAIN
})

const dispatchError = (dispatch, msgError = ERRORS_LOGIN.ERROR_DEFAULT, labelButton = ERRORS_LOGIN.ERROR_LABEL_BUTTON_DEFAULT) => {
  dispatch(setWithConfig({
    message: msgError,
    autoHideDuration: null,
    action: <SnackLogin labelButton={labelButton} />
  }))
}

const dispatchSuccess = (result, dispatch, history, path) => {
  result.appconfig = [
    ...result.appconfig
  ]
  setCredentials(result.token, result.refreshtoken)
  setRecoil(portalAuthState, result);
  dispatch(setAppConfigs(result))
  dispatch(setSelected(result.appconfig[0]))
  dispatch(fetchClientMessages(result.appconfig[0].appid, true))
  if (path !== false) history.push(path || PATH_ROUTE.HOME)
}

export const fetchLoginRedirect = (value, history) => (dispatch) => {
  return dispatch(authenticationRedirect(value))
    .then((result) => dispatchSuccess(result, dispatch, history))
    .catch(() => dispatchError(dispatch))
}

export const fetchLoginSSO = (token, history) => (dispatch) => {
  return dispatch(authenticationSSO(token))
    .then((result) => dispatchSuccess(result, dispatch, history))
    .catch(() => dispatchError(dispatch))
}

export const fetchLoginRedirectSMO = (value, history, path = false, hideError = false) => (dispatch) => {
  let promise = dispatch(authenticationRedirectSMO(value))
    .then((result) => dispatchSuccess(result, dispatch, history, path))

  if (!hideError) {
    promise.catch((e) => {
      dispatchError(dispatch)
    })
  }

  return promise
}

export const fetchLoginSSOV1 = (id, token, history) => (dispatch) => {
  return dispatch(authenticationSSOV1(id, token))
    .then((result) => dispatchSuccess(result, dispatch, history))
    .catch(() => dispatchError(dispatch))
}

export const fetchLoginAD = (token, history) => (dispatch) => {
  let messageError
  let labelButton

  return dispatch(authenticationAD(token)).then((result) =>
    dispatchSuccess(result, dispatch, history))
    .catch((result) => {
      if (result.response !== undefined) {
        messageError = LIST_ERROR_MESSAGE[result.response.data.message]
        labelButton = ERROR_LABEL_BUTTON
      }

      dispatchError(dispatch, messageError, labelButton)
    })
}
