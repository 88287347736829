
import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { BILLET, RESET_BILLET } from '../constants'

export default apiReducer({
  entity: BILLET,
  resetActions: [RESET_BILLET, USER_CLEAR],
})
