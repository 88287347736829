import { apiRequestActionType, apiSuccessActionType, apiErrorActionType, apiResetActionType } from 'utils/API/action-types'

export const ACCESS = 'ACCESS'

export const REQUEST_ACCESS = apiRequestActionType(ACCESS)
export const RECEIVE_ACCESS = apiSuccessActionType(ACCESS)
export const ERROR_ACCESS = apiErrorActionType(ACCESS)
export const RESET_ACCESS = apiResetActionType(ACCESS)

export const UI_APP_BAR_SHOW = 'UI_APP_BAR_SHOW'
export const UI_APP_BAR_HIDE = 'UI_APP_BAR_HIDE'

export const CONTACT = 'CONTACT'
export const REQUEST_CONTACT = apiRequestActionType(CONTACT)
export const RECEIVE_CONTACT = apiSuccessActionType(CONTACT)
export const ERROR_CONTACT = apiErrorActionType(CONTACT)

export const UI_SNACK_SET = 'UI_SNACK_SET'
export const UI_SNACK_SET_CONFIG = 'UI_SNACK_SET_CONFIG'
export const UI_SNACK_HIDE = 'UI_SNACK_HIDE'

export const ACTIVE_TOUR = 'ACTIVE_TOUR'
export const DEACTIVE_TOUR = 'DEACTIVE_TOUR'
