
import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { CREATE_FINANCIAL, RESET_CREATE_FINANCIAL } from '../constants'
import { RESET_ACCESS } from 'layouts/constants'

const defaultData = {
  financialAgreementId: '',
  financialAgreementProposalId: '',
  custAccount: '',
  fiscalEstablishmentId_Br: '',
  paymWayId: '',
  entryPercent: '',
  installmentQty: '',
  custAccountStudent: '',
  list: [],
  listMatriculas: [],
  paymModeId: '',
}

export default apiReducer({
  entity: CREATE_FINANCIAL,
  resetActions: [USER_CLEAR, RESET_CREATE_FINANCIAL, RESET_ACCESS],
  defaultData
})
