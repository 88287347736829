import { useDispatch, useSelector } from "react-redux";
import { isNull } from "lodash";
import { paymentTypeSelectedDataSelector } from "../selectors/paymentTypes";
import {
  changeToSimpleMode,
  changeToAutomaticDebit,
  changeToRecurrencePayment,
} from "../services/paymentTypes";
import {
  MESSAGE_ERROR_PAYMENT_TYPE_SIMPLE_MODE,
  MESSAGE_ERROR_PAYMENT_TYPE_AUTOMATIC_DEBIT,
  MESSAGE_ERROR_PAYMENT_TYPE_RECURRENCE_PAYMENT,
} from "../constants/index";
import { appIdSelector } from "modules/Login/selectors/user";

export const changePaymentTypeToSimpleMode = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const paymentTypeSelected = paymentTypeSelectedDataSelector(getState());

    const request = {
      matriculaId: paymentTypeSelected.matricula_id,
      accountManager: paymentTypeSelected.responsavel_financeiro,
      dataAreaId: paymentTypeSelected.data_area_id,
      smo: "",
    };

    dispatch(changeToSimpleMode(request))
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        const messageError = handleErrorMessage(
          error,
          MESSAGE_ERROR_PAYMENT_TYPE_SIMPLE_MODE
        );
        reject(messageError);
      });
  });
};

export const changePaymentTypeToAutomaticDebit = (bank, account, agency) => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    const paymentTypeSelected = paymentTypeSelectedDataSelector(getState());

    const request = {
      matriculaId: paymentTypeSelected.matricula_id,
      accountManager: paymentTypeSelected.responsavel_financeiro,
      bank: bank,
      account: account,
      agency: agency,
      dataAreaId: paymentTypeSelected.data_area_id,
      smo: "",
    };

    dispatch(changeToAutomaticDebit(request))
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        const messageError = handleErrorMessage(
          error,
          MESSAGE_ERROR_PAYMENT_TYPE_AUTOMATIC_DEBIT
        );
        reject(messageError);
      });
  });
};

export const changePaymentTypeToRecurrencePayment = (
  cardNumber,
  cardHolderName,
  expirationMonth,
  expirationYear,
  securityCode,
  unityId,
  cpf
) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const paymentTypeSelected = paymentTypeSelectedDataSelector(getState());
    const appId = appIdSelector(getState());

    const request = {
      matriculaId: paymentTypeSelected.matricula_id,
      accountManager: paymentTypeSelected.responsavel_financeiro,
      cardNumber: cardNumber.replace(/\s+/g, ""),
      cardHolderName: cardHolderName,
      expirationMonth: expirationMonth,
      expirationYear: expirationYear.toString().substring(2, 4),
      securityCode: securityCode,
      dataAreaId: paymentTypeSelected.data_area_id,
      unityId: unityId,
      cpf: cpf,
      smo: "",
    };

    dispatch(changeToRecurrencePayment(request))
      .then((result) => {
        //region Timeout de 15 segundos
        // Adiciona timeout para adicionar um espaçamento entre as transações no cartão ,
        // provavelmente isso deve evitar que o emissor recuse operação subsequentes
        setTimeout(() => {
          resolve(result);
        }, 15000)
        //endregion Timeout de 15 segundos
      })
      .catch((error) => {
        const messageError = handleErrorMessage(
          error,
          MESSAGE_ERROR_PAYMENT_TYPE_RECURRENCE_PAYMENT
        );
        reject(messageError);
      });
  });
};

const handleErrorMessage = (error, message) => {
  if (
    error &&
    error.response &&
    (error.response.status === 400 || error.response.status === 500)
  ) {
    const {
      response: { data },
    } = error;

    return isNull(data.message) ? message : data.message;
  }
};
