import { REQUEST_CLOSED, SUCCESS_CLOSED, ERROR_CLOSED, RESET_CLOSED } from '../constants'

const defaultState = {
  loading: false,
  error: false,
  data: []
}

export default (states = defaultState, action) => {
  switch (action.type) {
  case REQUEST_CLOSED:
    return {
      ...states,
      loading: true,
      error: false
    }
  case SUCCESS_CLOSED:
    return {
      ...states,
      loading: false,
      error: false,
      data: action.payload
    }
  case ERROR_CLOSED:
    return {
      ...states,
      loading: false,
      error: true,
      data: action.payload
    }
  case RESET_CLOSED:
    return defaultState
  default:
    return states
  }
}
