const ERROR_DYNAMICS = 'Erro ao obter dados do usuário no Dynamics.'
const ERROR_USER_UNITY = 'Seu usuário não possui unidades cadastradas.'
const ERROR_APP_NOTFOUND = 'Nenhum aplicativo encontrado para o usuário.'

const ERRORS_LOGIN_ADMIN = { ERROR_DYNAMICS, ERROR_USER_UNITY, ERROR_APP_NOTFOUND }

export const ERROR_LABEL_BUTTON = 'Efetuar Logoff'

export const LIST_ERROR_MESSAGE = {
  [ERRORS_LOGIN_ADMIN.ERROR_DYNAMICS]: ERRORS_LOGIN_ADMIN.ERROR_DYNAMICS,
  [ERRORS_LOGIN_ADMIN.ERROR_USER_UNITY]: ERRORS_LOGIN_ADMIN.ERROR_USER_UNITY,
  [ERRORS_LOGIN_ADMIN.ERROR_APP_NOTFOUND]: ERRORS_LOGIN_ADMIN.ERROR_APP_NOTFOUND
}