import { apiReducer } from "utils/API";
import { INFORMATION_RECURRENT_CARD } from "../constants";
import { reduceReducers } from 'utils/API/reducer/reducer-utils'
import { capitalizeString } from 'utils/utils';

const mapSuccessData = (action, states) => {
  const response = action.data;

  if (response) {
    if (response.expiration_month && response.expiration_month.length == 1) {
      response.expiration_month = `0${response.expiration_month}`;
    }
    
    if (response.expiration_year && response.expiration_year.length == 2) {
      response.expiration_year = `20${response.expiration_year}`;
    }

    if (response.brand) {
      response.brandTitle = capitalizeString(response.brand);
    
      if (response.brand == 'hipercard') {
        response.brand = 'hiper';
      }
    }
  }

  return response;
};

export default reduceReducers(
  apiReducer({
    entity: INFORMATION_RECURRENT_CARD,
    defaultData: null,
    mapSuccessData,
  })
)