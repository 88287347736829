import React, {useMemo} from 'react';
import {createComponent} from '@lit-labs/react';
import {SebCsat as CsatImport} from 'seb-interactive-islands';
import {useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {ApolloClient, gql, InMemoryCache} from "@apollo/client";
import "seb-interactive-islands/seb-csat.js";
import {unidadeGetResponseState} from "../../recoil/atoms/unidadeGetResponseState";
const {SebCsat,  createMutation, typeDefs, updateMutation} = CsatImport;

const LitComponent = createComponent({
  tagName: 'seb-csat',
  elementClass: SebCsat,
  react: React,
  events: {
    onScoreClicked: 'score-clicked',
    onClosed: 'closed',
    onSubmit: 'submit'
  },
});

const Csat = (props) => {
  const {
    show,
    scoreTitle = "Como você avalia o atendimento?",
    children,
    context = {},
  } = props;
  const dd = window?.DD_RUM || {};
  const {
    getInternalContext = () => {},
    getSessionReplayLink = () => {},
  } = dd || {};
  const ddContext = getInternalContext();
  const location = useLocation();
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const unidade = useRecoilValue(unidadeGetResponseState);
  const defaultContext = useMemo(() => {
    return JSON.stringify({
      version: window.appVersion,
      dd: ddContext,
      dd_replay: getSessionReplayLink(),
      question: scoreTitle,
      path: location.pathname,
      unidadeId: sessionStorage.getItem("@SMO20/unidade_Id") ?? unidade?.id ?? null,
      unidade,
      emailUsuarioLogado: sessionStorage.getItem("@SMO20/email"),
      idResponsavel: sessionStorage.getItem("@SMO20/idResponsavel"),
      selecaoServicos: JSON.parse(sessionStorage.getItem("selecaoServicos") || "{}"),
      cpf: sessionStorage.getItem("@SMO20/cpf"),
      matricula: selectedMatricula || {},
      ...context,
    })
  }, [scoreTitle, location.pathname, context, selectedMatricula, unidade, ddContext]);

  return (
    <LitComponent
      client={new ApolloClient({
        cache: new InMemoryCache(),
        uri: process.env.REACT_APP_CSAT_API || "",
        typeDefs: gql(typeDefs),
      })}
      createMutation={gql(createMutation)}
      updateMutation={gql(updateMutation)}
      type="popup"
      position="bottom"
      scoreTitle={scoreTitle}
      canClose="true"
      show={show}
      context={defaultContext}
      {...props}
    >
      {children}
    </LitComponent>
  );
}

export default Csat;
