const blockedResourcesEntitiesValues = {
  Matricula: 0,
  Unidade: 1,
}

const blockedResourcesEntities = [
  {
    "value": blockedResourcesEntitiesValues.Matricula,
    "label": "Matrícula"
  },
  {
    "value": blockedResourcesEntitiesValues.Unidade,
    "label": "Unidade"
  }
]
export { blockedResourcesEntitiesValues };
export default blockedResourcesEntities;