import { apiRequestActionType, apiSuccessActionType, apiErrorActionType, apiResetActionType } from 'utils/API/action-types'
import { STYLE_CELLS } from '../../constants'
import moment from 'moment'

export const CLOSED = 'CLOSED'
export const REQUEST_CLOSED = apiRequestActionType(CLOSED)
export const SUCCESS_CLOSED = apiSuccessActionType(CLOSED)
export const ERROR_CLOSED = apiErrorActionType(CLOSED)
export const RESET_CLOSED = apiResetActionType(CLOSED)

export const smColumns = [
  // { title: 'Mês/Ano', field: 'transactionDateFormatted', cellStyle: STYLE_CELLS, customSort: (param1, param2) => Date.parse(param1.transactionDate) - Date.parse(param2.transactionDate) },
  { title: 'Liquidação', field: 'liquidation', cellStyle: STYLE_CELLS, type: 'date', customSort: (param1, param2) => moment(param1.liquidation, 'DD/MM/YYYY').diff(moment(param2.liquidation, 'DD/MM/YYYY')) }
]

export const otherMediumColumns = [
  { title: 'Fatura', field: 'invoice', cellStyle: STYLE_CELLS },
  { title: 'Valor Documento', field: 'valueFormatted', cellStyle: STYLE_CELLS, customSort: (param1, param2) => parseFloat(param1.value) - parseFloat(param2.value) },
  { title: 'Valor Liquidado', field: 'valueliquidateFormatted', cellStyle: STYLE_CELLS, customSort: (param1, param2) => parseFloat(param1.valueliquidate) - parseFloat(param2.valueliquidate) },
  { title: 'Débito Automático', field: 'automaticdebitFormated', cellStyle: STYLE_CELLS }
]

export const otherColumns = [
  { title: 'Aluno', field: 'studentnameFormated', cellStyle: STYLE_CELLS },
  { title: 'Situação', field: 'situacaoFormated', cellStyle: STYLE_CELLS, render: value => value?.acordoptr === "1" ? "Acordo" : value?.situacaoFormated, },
  { title: 'Modo de Pagamento', field: 'paymenttype', cellStyle: STYLE_CELLS }
]
