
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { mapRoutes } from 'src/routes'
import { hasAny } from 'utils/permissions'

import { permissionsSelector } from 'modules/Login/selectors/user'

export function useRoutesByUserPermissions() {
  const permissions = useSelector(permissionsSelector)
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    setRoutes(
      mapRoutes(route => route,
        route => hasAny(permissions, route.permissions || [] ))
    )
  }, [permissions])

  return routes
}
