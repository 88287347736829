import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 230

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary?.backgroundColor || '#f0f0f1',
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    margin: 0,
  },
  contentShift: {
    '@media print': {
      display: 'none'
    },
    padding: theme.spacing(3),
    paddingBottom: 60,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  container: {
    padding: 0
  },
  contact: {
    color: '#cfcfcf'
  }
}))

export default useStyles
