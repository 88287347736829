import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  footer: {
    marginBottom: 20,
    marginTop: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    },
    justifyContent: 'center',
  },
  footerLogo: {
    width: 70,
    height: 50,
    opacity: 0.5,
  },
  footerIconsButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  footerInfoText: {
    fontWeight: 'bold'
  },
  footerCopyright: {
    fontWeight: 100,
    fontSize: '0.70rem'
  },
  footerCallCenter: {
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      lineHeight: '1.3'
    }
  },
  footerContainerLogo: {
    marginBottom: 5
  },
  footerTour: {
    marginBottom: 20
  },
  footerTextTour: {
    color: theme.palette.primary.main,
    '& span': {
      fontWeight: 500,
    }
  },
  footerIcon: {
    color: theme.palette.primary.main,
  }
}))
  