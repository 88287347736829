import { postAgreement } from 'modules/Negotiation/Finance/actions'

const urlRegex = /financial\/*.*\/postagreementcreditcard/

export const addMock = (mock) => {
  mock
    .onPost(urlRegex).reply(200, response)
}

export const requestApi = (store) => {
  store.dispatch(postAgreement({}, 0 , false, null))
}

const initializeMock = (mock, store) => {
  requestApi(store)
  addMock(mock, store)
}

const response = {
  'response':'O acordo está sendo processado, logo estará disponível para consulta.',
  'message':null
}

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}
