import React, { Fragment } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { hide } from 'layouts/actions'
import { snackSelector } from 'layouts/selectors/snack'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { SnackbarContent } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  message: {
    textAlign: 'center',
    paddingRight: 8,
    paddingLeft: 8,
  },
  anchorOriginBottomCenter: {
    [theme.breakpoints.up(600)]: {
      left: 0,
      right: 0,
      transform: 'none'
    }
  }
}))

const Snack = () => {
  const { open, message, autoHideDuration, action, customMessage: CustomMessage } = useSelector(snackSelector)
  const dispatch = useDispatch()
  const classes = useStyles()
  const Action = action
  
  return <Snackbar    
    autoHideDuration={autoHideDuration}
    open={open}
    classes={{
      anchorOriginBottomCenter: classes.anchorOriginBottomCenter
    }}
    onClose={() => dispatch(hide())}
    ContentProps={{
      'aria-describedby': 'message-id',
    }} >
    <SnackbarContent
      classes={{
        message: classes.message,
      }}
      message={
        CustomMessage ? <CustomMessage />
          : <span className={classes.message} id='message-id'>{message}</span>
      }
      action={!autoHideDuration && Action && typeof Action === 'function' ? <Action /> : Action}
    />
  </Snackbar>
}

export default () => (
  <Fragment>
    <Snack />
  </Fragment>)