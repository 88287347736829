import { map } from 'lodash'
import { fetchFinancial } from 'modules/Negotiation/Finance/actions'
import { PAY_WAYS } from 'modules/Negotiation/Finance/utils'
import { expiratedSelectedSelector, hasLoadedExpiratedSelector } from 'modules/Negotiation/Finance/selectors/expirated'
import { isFetchFinancialSelector, isMockFinancialSelector } from 'modules/Negotiation/Finance/selectors/financialAgreement'

const urlRegex = /financial\/createfinancialagreement/

const addMock = (mock, store) => {
  const expirated = expiratedSelectedSelector(store.getState())

  if (expirated) {
    mock
      .onPost(urlRegex).reply(200, response(expirated))
  }
}

const requestApi = (store, mock) => {
  const expirated = expiratedSelectedSelector(store.getState())
  store.dispatch(fetchFinancial(1, expirated, 0, PAY_WAYS.CARD))
}

const initializeMock = (mock, store) => {
  const hasExpirated = hasLoadedExpiratedSelector(store.getState())
  if (hasExpirated) {
    if (isFetchFinancialSelector(store.getState())) {
      requestApi(store, mock)
    } else if (isMockFinancialSelector(store.getState())) {
      addMock(mock, store)
    }
  }
}

const response = (items) => ({
  'response': {
    'financialAgreementId': 'CDB-082369',
    'financialAgreementProposalId': '',
    'custAccount': 'CDBC-005624',
    'fiscalEstablishmentId_Br': '1401',
    'paymWayId': 'C. CREDITO',
    'entryPercent': 0.0,
    'installmentQty': 0,
    'custAccountStudent': 'CDBC-005259',
    'list': map(items, responseList),
    'listOpenInstallment': [

    ],
    'BoletoInfo': [
      {
        'Aceite': '',
        'AlunoNOme': '',
        'BankCode': '',
        'CedenteCodigo': '',
        'CedenteNome': '',
        'Cnpjcpf': '',
        'CodBarras': '',
        'CodigoDV': '',
        'CodigoOcorrencia': '',
        'CodigoTransmissao': '',
        'ContaBancariaAgencia': '',
        'ContaBancariaCarteiraPadrao': '',
        'ContaBancariaConta': '',
        'ContaBancariaDigitoAgencia': '',
        'ContaBancariaDigitoConta': '',
        'ContaBancariaMensagemFixaTopoBoleto': '',
        'ContaBancariaOperacaoConta': '',
        'ContaBancariaTipoCarteiraPadrao': '0',
        'ContaBancariaTipoDocumento': '0',
        'ContaBancariaTipoFormaCadastramento': '0',
        'ContaBancariaTipoImpressaoBoleto': '0',
        'ContaBancariaVariacaoCarteiraPadrao': '',
        'DataEmissao': '',
        'DataVencimento': '',
        'DescricaoOcorrencia': '',
        'DetalheDaFatura': '',
        'EnderecoLogradouroBairro': '',
        'EnderecoLogradouroCEP': '',
        'EnderecoLogradouroCidade': '',
        'EnderecoLogradouroComplemento': '',
        'EnderecoLogradouroEndereco': '',
        'EnderecoLogradouroNumero': '',
        'EnderecoLogradouroUF': '',
        'EnderecoSAcadoCEP': '',
        'EnderecoSAcadoNumero': '',
        'EnderecoSAcadobairro': '',
        'EnderecoSacadoCidade': '',
        'EnderecoSacadoComplemento': '',
        'EnderecoSacadoEndereco': '',
        'EnderecoSacadoUF': '',
        'EspecieDocumento': '0',
        'GMPacoteDeCursoDescricao_PTR': '',
        'GMTurnoNome': '',
        'GrupoDemonstrativo': '',
        'LedgerjournalTrans': '0',
        'LinhaDigitavel': '',
        'MAtriculaId': '',
        'MensagemInformacaoAdicional': '',
        'MensagemInstrucoesCaixa': '',
        'MsgReferenciaPedido': '',
        'NossoNumero': '',
        'NumeroControleParticipante': '',
        'NumeroDocumento': '',
        'SacadoCNPJCPF': '',
        'SacadoNome': '',
        'ValorTitulo': '0,00',
        'pacoteDeCursoId': ''
      }
    ],
    'listCustTable': [
      {
        'custAccount': 'CDBC-005624',
        'custName': 'DIULI PETTERLE',
        'rgNumber': '40827188',
        'cpfCnpj': '006954309-71',
        'state': 'PR',
        'zipCode': '80540220',
        'custAddress': 'EMILIO CORNELSEN, 500 BL D 3 AND - CURITIBA'
      }
    ],
    'listMatriculas': [
      'CDB-0385603'
    ],
    'ListInvoices': [
      'CDB-0385603 1/11',
      'CDB-0385603 1/12',
      'CDB-0385603 2/11'
    ],
    'paymModeId': '',
    'boletoHTML': '',
    'unitName': 'DOM BOSCO AHU',
    'dataAreaId': '',
    'installments': [

    ],
    'DueDateList': null,
    'CreatedFromAPI': 0
  },
  'message': null
})

const responseList = (item) => ({
  'financialAgreementId': 'CDB-082369',
  'financialAgreementProposalId': '',
  'amountCur': item.correctvalue,
  'dueDate': item.expirationdate,
  'lineNum': 1.0,
  'interestAmount': 0.0
})

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}
