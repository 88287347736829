import React from 'react'
import { groupBy, map, isNil, find } from 'lodash'
import moment from 'moment'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Box,
  Typography,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import BarGraph from 'components/barChart'
import { useSelector } from 'react-redux'
import { dashboardDataSelector } from '../selectors/dashboard'
import { textIntl } from 'utils/intl'

export default ({ handleClose, info, isOpen }) => {
  const data = useSelector(dashboardDataSelector)
  const theme = useTheme()

  if (isNil(data)) {
    return null
  }

  const groupByDay = groupBy(data.analyticsByHour, 'dateOfReport')
  const month = find(groupByDay, (data, index) => moment(index).isSame(moment(info, 'DD/MM')))
  const labels = map(month, 'hourOfReport')
  const valuesReduce = map(month, 'visitCount') || []

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <FormattedMessage id='dashboard-dialog-chat-by-hour-title' values={{ date: info }} />
      </DialogTitle>
      <DialogContent>
        <Box width={560}>
          <BarGraph
            data={{
              labels: [...labels],
              datasets: [{
                label: textIntl('dashboard-dialog-graph-hour'),
                data: [...valuesReduce],
                backgroundColor: theme.palette.primary.main
              }]
            }}
          />
        </Box>
        <Typography color='secondary' align='right' variant='caption' display='block' gutterBottom>
          <FormattedMessage id='dashboard-dialog-font' />
        </Typography>
        <Typography variant='caption' color='secondary' align='left' display='block' gutterBottom>
          <FormattedMessage id='dashboard-dialog-observation' />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} color='primary'>
          <FormattedMessage id='dashboard-dialog-chat-by-hour-close' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
