import { pipe, prop, isEmpty } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'

const baseNegotiationSelector = prop('negotiation')

const baseFinanceSelector = pipe(baseNegotiationSelector, prop('finance'))

export const companyInformationsSelector = pipe(baseFinanceSelector, prop('companyInformations'))

export const companyInformationDataSelector = getAPIDataSelector(companyInformationsSelector)

export const hasLoadedCompanyInformationsSelector = hasLoadedAPISelector(companyInformationsSelector)

export const hasErrorCompanyInformationsSelector = hasErrorAPISelector(companyInformationsSelector)

export const isLoadingCompanyInformationSelector = isLoadingAPISelector(companyInformationsSelector)

export const isMockCompanySelector = createSelector(
  hasLoadedCompanyInformationsSelector,
  hasErrorCompanyInformationsSelector,
  companyInformationDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
)

export const isFetchCompanySelector = createSelector(
  hasLoadedCompanyInformationsSelector,
  isLoadingCompanyInformationSelector,
  hasErrorCompanyInformationsSelector,
  companyInformationDataSelector,
  (hasLoaded, isLoading,  hasError, data) =>  isEmpty(data) && !hasLoaded && !isLoading && !hasError)