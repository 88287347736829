import { API_REQUEST, API_REQUEST_HTTP_METHODS } from '../constants'
import { ignoreAPIRequestError } from '../request'

const apiBase = ({
  entity,
  meta,
  method,
  url,
  data,
  config,
  shouldStoreData,
  hasPagination = false,
  requiresAuthentication,
  isChainable = false,
  validation,
  analytics,
}) => dispatch => {
  const promise = new Promise((resolve, reject) =>
    dispatch({
      type: API_REQUEST,
      payload: {
        entity,
        meta,
        hasPagination,
        requiresAuthentication,
        request: { method, url, data, config },
        shouldStoreData,
        onSuccess: resolve,
        onError: reject,
        error: null,
        validation,
        analytics
      },
    })
  )

  if (!isChainable) {
    promise.catch(ignoreAPIRequestError)

    return null
  }

  return promise
}

export const apiGet = params =>
  apiBase({
    ...params,
    method: API_REQUEST_HTTP_METHODS.GET,
    data: undefined,
  })

export const apiPost = params =>
  apiBase({
    ...params,
    method: API_REQUEST_HTTP_METHODS.POST,
  })

export const apiPut = params =>
  apiBase({
    ...params,
    method: API_REQUEST_HTTP_METHODS.PUT,
  })

export const apiPatch = params =>
  apiBase({
    ...params,
    method: API_REQUEST_HTTP_METHODS.PATCH,
  })

export const apiDelete = params =>
  apiBase({
    ...params,
    method: API_REQUEST_HTTP_METHODS.DELETE,
    data: undefined,
  })
