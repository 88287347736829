import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import useStyles from '../style'
import LoadingModal from 'components/LoadingModal'

export default (isLoading) => {
  const classes = useStyles()
  return (
    <div className={classes.container} >
      <Grid container justify='center'>
        <div className={classes.paper}>
          <LoadingModal loading={isLoading} />
        </div>
      </Grid>
      <CssBaseline />
    </div>
  )
}