import React from 'react'
import { MTableBodyRow } from 'material-table'
import { Box, ListItemAvatar, ListItemText } from '@material-ui/core'
import PaymentIcon from '../../../../../PaymentIcons/PaymentIcon'
import useStyles from 'modules/Finance/PaymentTypes/style'
import { ReceiptTwoTone } from '@material-ui/icons'

function Index(props) {
  const {
    selectedInfo,
    cardBrand,
    cardNumber,
    metodoPagamento,
    isBoleto,
    ...rest
  } = props
  const classes = useStyles()

  if (metodoPagamento !== 'Cartão de crédito recorrente') {
    rest.data.cardInfo = (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ListItemAvatar
          className={classes.cardInfo}
        >
          <ReceiptTwoTone />
        </ListItemAvatar>
        <ListItemText
          className={classes.cardInfo}
          primary={metodoPagamento}
          disableTypography
          style={{ maxWidth: '80px' }}
        />
      </Box>
    )
  } else {

    const getLastFourDigits = (cardNumber) => {
      if (cardNumber.length <= 4) return cardNumber
      return cardNumber.slice(-4)
    }

    if (cardBrand || cardNumber) {
      rest.data.cardInfo = (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {cardBrand && (
            <ListItemAvatar
              className={classes.cardInfo}
            >
              <PaymentIcon
                id={cardBrand}
                style={{ width: 50 }}
                className='payment-icon'
              />
            </ListItemAvatar>
          )}
          {cardNumber && (
            <ListItemText
              className={classes.cardInfo}
              primary={`**** ${getLastFourDigits(cardNumber)}`}
              disableTypography
              style={{ maxWidth: '80px' }}
            />
          )}
        </Box>
      )
    }
  }

  return <MTableBodyRow {...rest} />
}

export default Index