import React, { Fragment, useState } from 'react'
import { Prompt, withRouter } from 'react-router-dom'
import { Beforeunload } from 'react-beforeunload'
import { Dialog, Button } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles'

const defaultMessage = 'As alterações feitas não serão salvas.'

const useStyles = makeStyles(({
  button: {
    width: 150,
    margin: 5
  },
}))

const ButtonsRoute = withRouter(({ history, setConfirmNavigation, handleClose, nextLocation, classes }) => (
  <div>
    <Button
      className={classes.button}
      variant='contained'
      color='secondary'
      id='btn-cancel-Location'
      type='button'
      onClick={() => {
        setConfirmNavigation(false)
        handleClose()
      }}>
      Cancelar
    </Button>
    <Button
      className={classes.button}
      variant='contained'
      color='primary'
      id='btn-confirm-location'
      type='button'
      onClick={() => (history.push(nextLocation.pathname))}
    >
      Confirmar
    </Button>
  </div>
))

const withPromptDirtyForm = (Component, message = defaultMessage) => props => {
  const [nextLocation, setLocation] = useState()
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [confirmNavigation, setConfirmNavigation] = useState(false)
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const showModal = (nextLocation) => {
    setLocation(nextLocation)
    setConfirmNavigation(true)
    handleOpen()
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmNavigation) {
      showModal(nextLocation)
      return false
    }
    setConfirmNavigation(false)
    return true
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      {props.dirty && <Beforeunload onBeforeunload={event => event.preventDefault()} />}
      <Prompt message={handleBlockedNavigation} when={props.dirty} />
      <Dialog
        maxWidth={'sm'}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{'Alterações não Salvas'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions id='dialog-actions'>
          <ButtonsRoute setConfirmNavigation={setConfirmNavigation} handleClose={handleClose} nextLocation={nextLocation} classes={classes} />
        </DialogActions>
      </Dialog>
      <Component {...props} />
    </Fragment>)
}

export default withPromptDirtyForm