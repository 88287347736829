import React from "react";
import loadingGif from "./loading.gif";

export default function LoadingAnimation(
  {
    size = 24
  }
) {
  return (
    <div className="loading-animation-container">
      <img
        className="img-responsive"
        width={size}
        height={size}
        src={loadingGif}
        alt="Carregando..."
      />
    </div>
  )
}
