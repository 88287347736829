import { pipe, prop } from 'ramda'
import { isEmpty, find, isNil, isArray } from 'lodash'
import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'
import { isWindowType, isPopUpType } from 'modules/Admin/Message/utils'
import moment from 'moment'
import { userAcessIdSelector } from 'modules/Login/selectors/user'

const baseHomeSelector = prop('home')

export const userMessageSelector = pipe(baseHomeSelector, prop('userMessage'))

export const userMessageDataSelector = getAPIDataSelector(userMessageSelector)

export const hasLoadedUserMessageSelector = hasLoadedAPISelector(userMessageSelector)

export const hasErrorUserMessageSelector = hasErrorAPISelector(userMessageSelector)

export const isLoadingUserMessageSelector = isLoadingAPISelector(userMessageSelector)

export const isMockMessage = createSelector(
  hasLoadedUserMessageSelector,
  hasErrorUserMessageSelector,
  userMessageDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
)

export const isFetchMessage = createSelector(
  hasLoadedUserMessageSelector,
  isLoadingUserMessageSelector,
  hasErrorUserMessageSelector,
  userMessageDataSelector,
  (hasLoaded, isLoading,  hasError, data) =>  isEmpty(data) && !hasLoaded && !isLoading && !hasError
)

export const userMessageNotificationSelector = createSelector(
  userMessageDataSelector,
  (data) => isArray(data) ? find(data, ({ messagingExhibitionType }) => isWindowType(messagingExhibitionType)) : data
)

export const userMessagePopUpSelector = createSelector(
  userMessageDataSelector,
  (data) => isArray(data) ? find(data, ({ messagingExhibitionType }) => isPopUpType(messagingExhibitionType)) : data
)

export const idMessageNotificationSelector = createSelector(
  userMessageNotificationSelector,
  (state) => userAcessIdSelector(state),
  (data, accessId) => {
    let id = !isNil(accessId) ? accessId : ''
    if (!isNil(data)) {
      id = id + data.idMessaging + moment(data.modifiedDate || data.createdDate).format('DDMMYYYY')
    }
    return id
  }
)

export const idMessagePopUpSelector = createSelector(
  userMessagePopUpSelector,
  (state) => userAcessIdSelector(state),
  (data, accessId) => {
    let id = !isNil(accessId) ? accessId : ''
    if (!isNil(data)) {
      id = id + data.idMessaging + moment(data.modifiedDate || data.createdDate).format('DDMMYYYY')
    }
    return id
  }
)

export const isOnlyOneExhibitionPopUpSelector = createSelector(
  userMessagePopUpSelector, 
  data => data && data.onlyOneExhibition
)

export const isOnlyOneExhibitionNotificationSelector = createSelector(
  userMessageNotificationSelector, 
  data => data && data.onlyOneExhibition
)
