import { map } from 'lodash'
import { PATH_ROUTE } from '../../../routes'
import initial from './initial'
import declarations from './declarations'
import negotiation from './negotiation'
import negotiationHistory from './negotiationHistory'

export const tourList = [
  ...map(initial, (item) => ({ path: PATH_ROUTE.HOME, ...item })), 
  ...map(negotiation, (item) => ({ path: PATH_ROUTE.NEGOTIATION_FINANCE, ...item })), 
  ...map(negotiationHistory, (item) => ({ path: PATH_ROUTE.NEGOTIATION_HISTORY, ...item })), 
  ...map(declarations, (item) => ({ path: PATH_ROUTE.TAX, ...item })), 
]