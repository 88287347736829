import { apiRequestActionType, apiSuccessActionType, apiErrorActionType, apiResetActionType } from 'utils/API/action-types'

export const HISTORY_NEGOTIATION = 'HISTORY_NEGOTIATION'
export const REQUEST_HISTORY_NEGOTIATION = apiRequestActionType(HISTORY_NEGOTIATION)
export const SUCCESS_HISTORY_NEGOTIATION = apiSuccessActionType(HISTORY_NEGOTIATION)
export const ERROR_HISTORY_NEGOTIATION = apiErrorActionType(HISTORY_NEGOTIATION)
export const RESET_HISTORY_NEGOTIATION = apiResetActionType(HISTORY_NEGOTIATION)

export const ACCEPT_TERM_NEGOTIATION = 'ACCEPT_TERM_NEGOTIATION'
export const REQUEST_ACCEPT_TERM_NEGOTIATION = apiRequestActionType(ACCEPT_TERM_NEGOTIATION)
export const SUCCESS_ACCEPT_TERM_NEGOTIATION = apiSuccessActionType(ACCEPT_TERM_NEGOTIATION)
export const ERROR_ACCEPT_TERM_NEGOTIATION = apiErrorActionType(ACCEPT_TERM_NEGOTIATION)
export const RESET_ACCEPT_TERM_NEGOTIATION = apiResetActionType(ACCEPT_TERM_NEGOTIATION)
