import {
  apiRequestActionType,
  apiSuccessActionType,
  apiErrorActionType,
  apiResetActionType,
} from "utils/API/action-types";

export const OPENED = "OPENED";
export const REQUEST_OPENED = apiRequestActionType(OPENED);
export const SUCCESS_OPENED = apiSuccessActionType(OPENED);
export const ERROR_OPENED = apiErrorActionType(OPENED);
export const RESET_OPENED = apiResetActionType(OPENED);
export const REFRESH_OPENED = "REFRESH_OPENED";

export const BILLET = "BILLET";
export const REQUEST_BILLET = apiRequestActionType(BILLET);
export const SUCCESS_BILLET = apiSuccessActionType(BILLET);
export const RESET_BILLET = apiResetActionType(BILLET);

export const SEND_PAYMENT = "SEND_PAYMENT";
export const REQUEST_SEND_PAYMENT = apiRequestActionType(SEND_PAYMENT);
export const ERROR_SEND_PAYMENT = apiErrorActionType(SEND_PAYMENT);

export const PAYMENT = "PAYMENT";
export const RESET_PAYMENT = apiResetActionType(PAYMENT);

export const PAYMENT_DEBIT = "PAYMENT_DEBIT";
export const REQUEST_PAYMENT_DEBIT = apiRequestActionType(PAYMENT_DEBIT);
export const SUCCESS_PAYMENT_DEBIT = apiSuccessActionType(PAYMENT_DEBIT);
export const ERROR_PAYMENT_DEBIT = apiSuccessActionType(PAYMENT_DEBIT);

export const PAYMENT_CREDIT = "PAYMENT_CREDIT";
export const REQUEST_PAYMENT_CREDIT = apiRequestActionType(PAYMENT_CREDIT);
export const SUCCESS_PAYMENT_CREDIT = apiSuccessActionType(PAYMENT_CREDIT);
export const ERROR_PAYMENT_CREDIT = apiSuccessActionType(PAYMENT_CREDIT);

export const WAITING_REDE_PAYMENT = "WAITING_REDE_PAYMENT";

export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const REQUEST_CONFIRM_PAYMENT = apiRequestActionType(CONFIRM_PAYMENT);
export const SUCCESS_CONFIRM_PAYMENT = apiSuccessActionType(CONFIRM_PAYMENT);

export const MESSAGE_ERROR_PAYMENT_CREDIT =
  "Erro PTC-5 - Não foi  possível realizar o pagamento por cartão de crédito.";
export const MESSAGE_ERROR_PAYMENT_DEBIT =
  "Erro PTC-5 - Não foi  possível realizar o pagamento por cartão de débito.";

export const IS_SUCCESS_REDE = "isSuccessRede";

export const PAYMENTTYPES_REGISTRATION = "PAYMENTTYPES_REGISTRATION";
export const PAYMENTTYPES_REGISTRATION_REQUEST = apiRequestActionType(
  PAYMENTTYPES_REGISTRATION
);
export const PAYMENTTYPES_REGISTRATION_SUCCESS = apiSuccessActionType(
  PAYMENTTYPES_REGISTRATION
);
export const PAYMENTTYPES_REGISTRATION_ERROR = apiErrorActionType(
  PAYMENTTYPES_REGISTRATION
);

export const PAYMENT_TYPE_SELECTED = "PAYMENT_TYPE_SELECTED";
export const PAYMENT_TYPE_SELECTED_REQUEST = apiRequestActionType(
  PAYMENT_TYPE_SELECTED
);
export const PAYMENT_TYPE_SELECTED_SUCCESS = apiSuccessActionType(
  PAYMENT_TYPE_SELECTED
);
export const PAYMENT_TYPE_SELECTED_ERROR = apiErrorActionType(
  PAYMENT_TYPE_SELECTED
);

export const PAYMENT_TYPE_SIMPLE_MODE = "PAYMENT_TYPE_SIMPLE_MODE";
export const PAYMENT_TYPE_SIMPLE_MODE_REQUEST = apiRequestActionType(
  PAYMENT_TYPE_SIMPLE_MODE
);
export const PAYMENT_TYPE_SIMPLE_MODE_SUCCESS = apiSuccessActionType(
  PAYMENT_TYPE_SIMPLE_MODE
);
export const PAYMENT_TYPE_SIMPLE_MODE_ERROR = apiErrorActionType(
  PAYMENT_TYPE_SIMPLE_MODE
);
export const MESSAGE_ERROR_PAYMENT_TYPE_SIMPLE_MODE =
  "Erro ao mudar para Forma de Pagamento Básica";

export const PAYMENT_TYPE_AUTOMATIC_DEBIT = "PAYMENT_TYPE_AUTOMATIC_DEBIT";
export const PAYMENT_TYPE_AUTOMATIC_DEBIT_REQUEST = apiRequestActionType(
  PAYMENT_TYPE_AUTOMATIC_DEBIT
);
export const PAYMENT_TYPE_AUTOMATIC_DEBIT_SUCCESS = apiSuccessActionType(
  PAYMENT_TYPE_AUTOMATIC_DEBIT
);
export const PAYMENT_TYPE_AUTOMATIC_DEBIT_ERROR = apiErrorActionType(
  PAYMENT_TYPE_AUTOMATIC_DEBIT
);
export const MESSAGE_ERROR_PAYMENT_TYPE_AUTOMATIC_DEBIT =
  "Erro ao mudar para Forma de Pagamento Débito Automático";

export const PAYMENT_TYPE_RECURRENCE_PAYMENT =
  "PAYMENT_TYPE_RECURRENCE_PAYMENT";
export const PAYMENT_TYPE_RECURRENCE_PAYMENT_REQUEST = apiRequestActionType(
  PAYMENT_TYPE_RECURRENCE_PAYMENT
);
export const PAYMENT_TYPE_RECURRENCE_PAYMENT_SUCCESS = apiSuccessActionType(
  PAYMENT_TYPE_RECURRENCE_PAYMENT
);
export const PAYMENT_TYPE_RECURRENCE_PAYMENT_ERROR = apiErrorActionType(
  PAYMENT_TYPE_RECURRENCE_PAYMENT
);
export const MESSAGE_ERROR_PAYMENT_TYPE_RECURRENCE_PAYMENT =
  "Erro ao mudar para Forma de Pagamento Crédito Recorrente";

export const ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE =
  "ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE";
export const ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE_REQUEST = apiRequestActionType(
  ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE
);
export const ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE_SUCCESS = apiSuccessActionType(
  ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE
);
export const ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE_ERROR = apiErrorActionType(
  ACCEPT_TERM_AGREEMENT_PAYMENT_TYPE
);

export const INFORMATION_RECURRENT_CARD = "INFORMATION_RECURRENT_CARD";
export const INFORMATION_RECURRENT_CARD_REQUEST = apiRequestActionType(
  INFORMATION_RECURRENT_CARD
);
export const INFORMATION_RECURRENT_CARD_SUCCESS = apiSuccessActionType(
  INFORMATION_RECURRENT_CARD
);
export const INFORMATION_RECURRENT_CARD_ERROR = apiErrorActionType(
  INFORMATION_RECURRENT_CARD
);