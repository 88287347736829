import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { appIdSelector, accessIdSelector, isTourEnabledSelector, isNotificationEnabledSelector } from 'modules/Login/selectors/user'
import Grow from '@material-ui/core/Grow'

import { userMessageSelector, userMessageNotificationSelector } from 'modules/Home/selectors/userMessage'
import BannerNotification from '../BannerNotification'
import { fetchClientMessages } from 'modules/Home/actions'
import { disableNotification } from 'modules/Login/actions'

export default () => {
  const dispatch = useDispatch()
  const appId = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const { loading, loaded } = useSelector(userMessageSelector)
  const isShowNotification = useSelector(isNotificationEnabledSelector)
  const data = useSelector(userMessageNotificationSelector)
  const isTourOpen = useSelector(isTourEnabledSelector)
  const [ enableMessage, setEnableMessage ] = useState(false)

  useEffect(() => {
    if (!isTourOpen && !loaded) {
      dispatch(fetchClientMessages(appId))
    }
  }, [appId, accessId, dispatch, isTourOpen])
  
  useEffect(() => {
    if (!enableMessage && isShowNotification && data && data.idMessaging) {
      setEnableMessage(isShowNotification)
      dispatch(disableNotification())
    }
  }, [isShowNotification, data])

  if(loading || !enableMessage) {
    return null
  }
  
  return (
    <Grow in={!!data} timeout={200}> 
      <div>
        {data && <BannerNotification {...data} />}
      </div>
    </Grow>)
}
