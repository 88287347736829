import { mergeDeepRight } from 'ramda'

/**
 * Request initial state.
 *
 * @param {Object} attrs - Attributes to be overwritten
 * @return {Object} Request initial state
 */
export const requestInitialState = (attrs = {}) =>
  mergeDeepRight(
    {
      data: {},
      isLoading: false,
      loaded: false,
      error: '',
    },
    attrs
  )

/**
 * Request loading state reducer.
 *
 * @param {Object} state - State
 * @return {Object} New state
 */
export const requestLoadingState = state => ({
  ...state,
  isLoading: true,
  loaded: false,
})

/**
 * Request success state reducer.
 *
 * @param {Object} state - State
 * @return {Object} New state
 */
export const requestSuccessState = state => ({
  ...state,
  isLoading: false,
  loaded: true,
})

/**
 * Request error state reducer.
 *
 * @param {Object} state - Application state
 * @param {Object} action - Action from action creator
 * @return {Object} New state
 */
export const requestErrorState = (state, action) => ({
  ...state,
  isLoading: false,
  loaded: false,
  error: action.error || true,
})

/**
 * Combines a list of reducers by running then in sequence.
 *
 * @param {...Function} reducers - Reducers to run
 * @return {Function} New reducer
 */
export const reduceReducers = (...reducers) => (state, action) =>
  reducers.reduce((prevState, reducer) => reducer(prevState, action), state)
