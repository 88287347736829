import env from "../env";

export function GetTemplateMovimentacao(idUnidade, tipoMovimentacao) {
  return fetch(env.apiUrl+"api/Movimentacao/GetMovimentacaoTemplate/"+ idUnidade + "/"+ tipoMovimentacao, {
    method: "GET",
    mode: "cors",
  })
    .then(res => res.json())
    .then(
      (result) => {
        return result?.value;
      },
      (error) => {
        return false
      }
    )
    .catch(error => {
      console.log(error.message);
    });
}
