import { pipe, prop, isEmpty } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'
import { accessIdSelectedSelector } from 'modules/Login/selectors/user'

const baseNegotiationSelector = prop('negotiation')

const baseFinanceSelector = pipe(baseNegotiationSelector, prop('finance'))

export const expiratedSelector = pipe(baseFinanceSelector, prop('expirated'))

export const expiratedDataSelector = getAPIDataSelector(expiratedSelector)

export const hasLoadedExpiratedSelector = hasLoadedAPISelector(expiratedSelector)

export const hasErrorExpiratedSelector = hasErrorAPISelector(expiratedSelector)

export const isLoadingExpiratedSelector = isLoadingAPISelector(expiratedSelector)

export const expiratedSelectedSelector = pipe(expiratedSelector, prop('dataSelected'))

export const isMockExpiredSelected = createSelector(
  hasLoadedExpiratedSelector,
  hasErrorExpiratedSelector,
  expiratedDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
)

export const isFetchExpiredSelected = createSelector(
  hasLoadedExpiratedSelector,
  isLoadingExpiratedSelector,
  hasErrorExpiratedSelector,
  expiratedDataSelector,
  accessIdSelectedSelector,
  (hasLoaded, isLoading,  hasError, data) =>  isEmpty(data) && !hasLoaded && !isLoading && !hasError)
