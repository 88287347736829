import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  studantAvatar: {
    width: 80,
    height: 80,
    margin: '10px auto',
    backgroundColor: '#404041'
  },
  studantServices: {
    fontSize: '0.875rem',
  },
  studantContent: {
    padding: '0 20px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  studantName: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    margin: 0
  },
  studantUnity: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: 10,
    fontSize: '0.9rem'
  },
  studantCard: {
    height: '100%'
  },
  studentButton: {
    display: 'flex',
    justifyContent: 'center'
  },
  studantSeeMore: {
    textTransform: 'initial'
  },
  studantServicesModal: {
    maxHeight: '60vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
}))