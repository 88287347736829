
import {  makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  installmentContainer: {
    maxHeight: 80,
    padding: theme.spacing(1)
  },
  installmentContainerPair: {
    backgroundColor: 'rgb(240, 240, 241)'
  },
  installment:{
    fontSize: '0.85rem'
  },
  installmentValue:{
    fontWeight: 'bold'
  },
  root: {
    color: '#757575',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    '&$disabled': {
      background: 'rgba(0, 0, 0, 0.05)',
      color: '#757575',
      boxShadow: 'none',
    }
  },
  disabled: {
    color: '#757575',
  }
}))
