import React from 'react'
import { map, isEqual } from 'lodash'
import { Paper, Grid, MenuItem, Button, CircularProgress, makeStyles, FormControlLabel } from '@material-ui/core'
import UnitiesAutocomplete from 'modules/Admin/components/UnitiesAutocomplete'
import { Form, withFormik, FastField } from 'formik'
import { compose } from 'recompose'
import { textIntl } from 'utils/intl'
import { SEND_TYPES, SEND_TYPES_NAME, isGeneralType, isExpireType } from 'modules/Admin/SMS/utils'
import { TextField, Switch } from 'formik-material-ui'
import DaysToSend from './daysToSend'
import ScheduleDay from './scheduleDay'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { saveSMS, updateSMS, getSMS } from 'modules/Admin/SMS/services/sms'
import { appIdSelector } from 'modules/Login/selectors/user'
import { setMessageSnack } from 'layouts/actions'
import withPromptDirtyForm from 'decorators/withPromptDirtyForm'
import * as yup from 'yup'

const useStyles = makeStyles(() => ({
  form: {
    padding: 20
  },
  item: {
    padding: 10
  },
  button: {
    minWidth: 150,
    margin: '0 8px'
  },
  paper: {
    marginBottom: 20
  }
}))

const SMS = (props) => {
  const classes = useStyles()
  const { values, errors, setSelected, isValid, isSubmitting, setSubmitting } = props
  const dispatch = useDispatch()
  const appId = useSelector(appIdSelector)
  const isEdit = props.idSmsConfiguration

  const saveSms = () => {
    setSubmitting(true)
    dispatch(isEdit ? updateSMS({ ...values, idSmsConfiguration: props.idSmsConfiguration }, appId) : saveSMS(values, appId))
      .then(() => {
        dispatch(setMessageSnack(textIntl(`admin-send-sms-success-${isEdit ? 'edit' : 'add'}`)))
        setSelected({})
        dispatch(getSMS(appId))
        setSubmitting(false)
      })
      .catch(() => {
        dispatch(setMessageSnack(textIntl(`admin-send-sms-error-${isEdit ? 'edit' : 'add'}`)))
        setSubmitting(false)
      })
  }

  return (
    <Paper className={classes.paper}>
      <Form>
        <Grid container className={classes.form} >
          <Grid item xs={12} className={classes.item}>
            <FastField
              variant='outlined'
              label={textIntl('admin-send-sms-unities')}
              placeholder={textIntl('admin-send-sms-unities-placeholder')}
              name='unityIds'
              id='unityIds'
              fullWidth
              component={UnitiesAutocomplete}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <FastField
              name='smsType'
              id='smsType'
              select
              label={textIntl('admin-send-sms-types')}
              fullWidth
              variant='outlined'
              component={TextField}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {map(SEND_TYPES, index => (
                <MenuItem key={index} value={index}>
                  {SEND_TYPES_NAME[index]}
                </MenuItem>)
              )}
            </FastField>
          </Grid>
          {isExpireType(values.smsType) && <DaysToSend classes={classes} />}
          {isGeneralType(values.smsType) && <ScheduleDay {...props} classes={classes} />}
          <Grid item xs={12} sm={12} className={classes.item}>
            <FastField
              name='message'
              id='message'
              label={textIntl('admin-send-sms-message')}
              fullWidth
              multiline
              rows={4}
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
              component={TextField}
            />
          </Grid>
          <Grid item md={3} xs={6} className={classes.item} >
            <FormControlLabel
              control={
                <FastField
                  color='primary'
                  label='active'
                  name='active'
                  id='active'
                  component={Switch} />
              }
              label={textIntl('cadastro-mensagem-active')}
            />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <Button
              color='primary'
              className={classes.button}
              disabled={isSubmitting || (isEdit ? !isEqual(errors, {}) : !isValid) }
              id='btn-save-sms'
              onClick={saveSms}
              variant='contained'
            > {isSubmitting ?
                <CircularProgress size={24} /> :
                <FormattedMessage id={`admin-send-sms-btn-${isEdit ? 'edit' : 'add'}`} />
              }
            </Button>
            {isEdit && !isSubmitting && <Button
              type='reset'
              variant='contained'
              color='secondary'
              className={classes.button}
              id='btn-save-sms'
              onClick={() => setSelected({})}
            > 
              <FormattedMessage id='admin-send-sms-dialog-cancel' />
            </Button>}
          </Grid>
        </Grid>
      </Form>
    </Paper>
  )
}

export default compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => ({
      active: typeof props.active === 'boolean' ? props.active : true,
      unityIds: props.unities || '',
      smsType: props.smsType || 0,
      message: props.message || '',
      daysToExpiration: props.daysToExpiration || 0,
      scheduleToSend: props.scheduleToSend || new Date()
    }),
    validationSchema: props => yup.object().shape({
      message: yup.string().min(5, textIntl('admin-send-sms-min-character')).required(textIntl('admin-send-sms-require')),
      smsType: yup.string().required(textIntl('admin-send-sms-require')),
      unityIds: yup.array(),
      scheduleToSend: yup.date(),
      daysToExpiration: yup.number(),
    })
  }),
  withPromptDirtyForm
)(SMS)