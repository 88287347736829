import { apiGet } from 'utils/API/actions/request'
import { HOME_REGISTRATION } from '../constants'

export const fetchRegistration = (appid, accessId) => apiGet({
  entity: HOME_REGISTRATION,
  url: `/registration/${appid}`,
  config: {
    params: {
      accessId
    }
  }
})
