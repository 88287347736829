import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'
import { textIntl } from 'utils/intl'

const baseLoginSelector = prop('general')

export const unitiesSelector = pipe(baseLoginSelector, prop('unities'))

export const unitiesDataSelector = getAPIDataSelector(unitiesSelector)

export const hasLoadedUnitiesSelector = hasLoadedAPISelector(unitiesSelector)

export const hasErrorUnitiesSelector = hasErrorAPISelector(unitiesSelector)

export const isLoadingUnitiesSelector = isLoadingAPISelector(unitiesSelector)

export const unitiesAllOptionsSelector = createSelector(
  unitiesDataSelector,
  data => [{ siteId: 0, name: textIntl('cadastro-messagem-all-unities') }, ...data]
)