import React, { useRef } from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import Downshift from 'downshift'
import InputBase from '@material-ui/core/InputBase'
import List from '@material-ui/core/List'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { appIdSelector } from 'modules/Login/selectors/user'
import { setAccess } from 'modules/Login/actions'
import { fetchAccess, receiveAccess } from 'layouts/actions'
import FetchStatus from 'utils/fetchStatus'
import { accessSelector } from 'layouts/selectors/access'

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 430,
    overflow: 'auto',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: 150,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  selected: {
    borderLeft: '3px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#ececec'
  },
  highlight: {
    backgroundColor: '#ececec'
  },
  loading: {
    color: '#ffffff'
  }
}))

// TODO: 
// - implementar cancelamento do token
export default function Autocomplete() {
  const classes = useStyles()
  const { data: items, status } = useSelector(accessSelector)
  const appid = useSelector(appIdSelector)
  const dispatch = useDispatch()

  const isRequestAfterSelect = useRef(false)

  const onChange = (inputValue) => {
    
    if(isRequestAfterSelect.current) {
      isRequestAfterSelect.current = false
      return
    }

    if(!inputValue || inputValue === '') {
      dispatch(receiveAccess([]))
      return
    }

    dispatch(fetchAccess(appid, inputValue))
  }

  const onSelect = (selected) => {
    isRequestAfterSelect.current = true
    dispatch(setAccess(selected))
  }

  return (
    <Downshift
      itemToString={item => (item ? item.cpfcnpj : '')}
      onInputValueChange={debounce(onChange, 500)}
      onChange={onSelect}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
      }) => (
        <span>
          <InputBase
            {...getInputProps()}
            placeholder='Trocar CPF...'
            endAdornment={ status === FetchStatus.FETCHING
              && <InputAdornment position='end'>
                <CircularProgress className={classes.loading} size={16} />
              </InputAdornment>
            }
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
          {isOpen && items.length > 0 &&
            <Paper {...getMenuProps()} className={classes.paper}>
              <List className={classes.root} dense>
                {items.map((item, index) => {
                  const { key, ...rest } = getItemProps({
                    key: item.accessid,
                    index,
                    item,
                    className: clsx({
                      [classes.highlight]: highlightedIndex === index,
                      [classes.selected]: selectedItem && selectedItem.accessid === item.accessid
                    })
                  })
                  return <ListItem
                    dense
                    key={key}
                    {...rest}
                  >
                    <ListItemText
                      primary={item.cpfcnpj}
                      secondary={`${item.accessid} - ${item.name}`}
                      secondaryTypographyProps={{
                        noWrap: true
                      }} />
                  </ListItem>
                })}
              </List>
            </Paper>}
        </span>
      )}
    </Downshift>)
}