import fetch from "../utils/fetch";
import env from "../env";

export function GetbyId(id, tipo) {
    return fetch(env.apiUrl+"api/metodopagamento/get/" + id + "/" + tipo, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            console.log(error.message);
            return false;
        });
}


export function GetDadosMeiosPagamentos(idUnidade, idMatricula) {
    return fetch(env.apiUrl+"api/MetodosDePagamento/Get/"+ idUnidade + "/"+ idMatricula, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error: "Ocorreu um erro contate o administrador.",
                    isLoadingPagamento: false
                });
                console.log(error);
            }
        )
        .catch(error => {
            console.log(error.message);
        });
}

export function GetByUnidade(query) {
    return fetch(env.apiUrl + "api/MetodosDePagamento/GetByUnidade", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
        body: JSON.stringify(query)
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
            }
        ).catch (error => {
            return error;
        });
}
