import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'formik'
import CustomRadio from 'components/CustomRadio'
import { PAYMENT_TYPE } from 'modules/Negotiation/Finance/utils'
import { injectIntl } from 'react-intl'

export default injectIntl(({ intl: { messages } , type, handleChange, classes }) => {
  return (
		<>
			<Grid item xs={6} className={classes.billetFormType} >
			  <Field
			    name={PAYMENT_TYPE.CASH_PAYMENT}
			    variant='outlined'
			    checked={type === PAYMENT_TYPE.CASH_PAYMENT}
			    color='primary'
			    label={messages['finance-component-payment-type-installment-unique']}
			    id='ckc-mdl-bil-cash'
			    value={PAYMENT_TYPE.CASH_PAYMENT}
			    onChange={handleChange}
			    component={CustomRadio}
			  />
			</Grid>
			<Grid item xs={6} className={classes.billetFormType} >
			  <Field
			    name={PAYMENT_TYPE.CASH_INSTALLMENT}
			    variant='outlined'
			    checked={type === PAYMENT_TYPE.CASH_INSTALLMENT}
			    id='ckc-mdl-bil-cash-inst'
			    value={PAYMENT_TYPE.CASH_INSTALLMENT}
			    color='primary'
			    label={messages['finance-component-payment-type-installment']}
			    onChange={handleChange}
			    component={CustomRadio}
			  />
			</Grid>
		</>
  )
})
