import React from 'react';
import "./styles.css";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

function PageLoading(
  {
    children,
    ...rest
  }
) {
  return (
    <div
      className="page-loading-animation-container"
      {...rest}
    >
      <div className="logo">
        <img
          src={sessionStorage.getItem("@SMO20/logoSistema")}
        />
      </div>
      <LoadingAnimation
        size={100}
      />
      {children}
    </div>
  );
}

export default PageLoading;
