import React from 'react'
import { groupBy, keys } from 'lodash'
import Grid from '@material-ui/core/Grid'
import { Typography, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { appIdSelector, accessIdSelector, isTourEnabledSelector } from 'modules/Login/selectors/user'
import { FormattedMessage } from 'react-intl'
import { registrationSelector } from 'modules/Home/selectors/registrations'
import { fetchRegistration } from 'modules/Home/services/registration'
import CardRegistration from '../CardRegistration'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  studantLoading: {
    margin: '0 auto',
    display: 'block'
  },
}))

export default () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const appid = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const isTourOpen = useSelector(isTourEnabledSelector)
  React.useEffect(() => {
    if (isTourOpen && data) {
      return
    }
    dispatch(fetchRegistration(appid, accessId))
    // eslint-disable-next-line
  }, [dispatch, appid, accessId, isTourOpen])

  const { data, isLoading } = useSelector(registrationSelector)

  if (isLoading) {
    return <CircularProgress className={classes.studantLoading} />
  }

  if (!data || data.length === 0) {
    return <Typography gutterBottom
      component='p' color={'textPrimary'}>
      <FormattedMessage id='students-register' />
    </Typography>
  }

  const grouped = groupBy(data, (st) => st.studentid)
  const list = keys(grouped)
  return (
    <Grid data-tut={list.length > 1 && 'tur-home-students'} container spacing={2}>
      {keys(grouped).map((id, i) => {
        
        return (
          <Grid data-tut={list.length === 1 && 'tur-home-students'} item xs={12} sm={6} md={6} lg={4} key={i}>
            <CardRegistration grouped={grouped[id]} showMore />
          </Grid>
        )
      })}
    </Grid>)
}