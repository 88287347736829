import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IconButton } from '@material-ui/core'
import { hide } from 'layouts/actions'
import { requestTryAgain } from '../actions'
import { logoutAD } from 'utils/adalConfig'
import { adminFlagSelector } from 'modules/Login/selectors/user'


export const SnackLogin = ({ labelButton }) => {

  const dispatch = useDispatch()
  const isAdmin = useSelector(adminFlagSelector)

  const onClick = () => {
    if (isAdmin) {
      logoutAD()
    }
    else {
      dispatch(requestTryAgain())
      dispatch(hide())
    }
  }

  return ([
    <Button key='tryAgain' color='secondary' size='small' onClick={onClick}>
      {labelButton}
    </Button>,
    <IconButton
      key='close'
      aria-label='close'
      color='inherit'
      onClick={() => dispatch(hide())}
    >
    </IconButton>,
  ])
}

