import React from 'react'
import { currency } from 'utils/utils'
import { Grid, Typography, Paper, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: '#FAFAFA'
  },
  detailBold: {
    fontWeight: 500
  }
}))

export default ({ item }) => {
  const classes = useStyles()
  const data = !item.billetDemonstrativeGroupDetails || item.billetDemonstrativeGroupDetails.itens.length === 0 ? [] : item.billetDemonstrativeGroupDetails.itens

  return (
    <Paper className={classes.root}>
      <Grid container justify='center' alignItems='center' direction='row' spacing={1}>
        {data.length === 0 ?
          <Typography component='h4' variant='body1' color='textSecondary' >
            Nenhum detalhe encontrado
          </Typography> :
          <>
            <RowDetail
              variant='body1'
              color='primary'
              classes={classes.detailBold}
              firstValue='Descrição'
              secondValue='Valor'
            />
            <Grid item xs={10} md={8}>
              <Divider />
            </Grid>
            {data.map((detail, index) => (
              <RowDetail
                key={index}
                variant='body2'
                color='initial'
                firstValue={detail.descricao}
                secondValue={currency(detail.valor)}
              />
            ))}
            <Grid item xs={10} md={8}>
              <Divider />
            </Grid>
            <RowDetail
              variant='body2'
              color='initial'
              firstValue='Total'
              classes={classes.detailBold}
              secondValue={currency(data.reduce((prev, curr) => prev + Number(curr.valor), 0))}
            />
          </>
        }
      </Grid>
    </Paper>
  )
}

const RowDetail = ({ classes, variant, color, firstValue, secondValue }) => {
  return (
    <>
      <Grid item xs={5}>
        <Typography component='h4' variant={variant} color={color} className={classes}>
          {firstValue}
        </Typography>
      </Grid>
      <Grid item xs={4} md={3}>
        <Typography component='h5' variant={variant} color={color} className={classes}>
          {secondValue}
        </Typography>
      </Grid>
    </>
  )
}