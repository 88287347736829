import { apiRequestActionType, apiSuccessActionType, apiErrorActionType } from 'utils/API/action-types'

export const MESSAGE = 'MESSAGE'
export const REQUEST_MESSAGE = apiRequestActionType(MESSAGE)
export const SUCCESS_MESSAGE = apiSuccessActionType(MESSAGE)
export const ERROR_MESSAGE = apiErrorActionType(MESSAGE)
export const DELETE_MESSAGE = 'DELETE_MESSAGE'

export const EDITED_MESSAGE = 'EDITED_MESSAGE'
export const SUCCESS_EDITED_MESSAGE = apiSuccessActionType(EDITED_MESSAGE) 

export const SEND_MESSAGE = 'SEND_MESSAGES'
export const REQUEST_SEND_MESSAGE = apiRequestActionType(SEND_MESSAGE)
export const SUCCESS_SEND_MESSAGE = apiSuccessActionType(SEND_MESSAGE)
export const ERRO_SEND_MESSAGE = apiErrorActionType(SEND_MESSAGE)