function siteColors() {
    const element = document.querySelector('body');

    switch (process.env['REACT_APP_ENV'] ?? "PRODUÇÃO") {
        case 'LOCALHOST':
            element.style.setProperty('--primary-color', '#00d427');
            element.style.setProperty('--primary-color-drop', '#00bb22');
            element.style.setProperty('--primary-color-onBack', '#00ee2c');
            element.style.setProperty('--primary-color-disable', '#00a11e');
            element.style.setProperty('--primary-color-background', '#008819');
            element.style.setProperty('--primary-color-selecionado', '#08ff35');
            break;
        case 'DESENVOLVIMENTO':
            element.style.setProperty('--primary-color', '#fcba03');
            element.style.setProperty('--primary-color-drop', '#fcc11c');
            element.style.setProperty('--primary-color-onBack', '#e3a703');
            element.style.setProperty('--primary-color-disable', '#ca9502');
            element.style.setProperty('--primary-color-background', '#b08202');
            element.style.setProperty('--primary-color-selecionado', '#fdc835');
            break;
        case 'HOMOLOGAÇÃO':
            element.style.setProperty('--primary-color', '#8b0000');
            element.style.setProperty('--primary-color-drop', 'rgba(149,4,4,0.97)');
            element.style.setProperty('--primary-color-onBack', '#8b0000');
            element.style.setProperty('--primary-color-disable', '#830101');
            element.style.setProperty('--primary-color-background', '#770101');
            element.style.setProperty('--primary-color-selecionado', '#8b0000');
            break;
        default:
            console.info('PRODUÇÃO')
    }
}

export default siteColors;
