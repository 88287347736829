import { AuthenticationContext } from 'react-adal'

export const adalConfig = {
  tenant: 'be041442-f3b6-4be1-8509-eca9c817528f',
  clientId: 'c2fb5368-c33d-43b9-85b3-5fab4025c6fb',
  endpoints: {
    api: 'be041442-f3b6-4be1-8509-eca9c817528f',
    graphApi: 'https://graph.microsoft.com'
  },
  cacheLocation: 'localStorage'
}

export const authContext = new AuthenticationContext(adalConfig)

export const logoutAD = () => {
  localStorage.removeItem('persist:persist')
  authContext.clearCache()
  authContext.logOut()
}