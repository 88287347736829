import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: "98%",
  },
  buttonClose: {
    position: "absolute",
    right: 0,
  },
  content: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    boxShadow: "none",
    display: "inline",
  },
  body: {
    marginTop: 25,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 'bold'
  }
}));
