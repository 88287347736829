import { API_ANALYTICS } from '../constants'

export const apiAnalytics = (
  entity,
  info,
  value
) => dispatch => {
  dispatch({
    type: API_ANALYTICS,
    payload: {
      entity,
      analytics: {
        info,
        value
      },
    },
  })
}

export const apiAnalyticsValue = (
  entity,
  value
) => dispatch => {
  dispatch({
    type: API_ANALYTICS,
    payload: {
      entity,
      analytics: {
        value
      },
    },
  })
}

export const apiAnalyticsInfo = (
  entity,
  info,
) => dispatch => {
  dispatch({
    type: API_ANALYTICS,
    payload: {
      entity,
      analytics: {
        info
      }
    },
  })
}