import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  footer: {
    width: '100%',
    height: 55,
    position: 'fixed',
    bottom: 0,
    left: 0,
  }
}))

export default () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer} />
  )
}
