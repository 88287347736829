import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './modules/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {RecoilRoot} from "recoil";
import { datadogRum } from '@datadog/browser-rum';
import packageJson from '../package.json';
import configurePaymentBrands from './utils/configurePaymentBrands'
import 'utils/polyfills'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import PageLoading from "./components/PageLoading/PageLoading";
import RecoilNexus from "recoil-nexus";

const rootElement = document.getElementById('root');

if (process.env.REACT_APP_ENABLE_DD === "ENABLE_DD") {
  datadogRum.init({
    clientToken: 'puba3a2fb64983ef30260a03fea8e234325',
    applicationId: '01adcfe5-1695-4fce-a0df-e29a2132dab7',
    site: 'datadoghq.com',
    service: 'app-smo-front-prod',
    env: 'prod',
    version: packageJson.version || undefined,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: ["*"]
  })
  datadogRum.startSessionReplayRecording()
}

ReactDOM.render(
    <ErrorBoundary>
      <Suspense fallback={<PageLoading/>}>
        <RecoilRoot>
          <RecoilNexus />
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </RecoilRoot>
      </Suspense>
    </ErrorBoundary>
    , rootElement
);

configurePaymentBrands();

serviceWorkerRegistration.unregister();
