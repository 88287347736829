import React from 'react'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { fieldToTextField } from 'formik-material-ui'
import DateFnsUtils from '@date-io/date-fns'
import ptBRLocale from 'date-fns/locale/pt-BR'

export default (props) => {
  const { field, form } = props
  const fieldProps = fieldToTextField(props)
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
      <KeyboardDatePicker okLabel='Ok' cancelLabel='Cancelar'
        InputAdornmentProps={{ id: field.name + '-button' }}
        {...fieldProps}
        value={fieldProps.value === '' ? null : fieldProps.value}
        onChange={(date, value) => {
          form.setFieldTouched(field.name, true, false)
          form.setFieldValue(field.name, date || '', true)
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
