import React, {Fragment} from 'react'
import Registrations from './components/Registrations'
import {makeStyles} from '@material-ui/core/styles'
import Pay from '../Finance/Pay'
import BannerNotificationContainer from './components/BannerNotificationContainer'

const useStyle = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(3)
  }
}))

const Home = ({ history }) => {
  const classes = useStyle()
  // const unidade = useRecoilValue(unidadeGetResponseState);

  // if (unidade && unidade.id === "c31ffd9a-1d4f-ed11-bba3-000d3a88f03a") {
  //   return (
  //     <Alert severity="warning">
  //       Portal financeiro bloqueado temporariamente. Por favor, tente novamente mais tarde.
  //     </Alert>
  //   )
  // }

  return (
    <Fragment>
      <div>
        <BannerNotificationContainer />
      </div>
      <div className={classes.margin}>
        <Pay history={history} />
      </div>
      <div className={classes.margin}>
        <Registrations />
      </div>
    </Fragment>
  )
}


export default Home
