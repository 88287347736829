import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { currency } from 'utils/utils'
import { Typography, Divider } from '@material-ui/core'
import useStyles from './style'
import { FormattedMessage } from 'react-intl'

export default ({ listInstallment }) => {
  const classes = useStyles()

  return listInstallment.map(({ dueDate, lineNum, amountCur }) => {
    const isInitial = lineNum === 1

    const label = isInitial ? 
      <FormattedMessage id='finance-negotiation-comp-installment-label-initial' /> : 
      <FormattedMessage id='finance-negotiation-comp-installment-label' values={{ installment: lineNum - 1 }} />

    const isPair = (lineNum % 2) === 0
    return (
      <>
        <Grid item xs={6} className={classNames(classes.installmentContainer,  isPair && classes.installmentContainerPair)} >
          <Typography align='center' className={classes.installment} >
            {label}
          </Typography>
          <Typography align='center' className={classes.installmentValue} >
            {currency(amountCur)}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classNames(classes.installmentContainer,  isPair && classes.installmentContainerPair)} >
          <Typography align='center' className={classes.installment}>
            <FormattedMessage id='finance-negotiation-comp-installment-date' />
          </Typography>
          <Typography align='center' className={classes.installmentValue}>
            {moment(new Date(dueDate)).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid xs='12' >
          <Divider />
        </Grid>
        </>
    )
  })
}
