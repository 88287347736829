import {getRecoil} from "recoil-nexus";
import {accessTokenState} from "../recoil/atoms/accessTokenState";
import {expiresInState} from "../recoil/atoms/expiresInState";

const fetch = (url, config) => {
  const {
    headers = []
  } = config || {}
  const token = getRecoil(accessTokenState);
  const expiresIn = getRecoil(expiresInState);

  if (new Date().valueOf() > new Date(expiresIn).valueOf()) {
    window.location.href = "/";
  }

  return window.fetch(url, {
    ...config,
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  })
}

export default fetch;
