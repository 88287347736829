import { LOGIN, LOGINV1 } from 'modules/Login/constants'

export const VERSIONS = {
  V1: 'v1',
  V2: 'v2'
}

const AUTHENTICATION = 'AUTHENTICATION'

export const ENTITY_VERSION = {
  [LOGIN]: VERSIONS.V2,
  [LOGINV1]: VERSIONS.V1,
  [AUTHENTICATION]: VERSIONS.V2
}
