import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseHomeSelector = prop('layout')

export const tourSelector = pipe(baseHomeSelector, prop('tour'))

export const tourDataSelector = getAPIDataSelector(tourSelector)

export const hasLoadedTourSelector = hasLoadedAPISelector(tourSelector)

export const hasErrorTourSelector = hasErrorAPISelector(tourSelector)

export const isLoadingTourSelector = isLoadingAPISelector(tourSelector)

export const tourActiveSelector = pipe(tourSelector, prop('active'))
