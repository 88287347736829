import React from 'react'
import classNames from 'classnames'
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { dashboardDataSelector } from '../selectors/dashboard'
import hexToRgba from 'hex-to-rgba'
import { currency } from 'utils/utils'

const useStyles = makeStyles(theme => ({
  gridItem: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 20px 0'
    }
  },
  gridItemRight: {
    padding: '0 0 0 10px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 20px 10px',
      '&:nth-child(3)': {
        padding: '0 0 20px 0',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 20px 0',
      }
    },
  },
  paper: {
    padding: 30,
    height: '100%'
  },
  gridContainer: {
    paddingBottom: 20
  },
  title: {
    backgroundColor: hexToRgba(theme.palette.primary.main, 0.5),
    color: 'white',
    borderRadius: 12,
    marginBottom: 15
  }
}))
export default () => {
  const classes = useStyles()
  const data = useSelector(dashboardDataSelector)

  return (
    <Grid container direction='row' className={classes.gridContainer}>
      <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
        <Paper className={classes.paper}>
          <Typography variant='subtitle1' className={classes.title}>
            <FormattedMessage id='dashboard-dialog-billet-title' />
          </Typography>
          <Typography variant='h4'>
            {Number(data.countBilletEmitted).toLocaleString()}
          </Typography>
          <Typography color='textSecondary' variant='body2'>
            <FormattedMessage id='dashboard-dialog-info-billet' />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3} className={classNames(classes.gridItem, classes.gridItemRight)}>
        <Paper className={classes.paper}>
          <Typography variant='subtitle1' className={classes.title}>
            <FormattedMessage id='dashboard-dialog-access-title' />
          </Typography>
          <Typography variant='h4'>
            {data.hoursPeakAccess && Number(data.hoursPeakAccess.count).toLocaleString()}
          </Typography>
          <Typography color='textSecondary' variant='body2'>
            <FormattedMessage id='dashboard-dialog-info-hour-peak' values={{ ...data.hoursPeakAccess }} />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3} className={classNames(classes.gridItem, classes.gridItemRight)}>
        <Paper className={classes.paper}>
          <Typography variant='subtitle1' className={classes.title}>
            <FormattedMessage id='dashboard-dialog-credit-title' />
          </Typography>
          <Typography variant='h4'>
            {currency(data.totalPaiedWithCreditCard)}
          </Typography>
          <Typography color='textSecondary' variant='body2'>
            <FormattedMessage id='dashboard-dialog-info-card-credit-amount' />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3} className={classNames(classes.gridItem, classes.gridItemRight)}>
        <Paper className={classes.paper}>
          <Typography variant='subtitle1' className={classes.title}>
            <FormattedMessage id='dashboard-dialog-credit-title' />
          </Typography>
          <Typography variant='h4'>
            {Number(data.countInstallmentPaiedWithCreditCard).toLocaleString()}
          </Typography>
          <Typography color='textSecondary' variant='body2'>
            <FormattedMessage id='dashboard-dialog-info-card-credit-total' />
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}