import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseFinanceSelector = prop('finance')

export const closedSelector = pipe(baseFinanceSelector, prop('closed'))

export const closedDataSelector = getAPIDataSelector(closedSelector)

export const hasLoadedClosedSelector = hasLoadedAPISelector(closedSelector)

export const hasErrorClosedSelector = hasErrorAPISelector(closedSelector)

export const isLoadingClosedSelector = isLoadingAPISelector(closedSelector)
