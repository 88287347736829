import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { values } from 'lodash'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Grid, IconButton, SvgIcon, CircularProgress, ListItemIcon, ListItem, ListItemText } from '@material-ui/core'
import { appIdSelector, isTourEnabledSelector } from 'modules/Login/selectors/user'
import Phone from '@material-ui/icons/Phone'
import Email from '@material-ui/icons/Email'
import { activeTour } from '../actions'
import { contactSelector } from '../selectors/contact'
import { fetchContact } from '../services/contactInfo'
import StringMask from 'string-mask'
import useStyles from './style'
import { showContacts } from 'utils/domains/routerSSO'
import { PATH_ROUTE } from '../../routes'
import { withRouter } from 'react-router-dom'
import HelpIcon from '@material-ui/icons/Help'

const WhatsIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z' />
  </SvgIcon>
)

const infoData = {
  email: {
    id: 'btn-menu-email-foot',
    icon: Email,
    name: 'Email',
  },
  telefone: {
    id: 'btn-menu-pho-foot',
    icon: Phone,
    name: 'Telefone',
    format: value => StringMask.apply(value, '(00) 00009-0000')
  },
  whatsapp: {
    id: 'btn-menu-whats-foot',
    icon: WhatsIcon,
    name: 'WhatsApp',
    format: value => StringMask.apply(value, '(00) 00009-0000')
  }
}

export default withRouter(({ history }) => {
  const classes = useStyles()
  const { loading, data, loaded } = useSelector(contactSelector)
  const [contactActive, setContactActive] = React.useState(null)
  const isTourOpen = useSelector(isTourEnabledSelector)

  const appid = useSelector(appIdSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchContact(appid))
  }, [dispatch, appid, isTourOpen])

  const onClick = (contact, info) => {
    if (contact.key === 'whatsapp') {
      window.open(`https://api.whatsapp.com/send?phone=55${contact.value}`)
    }

    setContactActive({
      ...contact,
      formatted: info.format
        ? info.format(contact.value)
        : contact.value
    })
  }

  const goToTour = () => {
    dispatch(activeTour())
    history.push(PATH_ROUTE.HOME)
  }

  return (
    <footer className={classes.footer}>
      <div>
        <ListItem button onClick={goToTour} className={classes.footerTour} id={'main-menu-'}>
          <ListItemIcon >
            <HelpIcon className={classes.footerIcon} />
          </ListItemIcon>
          <ListItemText className={classes.footerTextTour} primary='Ver tutorial' />
        </ListItem>
      </div>
      {(() => {
        if (loading) {
          return <Grid container justify='center' >
            <CircularProgress size={24} />
          </Grid>
        }

        if (!loaded || !showContacts()) {
          return null
        }

        return (
          <Container maxWidth='lg' data-tut='tur-menu-footer'>
            <Grid container justify='space-between' >
              <Grid item xs={12} className={classes.footerCallCenter} >
                <Typography variant='caption' color='secondary' >
                  Horário de Atendimento
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.footerCallCenter} >
                <Typography variant='caption' color='secondary' >
                  Canais Digitais
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.footerCallCenter} >
                <Typography variant='caption' color='secondary' >
                  Chat / Whatsapp / E-mail
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.footerCallCenter} >
                <Typography variant='caption' color='secondary' >
                  Segunda a Sexta das
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.footerCallCenter} >
                <Typography variant='caption' color='secondary' >
                  08hrs às 18hrs
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.footerIconsButton} >
                {values(data).sort().reverse().map(contact => {
                  const info = infoData[contact.key]

                  if (!info) {
                    return null
                  }
                  return (
                    <IconButton
                      id={info.id}
                      key={info.name}
                      size={'small'}
                      aria-label='action'
                      onClick={() => onClick(contact, info)}
                    >
                      <info.icon
                        color='primary'
                        fontSize={contactActive && contactActive.key === contact.key ? 'large' : 'small'} />
                    </IconButton>)
                }
                )}
              </Grid>
              {contactActive && <Grid item xs={12} className={classes.footerIconsButton} >
                <Typography className={classes.footerInfoText} variant='caption' color='secondary' >
                  {contactActive.formatted}
                </Typography>
              </Grid>}
            </Grid>
          </Container>)
      })()}
    </footer>
  )
})
