export const KEY_ENV_DOMAIN = {
  LOGIN: "urlLogin",
  LOGOUT: "urlLogout",
  CHAT: "urlChat",
  ZENDESK: "urlZendesk",
  ZENDESK_KEY: "keyZendesk",
};

export const ENTITY_NAME = {
  HOMOLOG: "homolog",
  SEBSA: "sebsa",
  EINSTEIN: "einstein",
  CONCEPT: "concept",
  LUMINOVA: "luminova",
  LOCALHOST: "localhost",
};

export const ENV_URL_DOMAIN = {
  [ENTITY_NAME.LOCALHOST]: {
    [KEY_ENV_DOMAIN.LOGIN]: "REACT_APP_SSO_URL_LOCALHOST",
    [KEY_ENV_DOMAIN.LOGOUT]: "REACT_APP_SSO_URL_LOGOUT_LOCALHOST",
    [KEY_ENV_DOMAIN.CHAT]: "REACT_APP_DEFAULT",
    [KEY_ENV_DOMAIN.ZENDESK]: "REACT_APP_ZENDESK_URL_BASE",
    [KEY_ENV_DOMAIN.ZENDESK_KEY]: "REACT_APP_ZENDESK_KEY",
  },
  [ENTITY_NAME.HOMOLOG]: {
    [KEY_ENV_DOMAIN.LOGIN]: "REACT_APP_SSO_URL_HOMOLOG",
    [KEY_ENV_DOMAIN.LOGOUT]: "REACT_APP_SSO_URL_LOGOUT_HOMOLOG",
    [KEY_ENV_DOMAIN.CHAT]: "REACT_APP_DEFAULT",
    [KEY_ENV_DOMAIN.ZENDESK]: "REACT_APP_ZENDESK_URL_BASE",
    [KEY_ENV_DOMAIN.ZENDESK_KEY]: "REACT_APP_ZENDESK_KEY",
  },
  [ENTITY_NAME.SEBSA]: {
    [KEY_ENV_DOMAIN.LOGIN]: "REACT_APP_SSO_URL_SEBSA",
    [KEY_ENV_DOMAIN.LOGOUT]: "REACT_APP_SSO_URL_LOGOUT_SEBSA",
    [KEY_ENV_DOMAIN.CHAT]: "REACT_APP_DEFAULT",
    [KEY_ENV_DOMAIN.ZENDESK]: "REACT_APP_ZENDESK_URL_BASE",
    [KEY_ENV_DOMAIN.ZENDESK_KEY]: "REACT_APP_ZENDESK_KEY",
  },
  [ENTITY_NAME.EINSTEIN]: {
    [KEY_ENV_DOMAIN.LOGIN]: "REACT_APP_SSO_URL_EINSTEIN",
    [KEY_ENV_DOMAIN.LOGOUT]: "REACT_APP_SSO_URL_LOGOUT_EINSTEIN",
    [KEY_ENV_DOMAIN.CHAT]: "",
    [KEY_ENV_DOMAIN.ZENDESK]: "REACT_APP_ZENDESK_URL_BASE",
    [KEY_ENV_DOMAIN.ZENDESK_KEY]: "REACT_APP_ZENDESK_KEY",
  },
  [ENTITY_NAME.CONCEPT]: {
    [KEY_ENV_DOMAIN.LOGIN]: "REACT_APP_SSO_URL_CONCEPT",
    [KEY_ENV_DOMAIN.LOGOUT]: "REACT_APP_SSO_URL_LOGOUT_CONCEPT",
    [KEY_ENV_DOMAIN.CHAT]: "REACT_APP_CONCEPT",
    [KEY_ENV_DOMAIN.ZENDESK]: "REACT_APP_ZENDESK_URL_BASE",
    [KEY_ENV_DOMAIN.ZENDESK_KEY]: "REACT_APP_ZENDESK_KEY",
  },
  [ENTITY_NAME.LUMINOVA]: {
    [KEY_ENV_DOMAIN.LOGIN]: "REACT_APP_SSO_URL_LUMINOVA",
    [KEY_ENV_DOMAIN.LOGOUT]: "REACT_APP_SSO_URL_LOGOUT_LUMINOVA",
    [KEY_ENV_DOMAIN.CHAT]: "REACT_APP_DEFAULT",
    [KEY_ENV_DOMAIN.ZENDESK]: "REACT_APP_ZENDESK_URL_BASE",
    [KEY_ENV_DOMAIN.ZENDESK_KEY]: "REACT_APP_ZENDESK_KEY",
  },
};
