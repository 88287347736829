
export const addGlobalBot = (name, email, cpf, dataAreaId, nomeEscola) => {
  if (!document.getElementById('globalbot-script')) {
    //Informações para realizar login no chat
    localStorage.setItem('name', name)
    localStorage.setItem('e-mail', email)
    localStorage.setItem('user_cpf', cpf)
    localStorage.setItem('data_area_id', dataAreaId)
    localStorage.setItem('nome_escola', nomeEscola)

    const token = process.env.REACT_APP_GLOBAL_BOT_TOKEN
    const encName = encodeURIComponent(name)
    const encUnidade = encodeURIComponent(nomeEscola)
    const encCPF = encodeURIComponent((cpf || '').replace(/\D/g, ''))

    const  script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'globalbot-script'
    script.src = `https://globalbot.ai/init/1/${token}?identifiers=name-${encName}|unidade-${encUnidade}|cpf-${encCPF}`
    document.body.appendChild(script)
  }
}
