import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseAdminSelector = prop('admin')

export const dashboardSelector = pipe(baseAdminSelector, prop('dashboard'))

export const dashboardDataSelector = getAPIDataSelector(dashboardSelector)

export const hasLoadedDashboardSelector = hasLoadedAPISelector(dashboardSelector)

export const hasErrorDashboardSelector = hasErrorAPISelector(dashboardSelector)

export const isLoadingDashboardSelector = isLoadingAPISelector(dashboardSelector)
