import { getIncomeTax, getIncomeTaxTotal } from '../services/incometax'
import { apenFileNewWindow } from 'utils/openFile'
import { setMessageSnack } from 'layouts/actions'

export const generateIncomeTax = (appId, isTotal, data, id) => (dispatch, getState) => {
  const api = dispatch(isTotal ? getIncomeTaxTotal(appId, data) : getIncomeTax(appId, data))
  api.then((result) => {
    apenFileNewWindow(result, id)
  }).catch(error => {
    if (error && error.response && error.response.data && error.response.data.message) {
      dispatch(setMessageSnack(error.response.data.message));
    }
  })
}