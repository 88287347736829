import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, map } from 'lodash'
import Table from 'components/Table'
import { appIdSelector } from 'modules/Login/selectors/user'
import CustomCellHighlight from 'components/CustomCellHighlight'
import { injectIntl, FormattedMessage } from 'react-intl'
import { hasErrorPaymentsSelector, isLoadingPaymentsSelector } from './selectors/negotiation'
import { getNegotiationPayments } from './service/negotiation'
import moment from 'moment'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({
  tableContainer: {
    marginBottom: 25
  }
}))
export const STYLE_CELLS = {
  paddingRight: 20,
  paddingLeft: 0
}

const columns = messages => [
  { title: messages['payment-negotiation-column-date'], field: 'creationDateFormatted', cellStyle: STYLE_CELLS, customSort: (param1, param2) => Date.parse(param1.creationDate) - Date.parse(param2.creationDate) },                        
  { title: messages['payment-negotiation-column-id'], field: 'agreementId', cellStyle: STYLE_CELLS },
  { title: messages['payment-negotiation-column-receipt'], field: 'receiptNumber', cellStyle: STYLE_CELLS },
  { title: messages['payment-negotiation-column-order-number'], field: 'orderNumber', cellStyle: STYLE_CELLS },
  { title: messages['payment-negotiation-column-installments'], field: 'installments', cellStyle: STYLE_CELLS },
  { title: messages['payment-negotiation-column-status'], field: 'paymentavailabilityFormatted', cellStyle: STYLE_CELLS },
]

export default injectIntl(({ intl: { messages } }) => {
  const classes = useStyles()

  const tableRef = useRef()
  const dispatch = useDispatch()
  const error = useSelector(hasErrorPaymentsSelector)
  const loading = useSelector(isLoadingPaymentsSelector)
  const appId = useSelector(appIdSelector)

  const [ pageSize, setPageSize ] = useState(10)

  if (!isEmpty(error)) {
    return (
      <div style={{ textAlign: 'center' }}>
        <FormattedMessage id='payment-negotiation-not-found' />
      </div>
    )
  }

  return (
    <div className={classes.tableContainer}>
      <Table
        isLoading={loading}
        tableRef={tableRef}
        components={{
          Cell: (props) => <CustomCellHighlight {...props} tableref={tableRef} />
        }}
        options={{
          showTextRowsSelected: false,
          search: false,
          sorting: true,
          pageSize: pageSize,
          paging: true,
          showFirstLastPageButtons: true,
          headerStyle: {
            fontSize: 15,
            paddingRight: 1
          }
        }}
        onChangeRowsPerPage={(size) => setPageSize(size)}
        columns={columns(messages)}
        data={ query => {
          const { page, pageSize } = query
          
          return dispatch(getNegotiationPayments(appId, page + 1, pageSize)).then(result => ({
            page: result.pagination ? result.pagination.page - 1 : 0,
            totalCount: result.pagination ? result.pagination.totalItems : 0,
            data: map(result.elements, el => ({
              ...el,
              paymentavailabilityFormatted: el.isliquidate ?  'Liquidado' : 'Não liquidado',
              creationDateFormatted: moment(el.creationDate).format('DD/MM/YYYY')
            }))
          }))
        }}
        title={messages['payment-negotiation-title']}
      />
    </div>)
})
