import React, { Fragment } from 'react'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Route } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import { Admin } from 'components/PermissionsFilter'
import useStyles from './style'
import MainMenu from '../Menu'
import ProfileDialog from '../ProfileDialog'
import SearchCPF from '../SearchCPF'
import { useRoutesByUserPermissions } from 'utils/hooks'
import FooterMenu from '../FooterMenu'


const BreadCrumb = () => {
  const routes = useRoutesByUserPermissions()

  return routes
    .filter(item => item.breadcrumb)
    .map(({ id, exact, path, breadcrumb }) =>
      <Route
        key={id}
        exact={exact}
        component={() => <span>{breadcrumb}</span>}
        path={path} />, item => item.breadcrumb)
}

export default () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const classes = useStyles()

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  return <Fragment>
    <AppBar
      position='fixed'
      className={clsx(isSmall ? classes.appbar : classes.appbarShift)}
    >
      <Toolbar>
        {isSmall && <IconButton
          edge='start'
          onClick={handleDrawerOpen}
          color='inherit'
          aria-label='menu'>
          <MenuIcon data-tut='tur-icon-menu' />
        </IconButton>}
        <Typography variant='h6' className={classes.title} noWrap>
          <BreadCrumb />
        </Typography>
        <Admin>
          <Hidden only='xs'>
            <SearchCPF />
          </Hidden>
        </Admin>
        <ProfileDialog />
      </Toolbar>
    </AppBar>
    <Drawer
      onClose={handleDrawerClose}
      className={classes.drawer}
      variant={isSmall ? 'temporary' : 'persistent'}
      anchor='left'
      open={open || !isSmall}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true
      }}
    >
      <div className={classes.drawerHeader}>
        <img className={classes.logo} src={theme.user.image} alt='logo' />
      </div>
      <Divider />
      <MainMenu setOpen={setOpen} />
      <FooterMenu />
    </Drawer>
  </Fragment>
}