import React, { useEffect, useRef } from "react";
import { omit, isNil, isEmpty } from "lodash";
import {
  appIdSelector,
  accessIdSelectedSelector,
  isTourEnabledSelector,
} from "modules/Login/selectors/user";
import DescriptionIcon from "@material-ui/icons/Description";
import { makeStyles, IconButton, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { generateIncomeTax } from "./actions";
import { isLoadingDeclarationSelector } from "./selectors/declaration";
import { getStudents } from "./services/incometax";
import {
  isLoadingStudentsSelector,
  studentsDataSelector,
  hasLoadedStudentsSelector,
} from "./selectors/students";
import Table from "components/Table";
import { FormattedMessage, injectIntl } from "react-intl";

const useStyles = makeStyles(() => ({
  notFoundStudents: {
    textAlign: "center",
  },
}));

const columns = (onGenerate, messages) => [
  {
    title: messages["income-tax--table-name"],
    field: "studentName",
  },
  {
    title: messages["income-tax-table-registrer"],
    field: "studentCode",
  },
  {
    title: messages["income-tax-table-tax"],
    field: "declaration",
    render: (row) => (
      <IconButton
        id="icon-button"
        onClick={() => onGenerate(row, false, "icon-button")}
      >
        <DescriptionIcon />
      </IconButton>
    ),
  },
  {
    title: messages["income-tax-table-receipt-total"],
    field: "total declaration",
    render: (row) => (
      <IconButton
        id="icon-button-total"
        onClick={() => onGenerate(row, true, "icon-button-total")}
      >
        <DescriptionIcon />
      </IconButton>
    ),
  },
];

export default injectIntl(({ intl: { messages } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const isLoadingDeclaration = useSelector(isLoadingDeclarationSelector);
  const hasLoadedStudents = useSelector(hasLoadedStudentsSelector);
  const isLoadingStudents = useSelector(isLoadingStudentsSelector);
  const appId = useSelector(appIdSelector);
  const data = useSelector(studentsDataSelector);
  const clientCode = useSelector(accessIdSelectedSelector);
  const isTourOpen = useSelector(isTourEnabledSelector);

  const onGenerate = (item, isTotal, id) => {
    console.log(item);

    const data = {
      ...omit(item, ["studentName", "tableData"]),
      reportType: isTotal ? 1 : 0
    }

    dispatch(
      generateIncomeTax(
        appId,
        isTotal,
        data,
        id
      )
    );
  };

  useEffect(() => {
    if (isTourOpen && !isEmpty(data)) {
      return;
    }

    dispatch(getStudents(appId, isNil(clientCode) ? '' : clientCode));

  }, [dispatch, appId, isTourOpen]);

  if (isNil(data) || data.length === 0) {
    return (
      <Typography
        gutterBottom
        component="p"
        color="textPrimary"
        className={classes.notFoundStudents}
      >
        <FormattedMessage id="income-tax-students-not-found" />
      </Typography>
    );
  }

  return (
    <Table
      isLoading={isLoadingStudents || isLoadingDeclaration}
      tableRef={tableRef}
      columns={columns(onGenerate, messages)}
      options={{
        sorting: false,
        selection: false,
        padding: "dense",
        headerStyle: {
          paddingRight: 5,
          padding: "16px 0px",
        },
      }}
      data={hasLoadedStudents ? data : []}
      title="Declarações"
    />
  );
});
