import React, {Fragment, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Receipt from '@material-ui/icons/Receipt'
import DateRange from '@material-ui/icons/DateRange'
import CreditCard from '@material-ui/icons/CreditCard'
import SimplePaymentDialog from './components/SimplePaymentDialog'
import RecurrencePaymentDialog from './components/RecurrencePaymentDialog'
import AutomaticDebitDialog from './components/AutomaticDebitDialog'
import {FormattedMessage, injectIntl} from 'react-intl'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import * as _movimentacao from '../../../service/movimentacao-service'
import Swal from 'sweetalert2'
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {paymentTypeSelectedDataSelector} from './selectors/paymentTypes'
import {
  hasLoadedInformationRecurrentCardSelector,
  informationRecurrentCardDataSelector,
} from './selectors/informationRecurrentCard'
import useStyles from './style'
import LoadingModal from 'components/LoadingModal'
import ConfirmDialog from 'components/ConfirmDialog'
import {appIdSelector, isTourEnabledSelector} from 'modules/Login/selectors/user'
import {acceptTermAgreementPaymentType} from 'modules/Finance/PaymentTypes/services/acceptanceTerm'
import StudentList from './components/StudentList'
import {getPaymentTypeSelected} from './services/paymentTypes'
import Tooltip from '@material-ui/core/Tooltip'
import KnowMoreAutomaticDebitDialog from './components/KnowMoreDialogs/KnowMoreAutomaticDebitDialog'
import KnowMoreRecurrencePaymentDialog from './components/KnowMoreDialogs/KnowMoreRecurrencePaymentDialog'
import KnowMoreSimplePaymentDialog from './components/KnowMoreDialogs/KnowMoreSimplePaymentDialog'
import PaymentIcon from './components/PaymentIcons/PaymentIcon'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import {capitalizeString, getBankByCode} from 'utils/utils'
import * as _metodoPagamentoService from "../../../service/metodoPagamento-service";
import {fetchContact} from 'layouts/services/contactInfo'
import {getAll} from '../services/unity'
import {GetBySistemaId} from '../services/blackList'
import blockedResourcesSystem from '../../../utils/blockedResourcesSystem'
import {blockedResourcesEntitiesValues} from '../../../utils/blockedResourcesEntities'
import useCsat from "hooks/use-csat";
import csatList from "utils/csatList";
import Csat from "../../../components/Csat/Csat";
import {useRecoilState, useRecoilValue} from "recoil";
import {selectedMatriculaState} from "../../../recoil/atoms/selectedMatriculaState";
import {unidadeGetResponseState} from "../../../recoil/atoms/unidadeGetResponseState";
import moment from 'moment-timezone';
import {registrationSelector} from "./selectors/registrations";
import * as _matricula from "../../../service/matricula-service";
import {Skeleton} from "@material-ui/lab";
import {Redirect} from "react-router-dom";
import {PATH_ROUTE} from "../../../routes";
import Alert from "@material-ui/lab/Alert";

export default injectIntl(({intl: {messages, formatDate}}) => {
  const classes = useStyles()
  const [paymentTypes, setPaymentTypes] = useState({})
  const dispatch = useDispatch()
  const {data: registrationData} = useSelector(registrationSelector)
  const appId = useSelector(appIdSelector)
  const isTourOpen = useSelector(isTourEnabledSelector)
  const [isLoadingDiscount, setIsLoadingDiscount] = useState(true)

  useEffect(() => {
    dispatch(fetchContact(appId))
  }, [dispatch, appId, isTourOpen])

  const [openModalRecurrencePayment, setOpenModalRecurrentPayment] = useState(
    false
  )
  const [openModalAutomaticDebit, setOpenModalAutomaticDebit] = useState(false)
  const [openModalSimplePayment, setOpenModalSimplePayment] = useState(false)

  const [
    openModalKnowMoreAutomaticDebit,
    setOpenModalKnowMoreAutomaticDebit,
  ] = useState(false)
  const [
    openModalKnowMoreRecurrencePayment,
    setOpenModalKnowMoreRecurrencePayment,
  ] = useState(false)
  const [
    openModalKnowMoreSimplePayment,
    setOpenModalKnowMoreSimplePayment,
  ] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [changePaymentHasError, setChangePaymentHasError] = useState(false)
  const [changePaymentError, setChangePaymentError] = useState('')
  const [changePaymentHasSuccess, setChangePaymentHasSuccess] = useState(false)
  const [showCreditCardInfo,] = useState(false)
  const showCreditCardInfoModalButton = true
  const [recurrencyIsEditable, setRecurrencyIsEditable] = useState(true)

  const [selectedMatriculaId, setSelectedMatriculaId] = useState(0)
  const [selectedDataAreaId, setSelectedDataAreaId] = useState('')
  const [selectedResponsibleId, setSelectedResponsibleId] = useState('')
  const [selectedMatricula, setSelectedMatricula] = useRecoilState(selectedMatriculaState)
  const [unidade, setUnidade] = useRecoilState(unidadeGetResponseState)
  const [blockedResources, setBlockedResources] = useState([])
  const [boletoLock, setBoletoLock] = useState({})
  const [cartaoLock, setCartaoLock] = useState({})
  const [daLock, setDaLock] = useState({})
  const [registrationHasError, setRegistrationHasError] = useState(false)

  const [acceptanceTermObj, setAcceptanceTermObj] = useState(null)

  const data = useSelector(paymentTypeSelectedDataSelector)

  const [bank, setBank] = useState(null)

  const dataCard = useSelector(informationRecurrentCardDataSelector)

  const hasLoadedDataCard = useSelector(
    hasLoadedInformationRecurrentCardSelector
  )

  // if (unidade && unidade.id === "c31ffd9a-1d4f-ed11-bba3-000d3a88f03a") {
  //   return (
  //     <Alert severity="warning">
  //       Portal financeiro bloqueado temporariamente. Por favor, tente novamente mais tarde.
  //     </Alert>
  //   )
  // }

  const {
    addAnsweredCsat,
    isCsatVisible,
    handleShowCsat
  } = useCsat();

  useEffect(() => {
    const fetchPaymentTypes = async () => {
      try {
        setIsLoadingDiscount(true)
        const idResponsavelCookie = sessionStorage.getItem("@SMO20/idResponsavel");
        let responseResult = await _matricula.GetbyMatriculasByResponsavel(idResponsavelCookie, false);
        let payments = {};
        const deduplicated = registrationData.filter((v, i, a) => a.findIndex(t => (t.registrationid === v.registrationid)) === i)
        const promises = deduplicated.map(async student => {
          const matricula = (responseResult?.value || [])?.find(x => x?.codigoMatriculaErp === student?.registrationid)
          const request = {
            Servicos: [],
            matriculaId: matricula?.id,
            unidadeId: matricula?.unidade_Id
          }
          request.Servicos.push(matricula?.servico_Id)
          const descontoPagamento = await _metodoPagamentoService.GetByUnidade(request)
          payments[student?.registrationid] = {};
          (descontoPagamento?.value?.mensais ?? [])?.forEach( (mensal) => {
            payments[student?.registrationid][mensal.meioDePagamento] = mensal?.servicosUnidade.find(x => x.percentualDesconto > 0)?.percentualDesconto;
          })
        })
        await Promise.all(promises)
        setPaymentTypes(payments)
        setIsLoadingDiscount(false)
      } catch (error) {
        console.error(error)
      }
    }

    if (registrationData?.length) {
      fetchPaymentTypes()
    }
  }, [registrationData])

  useEffect(() => {
    setIsLoading(selectedMatricula === null)

    if (selectedMatricula) {
      setIsLoading(true)
      const {unitycode} = selectedMatricula
      getAll(unitycode).then((response) => {
        if (response && response.length) {
          const unity = response.find(r => r.codigoERP === unitycode)
          if (unity) {
            setUnidade(unity)
          }
        }
      })

      GetBySistemaId(blockedResourcesSystem.SMO).then((response) => {
        if (response && response.length) {
          setBlockedResources(response)
        }
        setIsLoading(false)
      }).catch(() => {
        setIsLoading(false)
      })
    }

  }, [selectedMatricula])

  useEffect(() => {
    if (unidade && blockedResources && blockedResources.length) {
      const {id: idUnidade} = unidade
      const locksFiltered = blockedResources.filter(r => r?.unidadeId === idUnidade && r.type === blockedResourcesEntitiesValues.Unidade)
      if (locksFiltered) {
        const verifylock = (
          {
            lockName,
            action,
            setLock,
            defaultMessage,
            selectedYear
          }
        ) => {
          const lockFound = locksFiltered.find(x => x.action === action && x.anoLetivo === selectedYear)
          if (lockFound) {
            setLock({
              [lockName]: true,
              [`${lockName}Message`]: lockFound?.mensagemBloqueio && lockFound?.mensagemBloqueio !== ''
                ? lockFound?.mensagemBloqueio
                : defaultMessage
            })
          } else {
            setLock({})
          }
        }

        verifylock({
          lockName: 'mudancaBoleto',
          setLock: setBoletoLock,
          action: 'MudancaDeMetodoDePagamento/Unidade/Boleto',
          defaultMessage: 'Bloqueado para esta unidade',
          selectedYear: selectedMatricula.schoolyear
        })

        verifylock({
          lockName: 'mudancaCartao',
          setLock: setCartaoLock,
          action: 'MudancaDeMetodoDePagamento/Unidade/CartaoDeCreditoRecorrente',
          defaultMessage: 'Bloqueado para esta unidade',
          selectedYear: selectedMatricula.schoolyear
        })

        verifylock({
          lockName: 'mudancaDa',
          setLock: setDaLock,
          action: 'MudancaDeMetodoDePagamento/Unidade/DebitoAutomaticoRecorrente',
          defaultMessage: 'Bloqueado para esta unidade',
          selectedYear: selectedMatricula.schoolyear
        })
      }
    }
  }, [unidade, blockedResources])

  const isLimitDate = async () => {
    const {diaLimiteMes} = await _movimentacao.GetTemplateMovimentacao(unidade.id, 284360005);
    const diaAtual = parseInt(moment().tz("America/Sao_Paulo").format('DD/MM/YYYY').split("/")[0]);
    const passouDiaLimite = diaAtual >= diaLimiteMes ? true : false;
    if (!passouDiaLimite)
      return true
    return await Swal.fire({
      title: 'A data limite para alteração de método de pagamento para a parcela vigente já venceu, sendo assim, não será possível alterar o método para a parcela vigente (demais serão alteradas).',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, Continuar!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        return true
      } else {
        return false
      }
    })
  }

  const openSimplePayment = async () => {
    if (await isLimitDate())
      if (!isSimplePayment())
        setOpenModalSimplePayment(true)
  }

  const openRecurrentPayment = async () => {
      if (!isRecurrentPayment() && await isLimitDate()) {
          setRecurrencyIsEditable(true)
          setOpenModalRecurrentPayment(true)
      } else {
          setRecurrencyIsEditable(true)
          setOpenModalRecurrentPayment(true)
      }
  }

  const openRecurrentPaymentViewOnly = async () => {
    setRecurrencyIsEditable(false)
    setOpenModalRecurrentPayment(true)
  }

  const openAutomaticDebit = async () => {
    if (await isLimitDate())
      setOpenModalAutomaticDebit(true)
  }

  const closeModalSimplePayment = () => {
    setOpenModalSimplePayment(false)
  }

  const closeModalRecurrentPayment = () => {
    setOpenModalRecurrentPayment(false)
  }

  const closeModalAutomaticDebit = () => {
    setOpenModalAutomaticDebit(false)
  }

  const isSimplePayment = () => {
    return data.current_payment_type === 'BoletoBancario'
  }

  const isRecurrentPayment = () => {
    return data?.current_payment_type === 'CreditoRecorrente'
  }

  const isAutomaticDebit = () => {
    return data.current_payment_type === 'DebitoAutomatico'
  }

  const onSuccessChange = () => {
    setChangePaymentHasSuccess(true)
    dispatch(
      acceptTermAgreementPaymentType(appId, JSON.stringify(acceptanceTermObj))
    )
    dispatch(
      getPaymentTypeSelected(
        appId,
        selectedMatriculaId,
        selectedDataAreaId,
        selectedResponsibleId
      )
    )
  }

  const onErrorChange = (errorMessage) => {
    setChangePaymentHasError(true)
    setChangePaymentError(errorMessage)
  }

  const onSuccessChangeSimplePayment = () => {
    closeModalSimplePayment()
    onSuccessChange()
  }

  const onErrorChangeSimplePayment = (errorMessage) => {
    closeModalSimplePayment()
    onErrorChange(errorMessage)
  }

  const onSuccessChangeAutomaticDebit = () => {
    closeModalAutomaticDebit()
    onSuccessChange()
  }

  const onErrorChangeAutomaticDebit = (errorMessage) => {
    closeModalAutomaticDebit()
    onErrorChange(errorMessage)
  }

  const onSuccessChangeRecurrencePayment = () => {
    closeModalRecurrentPayment()
    onSuccessChange()
  }

  const onErrorChangeRecurrencePayment = (errorMessage) => {
    closeModalRecurrentPayment()
    onErrorChange(errorMessage)
  }

  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  useEffect(() => {
    if (data && isAutomaticDebit() && data.debito_automatico_banco) {
      setBank(getBankByCode(data.debito_automatico_banco))
    }
  }, [dispatch, data])

  const handleCloseCsat = () => {
    const body = document.querySelector('body')
    if (body) body.classList.remove('csatBlockScroll')
    addAnsweredCsat(csatList.trocaMetodoPagamento)
  }

  return (
    <div data-tut='tur-table-payment-types'>
      <LoadingModal loading={isLoading}/>

      <ConfirmDialog
        setOpen={setChangePaymentHasError}
        open={changePaymentHasError}
        title='Erro ao alterar forma de pagamento'
        message={changePaymentError}
        onClose={() => handleShowCsat(csatList.trocaMetodoPagamento)}
      />

      <ConfirmDialog
        setOpen={setChangePaymentHasSuccess}
        open={changePaymentHasSuccess}
        title='Método de Pagamento'
        onClose={() => handleShowCsat(csatList.trocaMetodoPagamento)}
        message={'A solicitação de alteração de método de pagamento foi realizada com sucesso!'}
      />

      <Csat
        show={isCsatVisible(csatList.trocaMetodoPagamento)}
        scoreTitle="O quanto você ficou satisfeito com: Troca de método de pagamento?"
        onSubmit={handleCloseCsat}
        onClosed={handleCloseCsat}
      />

      <Typography variant='h6' gutterBottom>
        Matrículas
      </Typography>
      <Card>
        <CardContent>
          <StudentList
            setSelectedMatriculaId={setSelectedMatriculaId}
            setSelectedDataAreaId={setSelectedDataAreaId}
            setRegistrationHasError={setRegistrationHasError}
            setSelectedResponsibleId={setSelectedResponsibleId}
            setSelectedMatricula={setSelectedMatricula}
          />
        </CardContent>
      </Card>

      <KnowMoreAutomaticDebitDialog
        open={openModalKnowMoreAutomaticDebit}
        discount={paymentTypes[selectedMatricula?.registrationid] ? paymentTypes[selectedMatricula?.registrationid]["Debito_automatico_unico"] : 0}
        onClose={() => {
          setOpenModalKnowMoreAutomaticDebit(false)
        }}
        selectedUnidade={selectedMatricula != null ? selectedMatricula?.unitycode : 0}
      />

      <KnowMoreRecurrencePaymentDialog
        open={openModalKnowMoreRecurrencePayment}
        discount={paymentTypes[selectedMatricula?.registrationid] ? paymentTypes[selectedMatricula?.registrationid]["Cartao_de_credito_recorrente"] : 0}
        onClose={() => {
          setOpenModalKnowMoreRecurrencePayment(false)
        }}
        selectedUnidade={selectedMatricula != null ? selectedMatricula?.unitycode : 0}
      />

      <KnowMoreSimplePaymentDialog
        open={openModalKnowMoreSimplePayment}
        discount={paymentTypes[selectedMatricula?.registrationid] ? paymentTypes[selectedMatricula?.registrationid]["Boleto_bancario"] : 0}
        onClose={() => {
          setOpenModalKnowMoreSimplePayment(false)
        }}
      />

      <SimplePaymentDialog
        open={openModalSimplePayment}
        onClose={closeModalSimplePayment}
        setIsLoading={setIsLoading}
        onSuccess={onSuccessChangeSimplePayment}
        onError={onErrorChangeSimplePayment}
        selectedMatricula={selectedMatricula}
        setAcceptanceTermObj={setAcceptanceTermObj}
      />

      <RecurrencePaymentDialog
        open={openModalRecurrencePayment}
        onClose={closeModalRecurrentPayment}
        setIsLoading={setIsLoading}
        onSuccess={onSuccessChangeRecurrencePayment}
        onError={onErrorChangeRecurrencePayment}
        selectedMatricula={selectedMatricula}
        editable={recurrencyIsEditable}
        showInstallments={isRecurrentPayment()}
        isCardChanging={isRecurrentPayment()}
        selectedInfo={{
          selectedMatriculaId,
          selectedDataAreaId,
          selectedResponsibleId,
          unitycode: selectedMatricula?.unitycode
        }}
        setAcceptanceTermObj={setAcceptanceTermObj}
      />

      <AutomaticDebitDialog
        open={openModalAutomaticDebit}
        onClose={closeModalAutomaticDebit}
        setIsLoading={setIsLoading}
        onSuccess={onSuccessChangeAutomaticDebit}
        onError={onErrorChangeAutomaticDebit}
        selectedMatricula={selectedMatricula}
        setAcceptanceTermObj={setAcceptanceTermObj}
        data={data}
      />

      <Typography variant='h6' gutterBottom className={classes.title}>
        Formas de Pagamento disponíveis
      </Typography>
      <Typography variant='subtitle1' gutterBottom className={classes.textInfo}>
        O método de pagamento destacado em verde indica seu plano atual. Conheça
        mais sobre cada método em Conhecer mais... Em caso de dúvidas nos chame
        no chat.
      </Typography>

      {registrationHasError && (
        <Card>
          <CardContent>
            <Typography gutterBottom component='p' color={'textPrimary'}>
              <FormattedMessage id='finance-negotiation-payment-type-not-found'/>
            </Typography>
          </CardContent>
        </Card>
      )}

      {data == null && !registrationHasError && (
        <CircularProgress className={classes.loading}/>
      )}

      {data != null && (
        <List className={classes.list}>
          {(boletoLock?.mudancaBoleto && cartaoLock?.mudancaCartao && daLock?.mudancaDa) && (
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={
                  <Typography className={classes.paymentTitle}>
                    Nenhuma forma de pagamento disponível
                  </Typography>
                }
              />
            </ListItem>
          )}

          {(!boletoLock?.mudancaBoleto) && (
            <ListItem
              className={
                isSimplePayment() ? classes.listItemSuccess : classes.listItem
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <Receipt/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography className={classes.paymentTitle}>
                    Boleto Bancário
                  </Typography>
                }
                secondary={
                  <div>
                    {isLoadingDiscount && <Skeleton variant="text" width={100} />}
                    {(paymentTypes[selectedMatricula?.registrationid] && paymentTypes[selectedMatricula?.registrationid]["Boleto_bancario"] > 0) && (
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.paymentSubtitle}
                      >
                        • Desconto de {paymentTypes[selectedMatricula?.registrationid]["Boleto_bancario"]}%
                      </Typography>
                    )}
                    <Tooltip title='Clique para abrir o modal com mais informações'>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.paymentKnowMore}
                        onClick={() => {
                          setOpenModalKnowMoreSimplePayment(true)
                        }}
                      >
                        • Conhecer mais...
                      </Typography>
                    </Tooltip>
                  </div>
                }
                disableTypography
              />

              <Button
                size='medium'
                variant='outlined'
                color='primary'
                className={
                  isSimplePayment()
                    ? classes.buttonSuccess
                    : classes.buttonPrimary
                }
                onClick={openSimplePayment}
              >
                {isSimplePayment() ? 'SELECIONADO' : 'SELECIONAR'}
              </Button>

            </ListItem>
          )}

          {(!cartaoLock?.mudancaCartao) && (
            <ListItem
              className={
                isRecurrentPayment() ? classes.listItemSuccess : classes.listItem
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <DateRange/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.cardPaymentRecurrentTitle}
                primary={
                  <Typography className={classes.paymentTitle}>
                    Cartão de Crédito Recorrente
                  </Typography>
                }
                secondary={
                  <div>
                    {isLoadingDiscount && <Skeleton variant="text" width={100} />}
                    {(paymentTypes[selectedMatricula?.registrationid] && paymentTypes[selectedMatricula?.registrationid]["Cartao_de_credito_recorrente"] > 0) && (
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.paymentSubtitle}
                      >
                        • Desconto de {paymentTypes[selectedMatricula?.registrationid]["Cartao_de_credito_recorrente"]}%
                      </Typography>
                    )}

                    <Tooltip title='Clique para abrir o modal com mais informações'>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.paymentKnowMore}
                        onClick={() => {
                          setOpenModalKnowMoreRecurrencePayment(true)
                        }}
                      >
                        • Conhecer mais...
                      </Typography>
                    </Tooltip>
                  </div>
                }
                disableTypography
              />

              {showCreditCardInfoModalButton && !isExtraSmall && isRecurrentPayment() && (
                <Fragment>
                  <Button
                    size='medium'
                    variant='outlined'
                    color='primary'
                    className={classes.creditCardInfoButton}
                    onClick={openRecurrentPaymentViewOnly}
                  >
                    VISUALIZAR CARTÃO VIGENTE
                  </Button>
                </Fragment>
              )}

              {(!showCreditCardInfoModalButton && showCreditCardInfo) && isRecurrentPayment() && hasLoadedDataCard && !isExtraSmall && (
                <Fragment>
                  <ListItemAvatar className={classes.cardInfo}>
                    <Avatar>
                      <PaymentIcon
                        id={dataCard.brand}
                        style={{width: 100}}
                        className='payment-icon'
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.cardInfo}
                    primary={`Terminado em ${dataCard.last_four_digits}`}
                    secondary={
                      <div>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          className={classes.paymentSubtitle}
                        >
                          {dataCard.brandTitle}
                        </Typography>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          className={classes.paymentSubtitle}
                        >
                          Vencimento: {dataCard.expiration_month}/
                          {dataCard.expiration_year}
                        </Typography>
                      </div>
                    }
                    disableTypography
                  />
                </Fragment>
              )}

              <Button
                size='medium'
                variant='outlined'
                color='primary'
                className={
                  isRecurrentPayment()
                    ? classes.buttonSuccessRecurrent
                    : classes.buttonPrimary
                }
                onClick={openRecurrentPayment}
              >
                {isRecurrentPayment() ? 'ALTERAR CARTÃO' : 'SELECIONAR'}
              </Button>
            </ListItem>
          )}

          {(!daLock?.mudancaDa) && (
            <ListItem
              className={
                isAutomaticDebit() ? classes.listItemSuccess : classes.listItem
              }
            >
              <ListItemAvatar className={classes.listIcon}>
                <Avatar>
                  <CreditCard/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography className={classes.paymentTitle}>
                    Débito Automático
                  </Typography>
                }
                secondary={
                  <div>
                    {isLoadingDiscount && <Skeleton variant="text" width={100} />}
                    {(paymentTypes[selectedMatricula?.registrationid] && paymentTypes[selectedMatricula?.registrationid]["Debito_automatico_unico"] > 0) && (
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.paymentSubtitle}
                      >
                        • Desconto de {paymentTypes[selectedMatricula?.registrationid]["Debito_automatico_unico"]}%
                      </Typography>
                    )}

                    <Tooltip title='Clique para abrir o modal com mais informações'>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.paymentKnowMore}
                        onClick={() => {
                          setOpenModalKnowMoreAutomaticDebit(true)
                        }}
                      >
                        • Conhecer mais...
                      </Typography>
                    </Tooltip>
                  </div>
                }
                disableTypography
              />

              {isAutomaticDebit() && bank && (
                <Fragment>
                  <ListItemAvatar className={classes.cardInfo}>
                    <Avatar>
                      <AccountBalanceIcon/>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.cardInfo}
                    primary={capitalizeString(bank.text)}
                    secondary={
                      <div>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          className={classes.paymentSubtitle}
                        >
                          Agência {data.debito_automatico_agencia}
                        </Typography>
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          className={classes.paymentSubtitle}
                        >
                          Conta {data.debito_automatico_conta}
                        </Typography>
                      </div>
                    }
                    disableTypography
                  />
                </Fragment>
              )}

              <Button
                size='medium'
                variant='outlined'
                color='primary'
                className={
                  isAutomaticDebit()
                    ? classes.buttonSuccessAutomaticDebit
                    : classes.buttonPrimary
                }
                onClick={openAutomaticDebit}
              >
                {isAutomaticDebit() ? 'ALTERAR DADOS BANCÁRIOS' : 'SELECIONAR'}
              </Button>
            </ListItem>
          )}
        </List>
      )}
    </div>
  )
})
