import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseNegotiationSelector = prop('negotiation')

const baseFinanceSelector = pipe(baseNegotiationSelector, prop('finance'))

export const  paymentSelector = pipe(baseFinanceSelector, prop('payment'))

export const paymentDataSelector = getAPIDataSelector(paymentSelector)

export const hasLoadedPaymentSelector = hasLoadedAPISelector(paymentSelector)

export const hasErrorPaymentSelector = hasErrorAPISelector(paymentSelector)

export const isLoadingPaymentSelector = isLoadingAPISelector(paymentSelector)

export const  paymentReceiptSelector = pipe(paymentDataSelector, prop('receipt'))

export const NSUSelector = pipe(paymentReceiptSelector, prop('nsu'))

export const orderNumberSelector = pipe(paymentReceiptSelector, prop('ordernumber'))

export const paymentNegotiationIdSelector = pipe(paymentReceiptSelector, prop('paymentnegotiationid'))

export const tourPaymentSelector = pipe(paymentSelector, prop('tour'))

export const isTourEnabledPaymentSelector = pipe(tourPaymentSelector, prop('enabled'))

export const stepTourPaymentSelector = pipe(tourPaymentSelector, prop('step'))
