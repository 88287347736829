import { makeStyles } from '@material-ui/core'

export default makeStyles(props => ({
  paperScrollPaper: {
    maxHeight: '98%',
  },
  textValue: {
    fontWeight: 'bolder',
    fontSize: '1rem',
    display: 'flex'
  },
  brandIcon: {
    height: 20
  }, 
  receiptRoot: {  
    background: `linear-gradient(rgba(255,255,255,.92), rgba(255,255,255,.92)), url(${props.user.image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 150px',
    '&:first-child': {
      paddingTop: 20,
    },
    '@media print' : {
      '-webkit-print-color-adjust': 'exact',
    }
  },
  noMarginPadding: {
    marginBottom: 0,
    paddingTop: 0
  },
  containerDialog: {
    padding: 20
  },
  summaryTable: {
    width: '100%'
  },
  rowContent: {
    lineHeight: 1
  },
  buttons: {
    marginBottom: 50,
  },
}))