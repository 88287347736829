import { getAllUnityConfiguration } from '../services/unityconfiguration'
import { REQUEST_UNITIES, SUCCESS_UNITIES, ERROR_UNITIES } from '../constants'

export const unitiesSelector = state => state.general.unities

export const requestUnities = () => ({
  type: REQUEST_UNITIES,
})

export const receiveUnities = (data) => ({
  type: SUCCESS_UNITIES,
  payload: data
})

export const error = () => ({
  type: ERROR_UNITIES
})

export const fetchAllUnities = (appId) => (dispatch, getState) => {
  dispatch(requestUnities())
  getAllUnityConfiguration(appId).then(
    result => dispatch(receiveUnities(result)))
    .catch((data) => {
      dispatch(receiveUnities([]))
      dispatch(error(data))    
    })
}
