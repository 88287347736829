import { toLower } from 'lodash'
import { ENTITY_NAME, ENV_URL_DOMAIN } from '../enums/entity'

export const getEnvDomain = (key) => {
  try {
    const hostname = toLower(window.location.hostname)
  
    if (hostname.includes(ENTITY_NAME.SEBSA)) {
      return getENVURL(ENV_URL_DOMAIN[ENTITY_NAME.SEBSA][key])
    }
    else if (hostname.includes(ENTITY_NAME.EINSTEIN)) {
      return getENVURL(ENV_URL_DOMAIN[ENTITY_NAME.EINSTEIN][key])
    }
    else if (hostname.includes(ENTITY_NAME.CONCEPT)) {
      return getENVURL(ENV_URL_DOMAIN[ENTITY_NAME.CONCEPT][key])
    }
    else if (hostname.includes(ENTITY_NAME.LUMINOVA)) {
      return getENVURL(ENV_URL_DOMAIN[ENTITY_NAME.LUMINOVA][key])
    }
    else if (hostname.includes(ENTITY_NAME.LOCALHOST)) {
      return getENVURL(ENV_URL_DOMAIN[ENTITY_NAME.LOCALHOST][key])
    }
    else if (hostname.includes(ENTITY_NAME.HOMOLOG)) {
      return getENVURL(ENV_URL_DOMAIN[ENTITY_NAME.HOMOLOG][key])
    }
    else { 
      return getENVURL(ENV_URL_DOMAIN[ENTITY_NAME.SEBSA][key])
    }
  } catch (e) {
    return getENVURL(ENV_URL_DOMAIN[ENTITY_NAME.SEBSA][key])
  }
}

const getENVURL = (key) => {
  return process.env[key] || ''
}

export const showContacts = () => {
  const hostname = toLower(window.location.hostname)
  return !hostname.includes(ENTITY_NAME.CONCEPT) && !hostname.includes(ENTITY_NAME.EINSTEIN)
}