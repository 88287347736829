import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import CreditCard from '@material-ui/icons/CreditCardOutlined'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar, faReceipt, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import Table from 'components/Table'
import { appIdSelector, accessIdSelector, isTourEnabledSelector, userSelectedSelector, cpfCnpjSelector } from 'modules/Login/selectors/user'
import { set, hide, setWithConfig } from 'layouts/actions'
import PaymentDialog from './components/PaymentDialog/index'
import DetailPayment from './components/DetailPayment'
import { openedDataSelector, isLoadingOpenedSelector } from './selectors/opened'
import { fetchOpened } from './actions'
import ReceiptDialog from '../components/ReceiptDialog'
import { MTableBodyRow } from 'material-table'
import { SnackBillet } from './components/SnackBillet'
import { FormattedMessage } from 'react-intl'
import { STYLE_CELLS } from '../constants/index'
import { isLoadingBilletSelector } from './selectors/billet'
import { billetRequest } from '../services/installment'
import { receiptPayment } from '../services/financial'
import { ERROR_UNEXPECTED } from 'utils/message'
import { apiAnalyticsValue } from 'utils/GTM/action'
import { PAYMENT, OPENED } from './constants'
import CustomCellHighlight from 'components/CustomCellHighlight'
import { isCardWay } from 'modules/Negotiation/Finance/utils'
import { apiResetAction } from 'utils/API/actions/status'
import {useRecoilValue} from "recoil";
import {unidadeGetResponseState} from "../../../recoil/atoms/unidadeGetResponseState";
import {Redirect} from "react-router-dom";
import {PATH_ROUTE} from "../../../routes";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  payButton: {
    width: 32,
    height: 32,
    padding: 5
  },
  payDiscount: {
    margin: 0,
    color: 'green',
    fontWeight: 500
  }
})

const smColumns = (isSmall) => ([
  { title: 'Mês/Ano ', field: 'yearMonthFormatted', cellStyle: { width: isSmall ? '30%' : '10%', paddingRight: 30, paddingLeft: 0 }, customSort: (param1, param2) => Date.parse(param1.expirationdate) - Date.parse(param2.expirationdate) },
  { title: 'Vencimento', field: 'expirationdateFormatted', cellStyle: { width: isSmall ? '30%' : '10%', paddingLeft: 0, paddingRight: 30 }, customSort: (param1, param2) => Date.parse(param1.expirationdate) - Date.parse(param2.expirationdate) }
])

const otherColumns = (classes) => ([
  { title: 'Fatura', field: 'invoice', cellStyle: STYLE_CELLS },
  { title: 'Valor no Vencimento', field: 'documentvalueFormatted', cellStyle: STYLE_CELLS, customSort: (param1, param2) => parseFloat(param1.documentvalue) - parseFloat(param2.documentvalue) },
  // {
  //   title: 'Valor Corrigido',
  //   field: 'correctvalueFormatted',
  //   cellStyle: STYLE_CELLS,
  //   render: (row) => Number(row.correctvalue) < Number(row.documentvalue) ? <p className={classes.payDiscount}>{row.correctvalueFormatted}</p> : row.correctvalueFormatted,
  //   customSort: (param1, param2) => parseFloat(param1.correctvalue) - parseFloat(param2.correctvalue)
  // },
  { title: 'Parcelamento disponível', field: 'enables_installment_payment', render: row => row.enables_installment_payment ? `${row.maximum_installment_number} x` : '---', cellStyle: STYLE_CELLS },
])

const otherMediumColumns = () => ([
  { title: 'Débito Automático', field: 'automaticdebitFormatted', cellStyle: STYLE_CELLS },
  { title: 'Aluno', field: 'studentnameFormatted', cellStyle: STYLE_CELLS },
  { title: 'Situação', field: 'paymentavailabilityFormatted', cellStyle: STYLE_CELLS }
])

const SmallCircularProgress = () => (
  <span style={{ fontSize: 18 }}>
    <CircularProgress size={18} />
  </span>)

const Action = ({ tooltip, className, value, onClick, icon: Icon, id, dataTur, loading }) => {
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          size='small'
          data-tut={Array.isArray(value) ? `${dataTur}-actions` : `${dataTur}-action`}
          className={className}
          id={id}
          disabled={(!Array.isArray(value) && value.tableData.checked) || loading}
          onClick={ev => onClick(ev, value, id)}
          aria-label='action'>
          {loading ? <SmallCircularProgress /> : <Icon />}
        </IconButton>
      </span>
    </Tooltip>)
}

const RenderAction = (value) => act => {
  const { hidden, icon, onClick, tooltip, id, className, dataTur, loading } = act(value)

  if (hidden)
    return null

  return <Action
    dataTur={dataTur}
    key={id}
    icon={icon}
    onClick={onClick}
    tooltip={tooltip}
    id={id}
    loading={loading}
    className={className}
    value={value}
  />
}

const optionsColumn = (actions) => ({
  title: 'Pagar',
  field: 'canPay',
  sorting: false,
  // usa a mesma logica das actions
  render: value => actions.map(RenderAction(value)),
  cellStyle: { minWidth: 90, paddingBottom: 8, paddingTop: 8 }
})

const CustomAction = (props) => RenderAction(props.data)(props.action)

const defaultReceipt = { open: false, correctvalue: 0 }

const Pay = (isConfigure = false) => {
  const classes = useStyles()
  const tableRef = useRef()
  const dispatch = useDispatch()

  const [items, setItems] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [openModalReceipt, setOpenModalReceipt] = useState(defaultReceipt)
  const [loaddingActions, seLoadingActions] = useState([])
  const [isFirst, setIsFirst] = useState(true)

  const isTourOpen = useSelector(isTourEnabledSelector)
  const appid = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const cpfCnpj = useSelector(cpfCnpjSelector)
  const data = useSelector(openedDataSelector)
  const isLoadingData = useSelector(isLoadingOpenedSelector)
  const isLoadingBillet = useSelector(isLoadingBilletSelector)
  const userSelected = useSelector(userSelectedSelector)
  const [isLoadingReceipt, setLoading] = useState(false)
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'))

  const unidade = useRecoilValue(unidadeGetResponseState);



  const title = isSmall
    ? 'Parcelas vencidas'
    : 'Parcelas com vencimento próximo ou vencidas'

  const setMessage = (message) => dispatch(message ? set(message) : hide())

  useEffect(() => {
    if (isFirst) {
      cleanSelection()
      setIsFirst(false)
      if (!isTourOpen) {
        dispatch(apiResetAction(OPENED))
      }
      return
    }
  }, [appid, isTourOpen, data])

  // It control the refresh when change the accessId
  useEffect(() => {
    if (isTourOpen && !isEmpty(data)) {
      return
    }
    dispatch(fetchOpened({ accessId, cpfCnpj }))
    cleanSelection()
  }, [accessId, dispatch, isTourOpen, userSelected])

  const CustomActions = (props) => {
    return (
      <div data-tut='tur-home-actions'>
        {actions(props.components.isTourOpen, true).map(RenderAction(props.data || []))}
      </div>
    )
  }

  const CustomRow = (props) => {
    const selected = (event, path, dataClicked) => {
      if (tableRef.current && tableRef.current?.unity === 0) {
        tableRef.current.unity = props.data.unityid
        tableRef.current.financialType = props.data.paymentWayId
      } else if (dataClicked.tableData.checked && tableRef.current?.dataManager?.selectedCount === 1) {
        cleanSelection()
      }
      props.onRowSelected(event, path, dataClicked)
    }
    return <MTableBodyRow {...props} onRowSelected={selected} data-tur='tut-row-opened' />
  }

  const closeModal = () => {
    setOpenModal(false)
    dispatch(fetchOpened({ accessId, cpfCnpj }))
    cleanSelection()
  }

  const cleanSelection = () => {
    try {
      tableRef.current.unity = 0
      tableRef.current.financialType = undefined
    } catch (e) {
      console.error(e)
    }
  }

  const isHiddenAction = (rowData, isTour, isHeader, check) => {
    if (isTour) {
      return false
    }
    if (Array.isArray(rowData)) {
      return (isHeader && isEmpty(rowData)) || !rowData.some(item => item[check])
    }
    return (!rowData[check])
  }

  const isNegotiation = (rowData, isTour) => {
    if (isTour) {
      return false
    }
    if (Array.isArray(rowData)) {
      return rowData.some(item => item.paymentWayId && isCardWay(item.paymentWayId))
    }
    return rowData.paymentWayId && isCardWay(rowData.paymentWayId)
  }

  const actions = (isTour, isHeader) => {
    return [
      rowData => ({
        dataTur: 'tur-home-pay',
        hidden: isHiddenAction(rowData, isTour, isHeader, 'canPay'),
        icon: CreditCard,
        tooltip: 'Pagar',
        onClick: onPayClick,
        id: 'btn-home-cred-' + (Array.isArray(rowData) ? '' : rowData.tableData.id),
        className: classes.payButton
      }),
      rowData => ({
        dataTur: 'tur-home-bol',
        hidden: isHiddenAction(rowData, isTour, isHeader, 'canPrint') || isNegotiation(rowData, isTour),
        loading: isLoadingBillet && (Array.isArray(rowData) || loaddingActions === rowData.journaltransrecid),
        icon: () => <FontAwesomeIcon icon={faFileInvoiceDollar} />,
        tooltip: 'Gerar Boleto',
        onClick: onDownloadBillet,
        id: 'btn-home-bol-' + (Array.isArray(rowData) ? '' : rowData.tableData.id),
        isFreeAction: false,
        className: classes.payButton,
      }),
      rowData => ({
        dataTur: 'tur-home-bol',
        hidden: isHiddenAction(rowData, isTour, isHeader, 'canPrint') || isNegotiation(rowData, isTour) || tableRef.current?.unity > 0,
        loading: isLoadingBillet && (Array.isArray(rowData) || loaddingActions === rowData.journaltransrecid),
        icon: () => <FontAwesomeIcon icon={faFileInvoice} />,
        tooltip: 'Copiar Linha Digitável',
        onClick: onCopylineBillet,
        id: 'btn-home-bol-' + (Array.isArray(rowData) ? '' : rowData.tableData.id),
        isFreeAction: false,
        className: classes.payButton,
      }),
      rowData => ({
        dataTur: 'tur-home-receipt',
        hidden: (!rowData.viewRecept && !isTour) || isHeader,
        loading: isLoadingReceipt && loaddingActions === rowData.journaltransrecid,
        icon: () => <FontAwesomeIcon icon={faReceipt} style={{ width: 24, height: 24 }} />,
        tooltip: 'Comprovante',
        onClick: onReceipt,
        id: 'btn-gen-receipt-' + (Array.isArray(rowData) ? '' : rowData.tableData.id),
        isFreeAction: false,
        className: classes.payButton,
      }),
    ]
  }

  const columns = (isTour, isSmall) => {
    return [
      ...smColumns(isSmall),
      ...(isSmall ? [] : otherColumns(classes)),
      ...(isMedium ? [] : otherMediumColumns()),
      optionsColumn(actions(isTour, false))
    ]
  }

  const onCopylineBillet = async (event, item, id) => {
    const items = Array.isArray(item) ? item : [item]

    const params = {
      appid,
      journaltransrecids: items.map(i => i.journaltransrecid),
      linhaDigitavel: "true"
    }

    if (!Array.isArray(item)) {
      seLoadingActions(item.journaltransrecid);
    }

    dispatch(billetRequest(params, cpfCnpj)).then(result => {
      const { fileurl: url, errors, linhadigitavelcopia } = result
      if (linhadigitavelcopia) {
        navigator.clipboard.writeText(linhadigitavelcopia);
        dispatch(set('Linha Digitável Copiada com sucesso!'));
      } else {
        dispatch(set('Erro ao copiar linha digitável!'));
      }
    })
  }

  const onDownloadBillet = async (event, item, id) => {
    const items = Array.isArray(item) ? item : [item]

    const params = {
      appid,
      journaltransrecids: items.map(i => i.journaltransrecid)
    }

    if (!Array.isArray(item)) {
      seLoadingActions(item.journaltransrecid)
    }

    dispatch(billetRequest(params, cpfCnpj)).then(result => {
      const { fileurl: url, errors } = result
      if (url) {
        const button = document.getElementById(id)
        const listener = () => {
          window.open(url, '_blank', 'noopener')
        }
        if (Array.isArray(item)) {
          dispatch(setWithConfig({
            customMessage: () => <FormattedMessage id='opened-payment-billet' values={{ count: item.length }} />,
            autoHideDuration: null,
            action: SnackBillet
          }))
        }

        button.addEventListener('click', listener, false)
        button.dispatchEvent(new CustomEvent('click'))
        button.removeEventListener('click', listener, false)
        seLoadingActions([])
      } else if (!isEmpty(errors)) {
        dispatch(set(errors[0].message))
      }
    })
  }

  const onPayClick = async (event, items) => {
    dispatch(apiAnalyticsValue(PAYMENT, Array.isArray(items) ? items.length : 1))
    setItems(Array.isArray(items) ? items : [items])
    setOpenModal(true)
  }

  const onReceipt = async (event, item) => {

    seLoadingActions(item.journaltransrecid)
    setLoading(true)

    const params = {
      appId: appid,
      referenceId: item.referenceid,
      cpfCnpj: cpfCnpj,
      receiptNumber: item.receipt_number
    };

    dispatch(receiptPayment(params))
      .then(() => {
        setOpenModalReceipt({ open: true, correctvalue: item.correctvalue })
        setLoading(false)
      })
      .catch(() => {
        dispatch(set(ERROR_UNEXPECTED))
        setLoading(false)
      })
  }

  const isNotSameUnity = (rowData) => {
    return tableRef.current && tableRef.current?.unity > 0 && rowData.unityid !== tableRef.current?.unity
  }

  const isNotSameAgreement = (rowData) => {
    if (tableRef.current) {
      return (tableRef.current?.financialType && rowData.paymentWayId !== tableRef.current.financialType)
        || (!tableRef.current?.financialType && rowData.paymentWayId && tableRef.current?.unity > 0)
    }
    return false
  }

  // if (unidade && unidade.id === "c31ffd9a-1d4f-ed11-bba3-000d3a88f03a") {
  //   return (
  //     <Alert severity="warning">
  //       Portal financeiro bloqueado temporariamente. Por favor, tente novamente mais tarde.
  //     </Alert>
  //   )
  // }

  return (
    <div data-tut='tur-table-pay' >
      <PaymentDialog
        open={openModal}
        items={items}
        onError={setMessage}
        onClose={closeModal} />
      <ReceiptDialog
        {...openModalReceipt}
        onClose={() => setOpenModalReceipt(defaultReceipt)}
      />
      <Table
        isLoading={isLoadingData}
        tableRef={tableRef}
        components={{
          isTourOpen: isTourOpen,
          Action: CustomAction,
          Actions: CustomActions,
          Cell: (props) => <CustomCellHighlight {...props} tableref={tableRef} />,
          Row: CustomRow
        }}
        options={{
          showTextRowsSelected: false,
          selectionProps: (rowData) => {
            return {
              'data-tut': 'tur-home-check-pay',
              disabled: !rowData.canPay || isNotSameUnity(rowData) || isNotSameAgreement(rowData),
              color: 'primary',
              id: 'pay-select-all-' + rowData.tableData.id,
              style: { padding: 0, maxWidth: '0.5em' }
            }
          },
          showSelectAllCheckbox: false,
          selection: true,
          headerStyle: {
            paddingRight: 1,
            padding: '16px 0px',
          }
        }}
        columns={columns(isTourOpen, isSmall)}
        data={data}
        title={title}
        detailPanel={[
          (rowData) => ({
            disabled: isTourOpen ? false : !rowData.billetDemonstrativeGroupDetails || rowData.billetDemonstrativeGroupDetails.itens.length === 0,
            tooltip: (rowData.billetDemonstrativeGroupDetails && rowData.billetDemonstrativeGroupDetails.itens.length > 0) || isTourOpen ? 'Detalhes' : '',
            render: rowData => <DetailPayment item={rowData} />
          })
        ]}
      />
    </div>)
}

export default Pay
