import {
  REQUEST_TRY_AGAIN,
  LOGIN
} from '../constants'

import { apiReducer } from 'utils/API'
import { reduceReducers } from 'utils/API/reducer/reducer-utils'

export default reduceReducers(
  apiReducer({
    entity: LOGIN,
  }),
  (state, action) => {
    switch (action.type) {
    case REQUEST_TRY_AGAIN:
      return {
        ...state,
        tryAgain: true,
      }
    default:
      return state
    }
  })

