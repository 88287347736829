import { combineReducers } from 'redux'
import layout from './layouts/reducers'
import admin from './modules/Admin/reducers'
import finance from './modules/Finance/reducers'
import home from './modules/Home/reducers'
import login from './modules/Login/reducers'
import negotiation from './modules/Negotiation/reducers'
import tax from './modules/Tax/reducers'
import general from './utils/request/reducers'
import { REQUEST_LOGOUT } from './modules/Login/constants'

const reducers = combineReducers({
  admin,
  finance,
  general,
  home,
  layout,
  login,
  negotiation,
  tax
})

// used to reset state
const rootReducer = (state, action) => {
  let newState = state
  if (action.type === REQUEST_LOGOUT) {
    // eslint-disable-next-line no-undefined
    newState = undefined
  }

  return reducers(newState, action)
}

export default rootReducer
