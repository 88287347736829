
import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { ACCEPT_TERM_NEGOTIATION } from '../constants'

export default apiReducer({
  entity: ACCEPT_TERM_NEGOTIATION,
  resetActions: [USER_CLEAR],  
})
