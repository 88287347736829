import { uniq } from 'lodash'
import { DASHBOARD } from 'utils/permissions'
import {
  USER_SET_SELECTED,
  USER_CLEAR,
  USER_SET_APP_CONFIGS,
  USER_SET_ACCESS,
  USER_CLEAR_ACCESS,
  USER_STATUS_POPUP,
  USER_REFRESH_TOKEN,
  USER_SET_ADMIN_FLAG,
  USER_DISABLE_CLIENTS_POPUP,
  USER_DISABLE_NOTIFICATION
} from '../constants'

const defaultState = {
  // selected é um appconfig
  selected: null,
  appconfigs: null,
  token: null,
  refreshtoken: null,
  adminFlag: false,

  // config nao é limpo apos 401 e afins
  config: {
    popup: {
      enable: false,
      clientsDisable: []
    },
    notification: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
  case USER_SET_SELECTED:
    return {
      ...state,
      selected: {
        ...state.selected,
        access: undefined,
        ...action.payload,
        // adiciona a role de DASHBOARD para o usuario
        permissions: [...action.payload.permissions, DASHBOARD]
      }
    }
  case USER_CLEAR:
    return {
      ...state,
      ...defaultState,
      config: state.config
    }
  case USER_SET_APP_CONFIGS:
    return {
      ...state,
      appconfigs: action.payload.appconfig,
      token: action.payload.token,
      refreshtoken: action.payload.refreshtoken
    }
  case USER_SET_ACCESS:
    return {
      ...state,
      selected: {
        ...state.selected,
        access: action.payload
      }
    }
  case USER_CLEAR_ACCESS:
    return {
      ...state,
      selected: {
        ...state.selected,
        access: undefined
      }
    }
  case USER_STATUS_POPUP:
    return {
      ...state,
      config: {
        ...state.config,
        popup: {
          ...state.config.popup,
          enable: action.payload,
        }
      }
    }
  case USER_DISABLE_CLIENTS_POPUP:
    return {
      ...state,
      config: {
        ...state.config,
        popup: {
          ...state.config.popup,
          clientsDisable: state.config.popup.clientsDisable ? uniq([...state.config.popup.clientsDisable, action.payload]) : [action.payload]
        }
      }
    }
  case USER_DISABLE_NOTIFICATION:
    return {
      ...state,
      config: {
        ...state.config,
        notification: state.config.notification ? uniq([...state.config.notification, action.payload]) : [action.payload]
      }
    }
  case USER_REFRESH_TOKEN:
    return {
      ...state,
      token: action.payload.token,
      refreshtoken: action.payload.refreshtoken
    }
  case USER_SET_ADMIN_FLAG:
    return {
      ...state,
      adminFlag: action.payload.adminFlag
    }
  default:
    return state
  }
}
