import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseNegotiationSelector = prop('negotiation')

const baseFinanceSelector = pipe(baseNegotiationSelector, prop('finance'))

export const registrationExpiredSelector = pipe(baseFinanceSelector, prop('registrationsExpired'))

export const registrationExpiredDataSelector = getAPIDataSelector(registrationExpiredSelector)

export const hasLoadedRegistrationExpiredSelector = hasLoadedAPISelector(registrationExpiredSelector)

export const hasErrorRegistrationExpiredSelector = hasErrorAPISelector(registrationExpiredSelector)

export const isLoadingRegistrationExpiredSelector = isLoadingAPISelector(registrationExpiredSelector)

export const regristrationExpiratedSelected = pipe(registrationExpiredSelector, prop('dataSelected'))
