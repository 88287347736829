import { map } from 'lodash'
import { appIdSelector } from 'modules/Login/selectors/user'
import { closed } from 'modules/Finance/services/installment'
import { currency } from 'utils/utils'
import moment from 'moment'
import { REQUEST_CLOSED, SUCCESS_CLOSED, ERROR_CLOSED, RESET_CLOSED } from '../constants'

export const requestPaymentClosed = () => ({
  type: REQUEST_CLOSED,
})

export const successPaymentClosed = (payment) => ({
  type: SUCCESS_CLOSED,
  payload: payment
})

export const errorPaymentClosed = (message) => ({
  type: ERROR_CLOSED,
  payload: message
})

export const resetPaymentClosed = () => ({
  type: RESET_CLOSED,
})

export const fetchClosed = (params) => async (dispatch, getState) => {
  dispatch(requestPaymentClosed())
  const appid = appIdSelector(getState())
  return await closed(appid, params).then(result => {
    result.elementsFormatted = map(result.elements, el => ({
      ...el,
      transactionDateFormatted: el.transactionDate ? moment(el.transactionDate).format('MM/YY') : '',
      valueFormatted: currency(el.value),
      valueliquidateFormatted: currency(el.valueliquidate),
      automaticdebitFormated: el.automaticdebit ? 'Sim' : 'Não',
      studentnameFormated: el.studentname ? el.studentname.split(' ')[0] : '---',
      situacaoFormated: 'Liquidado'
    }))

    dispatch(successPaymentClosed(result.elementsFormatted))
  }).catch(error => {
    dispatch(errorPaymentClosed([]))
  })
}
