import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import Chart from 'chart.js'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  canvasContent: {
    padding: '30px 30px 0 0',
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  }
}))

export default ({ data, options, className }) => {
  const chartRef = useRef()
  let myChartRef
  const classes = useStyles()

  useEffect(() => {
    myChartRef = chartRef.current && chartRef.current.getContext('2d')

    new Chart(myChartRef, {
      type: 'bar',
      data: {
        ...data,
      },
      options: {
        ...options
      }
    })
  }, [data, options])

  return (
    <canvas
      className={classNames(classes.canvasContent, className)}
      id='myChart'
      ref={chartRef}
    />
  )
}