import { apiRequestActionType, apiSuccessActionType, apiErrorActionType } from 'utils/API/action-types'

export const USER_SET_SELECTED = 'USER_SET_SELECTED'
export const USER_SET_ACCESS = 'USER_SET_ACCESS'
export const USER_SET_APP_CONFIGS = 'USER_SET_APP_CONFIGS'
export const USER_SET_ADMIN_FLAG = 'USER_SET_ADMIN_FLAG'
export const USER_CLEAR = 'USER_CLEAR'
export const USER_CLEAR_ACCESS = 'USER_CLEAR_ACCESS'
export const USER_DISABLE_NOTIFICATION = 'USER_DISABLE_NOTIFICATION'
export const USER_STATUS_POPUP = 'USER_STATUS_POPUP'
export const USER_DISABLE_CLIENTS_POPUP = 'USER_DISABLE_CLIENTS_POPUP'
export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN'

export const LOGIN = 'LOGIN'
export const REQUEST_LOGIN = apiRequestActionType(LOGIN)
export const RECEIVE_LOGIN_SUCCESS = apiSuccessActionType(LOGIN)
export const RECEIVE_ERROR_LOGIN = apiErrorActionType(LOGIN)

export const LOGINV1 = 'LOGINV1'
export const REQUEST_LOGINV1 = apiRequestActionType(LOGINV1)
export const RECEIVE_LOGIN_SUCCESSV1 = apiSuccessActionType(LOGINV1)
export const RECEIVE_ERROR_LOGINV1 = apiErrorActionType(LOGINV1)

export const LOGOUT = 'LOGOUT'
export const REQUEST_LOGOUT = apiRequestActionType(LOGOUT)
export const REQUEST_TRY_AGAIN = 'REQUEST_TRY_AGAIN_LOGIN'
export const KEY_SSO_ACCESS_TOKEN = 'access_token'
export const SET_LABEL_BUTTON = 'SET_LABEL_BUTTON'
export const KEY_LOGIN_EXTERNAL = 'chave'

const ERROR_DEFAULT = 'Algo inesperado ocorreu.'
const ERROR_LABEL_BUTTON_DEFAULT = 'Tentar novamente?'
export const ERRORS_LOGIN = { ERROR_DEFAULT, ERROR_LABEL_BUTTON_DEFAULT }

