import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { STUDENTS_DECLARATIONS } from '../constants'

export default apiReducer({
  entity: STUDENTS_DECLARATIONS,
  defaultData: {},
  resetActions: [USER_CLEAR],  
})
