import { API_REQUEST_HTTP_METHODS } from '../constants'


export const baseAPIRequest = (apiClient, method, url, data, config) => {
  switch (method) {
    case API_REQUEST_HTTP_METHODS.GET:
      return apiClient.get(url, config)
    case API_REQUEST_HTTP_METHODS.POST:
      return apiClient.post(url, data, config)
    case API_REQUEST_HTTP_METHODS.PUT:
      return apiClient.put(url, data, config)
    case API_REQUEST_HTTP_METHODS.PATCH:
      return apiClient.patch(url, data, config)
    case API_REQUEST_HTTP_METHODS.DELETE:
      return apiClient.delete(url, config)
    default:
      throw new Error(`Unexpected request method: ${method}`)
  }
}


export const ignoreAPIRequestError = error => {
  if (!error.VALIDATION_ERROR) {
    const { request, response, config } = error
    const isRequestError = Boolean((config && config.url) || request || response)

    if (error) {
      /*eslint-disable no-console */
      console.log(error)
    }

    if (!isRequestError) {
      // In case the error is "undefined"
      throw error
    }
  }
}
