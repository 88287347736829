import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  ckeckoutStepper: {
    padding: theme.spacing(3, 0),
  },
  ckeckoutContent: {
    padding: '0 16px',
    paddingBottom: '16px',
    paddingTop: 12,
    boxShadow: 'none',
    display: 'inline'
  },
  ckeckoutRoot:{
    minHeight: 641
  },
  ckeckoutButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '15px'
  },
  ckeckoutButton: {
    marginLeft: theme.spacing(1),
    minWidth: 157,
    maxWidth: 300,
    height: 36,
  },
  ckeckoutLoading: {
    minHeight: 200
  },
  ckeckoutError: {
    color: 'red'
  },
  checkoutHeader: {
    marginTop: 15
  }
}))
