import React, {Fragment, useEffect, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RecurrencePaymentDialogForm from '../RecurrencePaymentDialogForm'
import RecurrencePaymentDialogSummary from '../RecurrencePaymentDialogSummary'
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from '@material-ui/core'
import { injectIntl } from 'react-intl'
import useStyles from './style'
import { changePaymentTypeToRecurrencePayment } from 'modules/Finance/PaymentTypes/actions'
import TermAgreement from 'modules/Finance/PaymentTypes/components/TermAgreement'
import {
  userCpfCnpjSelector,
} from 'modules/Login/selectors/user'
import InstallmentsAndCards
  from 'modules/Finance/PaymentTypes/components/RecurrencePaymentDialog/components/InstallmentsAndCards'


export default injectIntl(
  ({
     intl: { messages },
     setIsLoading,
     onSuccess,
     onError,
     selectedMatricula,
     setAcceptanceTermObj,
     editable,
     isCardChanging = true,
     selectedInfo,
     onBack,
   }) => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const dispatch = useDispatch()
    const cpfcnpj = useSelector(userCpfCnpjSelector)
    const [isCardChangingFinishResult, setIsCardChangingFinishResult] = useState(false);

    const steps = useMemo(() => {
      let values

      if (isCardChanging || isCardChangingFinishResult) {
        values = {
          0: {
            label: 'Parcelas e Cartões',
            id: 'lbl-mdl-pag-parcelas',
            isVoltar: false,
            isAvancar: false,
            isAlterar: true,
          },
          1: {
            label: 'Dados do Cartão',
            id: 'lbl-mdl-pag-pag',
            isVoltar: false,
          },
          2: {
            label: 'Aceite',
            id: 'lbl-mdl-pag-agr',
            isVoltar: true,
            isAvancar: true,
          },
          3: {
            label: 'Revisão',
            isVoltar: true,
            isAvancar: false,
            isConfirmar: true,
            id: 'lbl-mdl-pag-rev',
          },
          4: {
            label: 'Finalizar',
            isVoltar: false,
            isAvancar: false,
            isConfirmar: false,
            isFinalizar: true,
            id: 'lbl-mdl-pag-fin',
          }
        }
      } else {
        values = {
          0: {
            label: 'Dados do Cartão',
            id: 'lbl-mdl-pag-pag',
            isVoltar: false,
          },
          1: {
            label: 'Aceite',
            id: 'lbl-mdl-pag-agr',
            isVoltar: true,
            isAvancar: true,
          },
          2: {
            label: 'Revisão',
            isVoltar: true,
            isAvancar: false,
            isConfirmar: true,
            id: 'lbl-mdl-pag-rev',
          }
        }
      }

      return values
    }, [isCardChanging])

    const [payment, setPayment] = useState({
      cardName: '',
      cardNumber: '',
      expiryDateMonth: '',
      expiryDateYear: '',
      cvv: '',
      brandType: {},
      isAgreement: false,
      cardType: 'credit',
      cpf: cpfcnpj,
      // cpf: ""
    })

    const [disabled, setDisabled] = useState(false)

    const loading = false
    let error

    const finishPayment = () => {
      setIsLoading(true)

      dispatch(
        changePaymentTypeToRecurrencePayment(
          payment.cardNumber,
          payment.cardName,
          payment.expiryDateMonth,
          payment.expiryDateYear,
          payment.cvv,
          selectedMatricula.unitycode,
          payment.cpf
        )
      )
        .then(() => {
          setIsLoading(false)
          if (isCardChanging) {
            setIsCardChangingFinishResult(true)
            setActiveStep(4)
          } else {
            onSuccess(payment)
          }
        })
        .catch((errorMessage) => {
          onError(errorMessage)
          setIsLoading(false)
          setIsCardChangingFinishResult(false)
          setActiveStep(0)
        })
    }

    const handleNext = () => {
      setDisabled(false)
      setActiveStep(activeStep + 1)
      console.log("handleNext", activeStep + 1)
    }

    const handleBack = () => {
      if (activeStep === 0) {
        onBack()
      } else {
        setActiveStep(activeStep - 1)
      }
    }

    const getStepContent = () => {
      let stepsComponents = {
        0: () => (
          <RecurrencePaymentDialogForm
            payment={payment}
            setPayment={setPayment}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ),
        1: () => (
          <TermAgreement
            setDisabled={setDisabled}
            selectedMatricula={selectedMatricula}
            newPaymentType='Crédito Recorrente'
            setAcceptanceTermObj={setAcceptanceTermObj}
          />
        ),
        2: () => <RecurrencePaymentDialogSummary payments={payment} />
      }

      if (isCardChanging || isCardChangingFinishResult) {
        stepsComponents = {
          0: () => <InstallmentsAndCards
            setIsLoading={setIsLoading}
            selectedMatricula={selectedMatricula}
            selectedInfo={selectedInfo}
          />,
          1: () => (
            <RecurrencePaymentDialogForm
              payment={payment}
              setPayment={setPayment}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          ),
          2: () => (
            <TermAgreement
              setDisabled={setDisabled}
              selectedMatricula={selectedMatricula}
              newPaymentType='Crédito Recorrente'
              setAcceptanceTermObj={setAcceptanceTermObj}
            />
          ),
          3: () => <RecurrencePaymentDialogSummary payments={payment} />,
          4: () => <InstallmentsAndCards
            setIsLoading={setIsLoading}
            selectedMatricula={selectedMatricula}
            selectedInfo={selectedInfo}
          />
        }

        if (isCardChangingFinishResult) {
          return (
            <InstallmentsAndCards
              setIsLoading={setIsLoading}
              selectedMatricula={selectedMatricula}
              selectedInfo={selectedInfo}
            />
          )
        }
      }

      const stepComp = stepsComponents[activeStep];

      if (stepComp) {
        return stepComp() ?? null
      }

      return null;
    }

    const controllActiveStep =
      activeStep !== 2 || loading || (activeStep === 2 && error)
        ? activeStep
        : 3

    return (
      <Grid
        container
        justifyContent='space-between'
        direction='row'
        alignItems='center'
        className={classes.content}
      >
        <Grid item xs={12}>
          <Typography component='h1' variant='h4' align='center'>
            {isCardChanging && activeStep === 0 ? 'Parcelas e Cartões' : 'Crédito Recorrente'}
          </Typography>
          {editable && (
            <Stepper
              activeStep={controllActiveStep}
              className={classes.stepper}
              alternativeLabel
            >
              {Object.keys(steps).map((key) => (
                <Step key={steps[key].label}>
                  <StepLabel id={steps[key].id}>{steps[key].label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
        </Grid>
        <Fragment>
          <Grid item xs={12}>
            {getStepContent()}
          </Grid>
          {editable && (
            <Buttons
              activeStep={activeStep}
              handleBack={handleBack}
              finishPayment={finishPayment}
              disabled={disabled}
              steps={steps}
              handleNext={handleNext}
              handleSuccess={() => onSuccess(payment)}
            />
          )}
        </Fragment>
      </Grid>
    )
  }
)

const Buttons = ({
                   activeStep,
                   handleBack,
                   finishPayment,
                   disabled,
                   steps,
                   handleNext,
                   handleSuccess
                 }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} className={classes.buttons}>
      {steps[activeStep]?.isVoltar && (
        <Button
          id='btn-mdl-pag-vlt'
          onClick={handleBack}
          className={classes.button}
        >
          Voltar
        </Button>
      )}
      {steps[activeStep]?.isConfirmar && (
        <Button
          id='btn-mdl-pag-cfm'
          variant='contained'
          color='primary'
          onClick={finishPayment}
          className={classes.button}
        >
          Confirmar
        </Button>
      )}
      {steps[activeStep]?.isAvancar && (
        <Button
          id='btn-mdl-pag-cfm'
          variant='contained'
          color='primary'
          disabled={!disabled}
          className={classes.button}
          onClick={handleNext}
        >
          Avançar
        </Button>
      )}
      {steps[activeStep]?.isAlterar && (
        <Button
          id='btn-mdl-pag-parcelas'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleNext}
        >
          Alterar Cartão
        </Button>
      )}
      {steps[activeStep]?.isFinalizar && (
        <Button
          id='btn-mdl-pag-fin'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleSuccess}
        >
          Finalizar
        </Button>
      )}
    </Grid>
  )
}
