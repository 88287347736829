import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  termAgreementRoot:{
    maxHeight: 450,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  termAgreementLogo: {
    width: 75,
    margin: 15
  }, 
  termAgreementTitle: {
    textAlign: 'center',
    textDecoration: 'underline',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem'
  },
  termAgreementDialogTitle: {
    padding: '5px 16px'
  },
  termAgreementInstallment: {
    marginBottom: 10
  },
  root: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'grey',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    borderRadius: 4,
  },
  termAgreementFinal: {
    fontSize: 'small',
    fontFamily: 'Arial, sans-serif',
    color: '#000000',
    display: 'flex',
    justifyContent: 'center'
  }
}))
