import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import groupBy from "lodash/groupBy";
import { withRouter, Link, Route } from "react-router-dom";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandMore";
import ExpandMore from "@material-ui/icons/ChevronRight";
import { useRoutesByUserPermissions } from "utils/hooks";
import { isTourEnabledSelector } from "modules/Login/selectors/user";
import Help from "@material-ui/icons/Help";
import { companyInformationDataSelector } from "modules/Negotiation/Finance/selectors/companyInformations";
import {
  userAppUsername,
  userCpfCnpjSelector,
  userEmailSelector,
  userUnitiesSelector,
} from "modules/Login/selectors/user";
import { KEY_ENV_DOMAIN } from "../utils/enums/entity";
import { getEnvDomain } from "../utils/domains/routerSSO";

//TODO: Descomentar
// const jwt = require("jwt-simple"),
//   uuid = require("uuid");

const useStyles = makeStyles((theme) => ({
  subitem: {
    paddingLeft: 35,
  },
  item: {
    marginTop: 0,
  },
  link: {
    color: "inherit",
    display: "block",
    textDecoration: "none",

    '& span': {
      fontSize: 15
    }
  },
  active: {
    color: theme.palette.primary.main,
    "& span": {
      fontWeight: 500,
      fontSize: 15
    },
  },
}));

function OldSchoolMenuLink(props) {
  const {
    id,
    label,
    to,
    exact,
    linkClass,
    linkClassActive,
    itemClass,
    icon: Icon,
    onClick,
    dataTour,
  } = props;

  return (
    <Route path={to} exact={exact}>
      {({ match }) => (
        <Link
          data-tut={dataTour}
          to={to}
          className={clsx(linkClass, match && linkClassActive)}
          onClick={onClick}
        >
          <ListItem button className={itemClass} id={"main-menu-subitem-" + id}>
            {Icon && (
              <ListItemIcon>
                <Icon className={clsx(match && linkClassActive)} />
              </ListItemIcon>
            )}
            <ListItemText primary={label} />
          </ListItem>
        </Link>
      )}
    </Route>
  );
}

function ParentMenu(props) {
  const {
    onClick,
    id,
    icon: Icon,
    name,
    hasChildren,
    opened,
    dataTour,
  } = props;

  return (
    <ListItem
      button
      data-tut={dataTour}
      onClick={onClick}
      id={"main-menu-" + id}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={name} />
      {hasChildren && opened ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
  );
}

const Menu = ({ setOpen }) => {
  const classes = useStyles();

  const [openedItems, setOpendItems] = React.useState({});

  const routes = useRoutesByUserPermissions();

  const isTourOpen = useSelector(isTourEnabledSelector);
  const company = useSelector(companyInformationDataSelector);
  const name = useSelector(userAppUsername);
  const cpfcnpj = useSelector(userCpfCnpjSelector);
  const email = useSelector(userEmailSelector);
  const unities = useSelector(userUnitiesSelector);

  const closeMenu = () => setOpen(false);
  const isMenuOpen = (id) => isTourOpen || openedItems[id];

  const auth = true;

  const toggleMenuItem = (id) => {
    setOpendItems({
      ...openedItems,
      [id]: !openedItems[id],
    });
  };

  if (routes.length < 1) {
    return null;
  }

  const groupedRoutes = groupBy(
    routes.filter((route) => route.showMenu),
    (route) => route.parentId || "noParent"
  );

  const goToHelp = () => {
    // if (company && company.urlZenDesk) {
    //   const payload = {
    //     iat: new Date().getTime() / 1000,
    //     jti: uuid.v4(),
    //     name: name,
    //     email: email,
    //     external_id: cpfcnpj,
    //     // name: "ADENILSON MARTOS",
    //     // email: "martos@sebsa.com.br",
    //     // external_id: "078550598-90",
    //   };
    //
    //   const urlBase = getEnvDomain(KEY_ENV_DOMAIN.ZENDESK);
    //   const key = getEnvDomain(KEY_ENV_DOMAIN.ZENDESK_KEY);
    //
    //   const token = jwt.encode(payload, key);
    //
    //   let url = "";
    //
    //   if (auth) {
    //     url = `${urlBase}/access/jwt?jwt=${token}&return_to=${company.urlZenDesk}`;
    //   } else {
    //     url = company.urlZenDesk;
    //   }
    //
    //   window.open(url, "_blank");
    // }
  };

  return (
    <List data-tut="tur-icon-menu">
      {groupedRoutes.noParent.map(({ id, path, Icon, name, dataTour }) => {
        const hasChildren = groupedRoutes[id];
        //console.log(unities[0]);
        if ((unities[0] == "2604" 
        // || unities[0] == "1017" || unities[0] == "4501"
        // || unities[0] == "1038" || unities[0] == "4502"
        // || unities[0] == "1002" || unities[0] == "3302"
        // || unities[0] == "1003" || unities[0] == "3303"
        // || unities[0] == "1004" || unities[0] == "3304"
        // || unities[0] == "1007" || unities[0] == "3305"
        // || unities[0] == "1008" || unities[0] == "3306"
        // || unities[0] == "1009" || unities[0] == "3307"
        // || unities[0] == "1010" || unities[0] == "3308"
        // || unities[0] == "1011" || unities[0] == "3309"
        // || unities[0] == "1021" || unities[0] == "3311"
        // || unities[0] == "1022" || unities[0] == "3312"
        // || unities[0] == "1023" || unities[0] == "3313"
        // || unities[0] == "1024" || unities[0] == "3314"
        // || unities[0] == "1028" || unities[0] == "3315"
        // || unities[0] == "1035" || unities[0] == "3318"
        // || unities[0] == "1032" || unities[0] == "3316"
        // || unities[0] == "1036" || unities[0] == "3319"
        // || unities[0] == "1033" || unities[0] == "3317"
        // || unities[0] == "1037" || unities[0] == "3320"
        // || unities[0] == "1207" || unities[0] == "4401"
        ) && (id == "negotiation" || id == "paymentTypes") )
        {return}
        return (
          <div
            key={id}
            className={classes.item}
            data-tut={`tur_menu_${id}`}
            id={"main-menu-" + id}
          >
            {hasChildren ? (
              <ParentMenu
                hasChildren={hasChildren}
                opened={openedItems[id]}
                icon={Icon}
                name={name}
                id={id}
                onClick={() => toggleMenuItem(id)}
              />
            ) : (
              <OldSchoolMenuLink
                dataTour={dataTour}
                onClick={closeMenu}
                icon={Icon}
                key={id}
                id={id}
                label={name}
                to={path}
                exact={true}
                linkClass={classes.link}
                linkClassActive={classes.active}
              />
            )}
            {hasChildren && isMenuOpen(id) && (
              <List component="div" disablePadding>
                {groupedRoutes[id].map((sub) => (
                  <OldSchoolMenuLink
                    dataTour={dataTour}
                    onClick={closeMenu}
                    key={sub.id}
                    id={sub.id}
                    label={sub.name}
                    to={sub.path}
                    exact={true}
                    linkClass={classes.link}
                    itemClass={classes.subitem}
                    linkClassActive={classes.active}
                  />
                ))}
              </List>
            )}
          </div>
        );
                
      })}

      <div className={classes.link}>
        <ListItem button id={"menu-help-zen"} onClick={goToHelp}>
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          <ListItemText primary="Ajuda" />
        </ListItem>
      </div>
    </List>
  );
};

export default withRouter(Menu);
