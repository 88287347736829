import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 230

const useStyles = makeStyles(theme => ({
  logo: {
    maxHeight: 50,
    margin: '0 auto'
  },
  appbar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: '#ffffff',
    '@media print': {
      display: 'none'
    },
  },
  appbarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    color: '#ffffff',
    marginLeft: drawerWidth,
    '@media print': {
      display: 'none'
    },
  },
  title: {
    flexGrow: 1,
    marginLeft: 5,
  },
  drawer: {
    '@media print': {
      display: 'none'
    },
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
}))

export default useStyles