import React, { Fragment } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Table from 'components/Table'
import { Typography } from '@material-ui/core'
import { getNameType } from '../utils'
import DialogDelete from './dialogDelete'
import { textIntl } from 'utils/intl'
import { useSelector } from 'react-redux'
import { smsSelector } from '../selectors/sms'

const columns = [
  {
    title: textIntl('admin-send-sms-table-message'),
    field: 'message',
    render: row => <Typography style={{ width: 350 }} noWrap component='div'>{row.message}</Typography>,
    cellStyle: { textAlign: 'Left', paddingRight: 0, paddingLeft: 10 }
  },
  {
    title: textIntl('admin-send-sms-table-type'), field: 'smsType', render: value => getNameType(value.smsType),
    cellStyle: { textAlign: 'Left', paddingRight: 0, paddingLeft: 20 }
  },
]

export default ({ setSelected, selected }) => {
  
  const { data, loaded, loading } = useSelector(smsSelector)
  const [ modal, setModal] = React.useState({
    open: false
  })
  
  const onEditClick = (event, message) => {
    event.stopPropagation()
    setSelected(message)
  }

  const onDeleteClick = (event, message) => {
    event.stopPropagation()
    setModal({ open: true, selected: message })
  }

  return (
    <Fragment>
      <DialogDelete {...modal} setOpen={() => setModal({ open: false })} setSelected={setSelected} />
      <Table
        isLoading={loading}
        title={textIntl('admin-send-sms-table-title')}
        options={{
          selection: false,
          search: true,
          emptyRowsWhenPaging: true,
          sorting: true,
          headerStyle: {
            textAlign: 'left'
          }
        }}
        localization={{
          header: {
            actions: ''
          }
        }}
        actions={[
          rowData => ({
            icon: EditIcon,
            tooltip: 'Editar',
            onClick: onEditClick,
            id: 'message-list-edit-' + rowData.tableData.id
          }),
          rowData => ({
            icon: Delete,
            tooltip: 'Apagar',
            onClick: onDeleteClick,
            id: 'message-list-delete-' + rowData.tableData.id
          }),
        ]}
        columns={columns}
        data={loaded ? data : []} />
    </Fragment >
  )
}

