import { isEqual } from 'lodash'
import { textIntl } from 'utils/intl'

export const SEND_TYPES = {
  EXPIRED: 0,
  EXPIRE: 1,
  GENERAL: 2
}

export const SEND_TYPES_NAME = {
  [SEND_TYPES.EXPIRED]: textIntl('admin-send-sms-expired'),
  [SEND_TYPES.EXPIRE]: textIntl('admin-send-sms-to-expire'),
  [SEND_TYPES.GENERAL]: textIntl('admin-send-sms-general'),
}

export const isGeneralType = (value) => isEqual(value, SEND_TYPES.GENERAL)

export const isExpireType = (value) => isEqual(value, SEND_TYPES.EXPIRE)

export const getNameType = (value) => SEND_TYPES_NAME[value]