import { API_STATUS_TYPES } from 'utils/API/constants'
import { isTourEnabledSelector } from 'modules/Login/selectors/user'
import { isNil, includes, isEmpty } from 'lodash'
import { refreshMock } from 'utils/mock/tour'

export const apiMockRequest = store => next => action => {
  const tourEnabled = isTourEnabledSelector(store.getState())
  
  if (tourEnabled && (
    includes(action.type, API_STATUS_TYPES.ERROR) ||
      (includes(action.type, API_STATUS_TYPES.SUCCESS) && (isNil(action.data) || isEmpty(action.data))))
  ) {
    refreshMock(action.type, store)
  }
  return next(action)
}