import React, { useState } from 'react'
import moment from 'moment'
import { map } from 'lodash'
import { useTheme, Grid, Typography, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { dashboardDataSelector } from '../selectors/dashboard'
import BarGraph from 'components/barChart'
import ModalBarHour from './modalBarHour'
import { FormattedMessage } from 'react-intl'
import { textIntl } from 'utils/intl'

const useStyles = makeStyles(() => ({
  header: {
    padding: 30,
    paddingBottom: 0
  }
}))

export default () => {
  const data = useSelector(dashboardDataSelector)
  const theme = useTheme()
  const [modal, setModal] = useState({
    isOpen: false
  })
  const classes = useStyles()
  const labels = map(data.analyticsByDay, value => moment(value.dateOfReport).format('DD/MM'))
  const valuesReduce = map(data.analyticsByDay, 'visitCount') || []

  return (
    <>
      <ModalBarHour {...modal} handleClose={() => setModal({ isOpen: false })} />
      <Grid container >
        <Grid xs={12} >
          <Typography variant='h6' className={classes.header}>
            <FormattedMessage id='dashboard-dialog-access-total' values={{ number: Number(data.countAnalyticsAccessByDay).toLocaleString() }} />
          </Typography>
          <Typography variant='caption' className={classes.header}>
            <FormattedMessage id='dashboard-dialog-action-hours' />
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <BarGraph
            data={{
              labels: [...labels],
              datasets: [{
                label: textIntl('dashboard-dialog-graph-day'),
                data: [...valuesReduce],
                backgroundColor: theme.palette.primary.main
              }]
            }}
            options={{
              onClick: (event, target) => {
                if (target && target[0] && target[0]._model.label) {
                  setModal({ isOpen: true, info: target[0]._model.label })
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}