import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  content: {
    padding: theme.spacing(2),
    paddingBottom: 100,
    boxShadow: 'none',
    display: 'inline'
  },
  contentStep: {
    // marginTop: 25
  },
  stepper: {
    padding: '14px 0px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  loading: {
    minHeight: 200
  },
  error: {
    color: 'red'
  },
  openWindow: {
    display: 'none'
  },
  buttonRede: {
    width: 124,
    height: 36
  }
}))
