import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseNegotiationSelector = prop('negotiation')

const baseFinanceSelector = pipe(baseNegotiationSelector, prop('finance'))

export const postFinancialSelector = pipe(baseFinanceSelector, prop('postFinancial'))

export const postFinancialDataSelector = getAPIDataSelector(postFinancialSelector)

export const hasLoadedPostFinancialSelector = hasLoadedAPISelector(postFinancialSelector)

export const hasErrorPostFinancialSelector = hasErrorAPISelector(postFinancialSelector)

export const isLoadingPostFinancialSelector = isLoadingAPISelector(postFinancialSelector)

export const postFinancialIdDataSelector = pipe(postFinancialDataSelector, prop('postFinancialId'))
