import { combineReducers } from 'redux'
import financialAgreement from './financialAgreement'
import expirated from './expirated'
import registrationsExpired from './registrations'
import postFinancial from './postFinancial'
import payment from './payment'
import companyInformations from './companyInformations'

export default combineReducers({
  financialAgreement,
  postFinancial,
  payment,
  registrationsExpired,
  expirated,
  companyInformations
})
