import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
} from 'utils/API/selectors'

const baseLayoutSelector = prop('layout')

export const contactSelector = pipe(baseLayoutSelector, prop('contact'))

export const contactDataSelector = getAPIDataSelector(contactSelector)

export const contactStatusSelector = pipe(contactDataSelector, prop('status'))

