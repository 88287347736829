import { pipe, prop } from 'ramda'
import { isEmpty } from 'lodash'
import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'
import { accessIdSelectedSelector } from 'modules/Login/selectors/user'

const baseTaxSelector = prop('tax')

export const studentsSelector = pipe(baseTaxSelector, prop('students'))

export const studentsDataSelector = getAPIDataSelector(studentsSelector)

export const hasLoadedStudentsSelector = hasLoadedAPISelector(studentsSelector)

export const hasErrorStudentsSelector = hasErrorAPISelector(studentsSelector)

export const isLoadingStudentsSelector = isLoadingAPISelector(studentsSelector)

export const isMockStudents = createSelector(
  hasLoadedStudentsSelector,
  hasErrorStudentsSelector,
  studentsDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
)

export const isFetchStudents = createSelector(
  hasLoadedStudentsSelector,
  isLoadingStudentsSelector,
  hasErrorStudentsSelector,
  studentsDataSelector,
  accessIdSelectedSelector,
  (hasLoaded, isLoading,  hasError, data, accessId) =>  {
    let isDifferentAcess = false
    if (!isEmpty(data)) {
      const [ student ] = data
      isDifferentAcess = student && student.clientCode !== accessId
    }
    return (isEmpty(data) && !hasLoaded && !isLoading && !hasError) || isDifferentAcess
  }
)
