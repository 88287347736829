import React from 'react'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import { setSelected, logout } from 'modules/Login/actions'
import { userSelectedSelector, userAppConfigsSelector, adminFlagSelector } from 'modules/Login/selectors/user'
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PersonIcon from '@material-ui/icons/Person'
import Power from '@material-ui/icons/PowerSettingsNew'
import { Typography } from '@material-ui/core'
import { logoutAD } from 'utils/adalConfig'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
    width: 350
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #e2e2e2',
    backgroundColor: '#f1f1f1',
    padding: '6px 5px'
  },
  button: {
    color: theme.palette.primary.main
  },
  selectedAvatar: {
    height: 80,
    width: 80
  },
  selectedItem: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  divider: {
    margin: '10px 0'
  },
}))

function Menu() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const user = useSelector(userSelectedSelector)
  const appconfigs = useSelector(userAppConfigsSelector)
  const dispatch = useDispatch()
  const isAdmin = useSelector(adminFlagSelector)

  function handleToggle() {
    setOpen(prevOpen => !prevOpen)
  }

  function onSelect(appconfig) {
    setOpen(false)
    dispatch(setSelected(appconfig))
  }

  function onExit() {
    if (isAdmin) {
      logoutAD()
    } else {
      dispatch(logout())
    }

  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <div>
        <Tooltip title='Usuário'>
          <IconButton
            data-tut='tur-icon-search-account'
            id={'change-unity-button'}
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleToggle}
            color='inherit'
            ref={anchorRef}
          >
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          placement='bottom-end'>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              timeout={100}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <MenuList>
                      {appconfigs.sort((a, b) => ((a.appid === user.appid) ? -1 : 1) - ((b.appid === user.appid) ? -1 : 1)).map((appconfig, index) => {
                        const isCurrent = appconfig.appid === user.appid
                        return <MenuItem
                          disabled={isCurrent}
                          key={appconfig.appid}
                          id={'change-unity-' + index}
                          onClick={() => onSelect(appconfig)}>
                          <ListItemAvatar >
                            <Avatar className={clsx(isCurrent && classes.selectedItem)}>
                              <PersonIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <Typography noWrap component='span' variant='body2' className={clsx(isCurrent && classes.selectedItem)}>
                            {appconfig.appusername}
                          </Typography>
                        </MenuItem>
                      }
                      )}
                    </MenuList>
                    <div className={classes.buttonContainer}>
                      <Button size='small' color='secondary' onClick={onExit}>
                        Sair do Portal &nbsp;<Power />
                      </Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}

export default withRouter(Menu)
