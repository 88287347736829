import React, {Suspense, useEffect} from 'react'
import clsx from 'clsx'
import '../../css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import {Provider, useDispatch, useSelector} from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom'
import Container from '@material-ui/core/Container'
import {ThemeProvider} from '@material-ui/styles'
import {PersistGate} from 'redux-persist/integration/react'
import VerifyPermissionsAndLoggedUser from '../../components/VerifyPermissionsAndLoggedUser'
import {persistor, store} from '../../configureStore'
import Intl from 'provider/intl'
import {getLocale} from 'utils/utils'
import routes from '../../routes'
import {useRoutesByUserPermissions} from 'utils/hooks'
import theme from '../../styles/theme'
import useStyles from './style'
import UI from '../../layouts/UI'
import AppBar from 'layouts/Appbar'
import {appBarShowSelector} from 'layouts/selectors/appBar'
import {
  appIdSelector,
  appNameSelector,
  userAppUsername,
  userCpfCnpjSelector,
  userEmailSelector,
  userSelectedSelector,
  userUnitiesSelector
} from 'modules/Login/selectors/user'
import {logPageView} from '../../utils/GTM'
import Footer from 'layouts/Footer'
import {addGlobalBot} from 'utils/domains/chat'
import Tour from 'layouts/Tour'
import {
  companyInformationDataSelector,
  hasLoadedCompanyInformationsSelector,
} from "modules/Negotiation/Finance/selectors/companyInformations";
import {getCompanyInformations} from "modules/Negotiation/Finance/services/company";
import PageLoading from "../../components/PageLoading/PageLoading";
import CacheBuster from "../../components/CacheBuster/CacheBuster";
import {ToastContainer} from "react-toastify";
import updateSiteColors from "../../utils/updateSiteColors";
import {hideAppBar, showAppBar} from "../../layouts/actions";
import {useRecoilValue} from "recoil";
import {loginTypeState} from "../../recoil/atoms/loginTypeState";
import loginTypes from "../../utils/loginTypes";
import {useMounted} from '../../hooks/use-mounted';
import moment from "moment-timezone";

moment.tz.setDefault("America/Sao_Paulo");

// TODO: parou de coletar eventos sem motivos
// initializeGtm()

const StatusPage = React.lazy(() => import('../../pages/StatusPage/StatusPage'));

const routeToRouteObject = ({ exact, Component, path, id }) => (
  <Route key={id} exact={exact} component={Component} path={path} />
);

const Routes = () => {
  const routes = useRoutesByUserPermissions();
  return routes.map(routeToRouteObject)
};

function Dashboard() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const isShowAppBar = useSelector(appBarShowSelector);
  return (
    <div>
      <CssBaseline />
      {isShowAppBar && <AppBar />}
      <div className={clsx(!isSmall && isShowAppBar ? classes.contentShift : classes.content)}>
        {isShowAppBar && <div className={classes.drawerHeader} />}
        <Container
          maxWidth={isShowAppBar ? "lg" : false}
          className={classes.container}
        >
          <Suspense fallback={<PageLoading/>}>
            <Routes />
          </Suspense>
        </Container>
      </div>
      {/* <Notification /> */}
      <Tour />
      <Footer />
    </div>
  );
}

const UsePageViews = ({ appconfig }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const appId = useSelector(appIdSelector);
  const unities = useSelector(userUnitiesSelector);
  const name = useSelector(userAppUsername);
  const org = useSelector(appNameSelector);
  const email = useSelector(userEmailSelector);
  const hasLoadedCompany = useSelector(hasLoadedCompanyInformationsSelector);
  const company = useSelector(companyInformationDataSelector);
  const cpf = useSelector(userCpfCnpjSelector);
  const isMobile = window.navigator.userAgent.toLowerCase().includes("mobi");
  const isShowAppBar = useSelector(appBarShowSelector);
  const loginType = useRecoilValue(loginTypeState);
  const isMounted = useMounted();

  React.useEffect(() => {
    switch (loginType) {
      case loginTypes.SMO:
        dispatch(hideAppBar());
        break;
      default:
        dispatch(showAppBar());
        break;

    }
  }, [loginType]);

  React.useEffect(() => {
    if (unities && unities.length > 0 && !location.pathname.includes('login') && !hasLoadedCompany) {
      dispatch(getCompanyInformations(appId, unities[unities.length - 1]));
    }
  }, [unities, location, appconfig, isMobile]);

  React.useEffect(() => {
    if (isMounted()) {
      logPageView(appconfig ? appconfig.appname : "Portal");
    }
  }, [isMounted, appconfig, location])

  if (company && hasLoadedCompany && isShowAppBar && 'dataAreaId' in company && 'name' in company) {
    addGlobalBot(name, email, cpf, company.dataAreaId, company.name)
  }

  return (
      <Switch>
        <VerifyPermissionsAndLoggedUser component={Dashboard}>
            {routes
                .filter((route) => route.public)
                .map((route) => routeToRouteObject(route))}
        </VerifyPermissionsAndLoggedUser>

      <Route
        exact
        path="/status/:code"
        component={StatusPage}
      />
      <Redirect to="/status/404"/>
    </Switch>
  );
};

const Content = () => {
  const appconfig = useSelector(userSelectedSelector);
  const newTheme = theme(appconfig);
  const isShowAppBar = useSelector(appBarShowSelector);

  useEffect(() => {
    updateSiteColors();
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;

        // Evita que a autenticação SSO ocorra com ambiente desatualizado
        if (!loading && !isLatestVersion && isShowAppBar) refreshCacheAndReload();

        return (
          <Suspense fallback={<PageLoading/>}>
            {(!loading && !isLatestVersion) && refreshCacheAndReload()}

            {/*TODO: Habilitar esta feature permite instalar o Web app no dispositivo*/}
            {process.env.REACT_APP_ENABLE_PWA === 'true' && (
              <pwa-install
                install-description="Este site possui a funcionalidade de aplicativo. Instale-o para ter uma experiência aprimorada e de fácil acesso."
                manifest-url="/manifest.json"
                name="Matrícula Digital"
                description="Portal de matrícula digital da SEB."
                icon="/maskable_icon_x384.png"
              ></pwa-install>
            )}

            <ToastContainer
              autoClose={false}
            />

            <ThemeProvider theme={newTheme}>
              <Intl locale={getLocale()}>
                <UI />
                <Router>
                  <UsePageViews appconfig={appconfig} />
                </Router>
              </Intl>
            </ThemeProvider>
          </Suspense>
        )}}
    </CacheBuster>
  );
};

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Content />
    </PersistGate>
  </Provider>
);

export default App;
