import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { injectIntl, FormattedMessage } from 'react-intl'

export const PAYMENT_OPTIONS = {
  CARD: 'card',
  BILLET: 'billet'
}
export default injectIntl(({ intl: { messages } , setValueOption, valueOption }) => {

  const handleChange = event => {
    setValueOption(event.target.value)
  }

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>
        <FormattedMessage id='finance-negotiation-comp-payment-option-title' />
      </FormLabel>
      <RadioGroup aria-label='position' name='position' value={valueOption} onChange={handleChange} row>
        <FormControlLabel
          value={PAYMENT_OPTIONS.BILLET}
          control={<Radio color='primary' />}
          label={messages['finance-negotiation-comp-payment-option-billet']}
          labelPlacement='end'
        />
        <FormControlLabel
          value={PAYMENT_OPTIONS.CARD}
          control={<Radio color='primary' />}
          label={messages['finance-negotiation-comp-payment-option-card']}
          labelPlacement='end'
        />
      </RadioGroup>
    </FormControl>
  )
})
