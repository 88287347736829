export const apenFileNewWindow = (url, id) => {
  if (url) {
    const button = document.getElementById(id)
    const listener = () => {
      window.open(url, '_blank', 'noopener')
    }

    button.addEventListener('click', listener, false)
    button.dispatchEvent(new CustomEvent('click'))
    button.removeEventListener('click', listener, false)
  }

}