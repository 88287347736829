import moment from "moment";
import { isEmpty } from "lodash";
import banks from "modules/Finance/PaymentTypes/components/AutomaticDebitDialog/data/banks.json";

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const currency = (value) => formatter.format(value);

export const getLocale = () => navigator.language;

export const getSchoolYear = (year) => {
  if (!isEmpty(year)) {
    return year;
  }

  return moment().format("YYYY");
};

export const getParamsRouter = (param, location) => {
  const rx = new RegExp("[?&]" + param + "=([^&]+).*$");
  const returnVal = location.search.match(rx);
  const ret = returnVal === null ? "" : returnVal[1];
  return ret;
};

export const capitalizeString = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const getBankByCode = (code) => {
  const bank = banks.find((b) => b.value == code);
  return bank ? bank : null;
};
