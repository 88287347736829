import {useRecoilState} from "recoil";
import {csatViewedListState} from "../recoil/atoms/csatViewedListState";
import {csatShowState} from "../recoil/atoms/csatShowState";

export default function useCsat() {
  const [csatViewedList, setCsatViewedList] = useRecoilState(csatViewedListState);
  const [csatShow, setCsatShow] = useRecoilState(csatShowState);

  const addAnsweredCsat = (name) => {
    setCsatViewedList([
      ...csatViewedList,
      name
    ])
  }


  const handleShowCsat = (name) => {
    setCsatShow(name);
  }

  const isCsatVisible = (name) => {
    return !csatViewedList.includes(name) && name !== '' && csatShow !== '' && name === csatShow;
  }

  return {
    addAnsweredCsat,
    isCsatVisible,
    handleShowCsat
  }
}
