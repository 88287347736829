import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
} from 'utils/API/selectors'

const baseLayoutSelector = prop('layout')

export const accessSelector = pipe(baseLayoutSelector, prop('access'))

export const accessDataSelector = getAPIDataSelector(accessSelector)

export const accessStatusSelector = pipe(accessDataSelector, prop('status'))

