import React, { Fragment, useEffect, useState, useRef } from 'react'
import Tour from 'reactour'
import { useMediaQuery, makeStyles } from '@material-ui/core'
import { findLastKey } from 'lodash'
import { useTheme } from '@material-ui/styles'
import { hasAny } from 'utils/permissions'
import { useSelector, useDispatch } from 'react-redux'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { permissionsSelector, isTourEnabledSelector } from 'modules/Login/selectors/user'
import { tourList } from './constants'
import { deactiveTour } from 'layouts/actions'
import { useHistory } from 'react-router-dom'
import { PATH_ROUTE } from '../../routes'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles({
  rootTour: {
    opacity: 0.7
  }
})

const saveHistorySteps = (step, current, history, tourRef) => {
  if (step.nextTo && step.nextTo !== window.location.pathname) {
    if (!tourRef.current.state.navegation || !tourRef.current.state.navegation[step.nextTo]) {
      const lastNavegation = tourRef.current.state.navegation && tourRef.current.state.navegation[findLastKey(tourRef.current.state.navegation, 'lastTour')]
      tourRef.current.state.navegation = {
        ...tourRef.current.state.navegation,
        [step.nextTo]: {
          initialTour: lastNavegation ? lastNavegation.lastTour + 1 : 0,
          lastTour: current
        }
      }
    }
    history.push(step.nextTo)
  }
}

const setFocusElement = (current, tours, history, tourRef) => {
  if (current && current > 1) {
    const step = tours[current]
    const selector = document.querySelector(step.selector)
    if (selector) {
      window.scrollTo(0, selector.offsetTop + (step.offSetPlus))
    } else {
      setInterval(() => {
        const selector = document.querySelector(step.selector)
        if (selector) {
          window.scrollTo(0, selector.offsetTop + (step.offSetPlus))
          tourRef.current.showStep()
        }
      }, 200)
    }
  }
}

export default () => {
  const isTourOpen = useSelector(isTourEnabledSelector)
  const theme = useTheme()
  const [tours, setTours] = useState([])
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const permissions = useSelector(permissionsSelector)
  const tourRef = useRef()
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    const items = tourList.filter(t => hasAny(permissions, t.permissions))
    setTours(items)
  }, [permissions])

  const closeTour = () => {
    dispatch(deactiveTour())
    enableBodyScroll(document.body)
  }

  const onAfterOpen = target => {
    tourRef.current.gotoStep(0)
    disableBodyScroll(target)
  }

  const onBeforeClose = target => {
    enableBodyScroll(target)
    history.push(PATH_ROUTE.HOME)
  }

  const prevStep = () => {
    const step = tours[tourRef.current.state.current]

    if (step.prevTo) {
      const navegation = tourRef.current.state.navegation[window.location.pathname]
      history.push(step.prevTo)
      if (navegation) {
        tourRef.current.gotoStep(navegation.initialTour)
        return
      }
    }

    if (step && step.prevAction) {
      step.prevAction(history)
    }
    tourRef.current.prevStep()
  }

  const nextStep = () => {
    const step = tours[tourRef.current.state.current]
    saveHistorySteps(step, tourRef.current.state.current, history, tourRef)
    if (step && step.nextAction) {
      step.nextAction(history)
    }
    tourRef.current.nextStep()
  }

  if (tours.length < 1 || isSmall) {
    return null
  }

  return (
    <Fragment>
      <Tour
        ref={tourRef}
        closeWithMask={false}
        accessibilityOptions={{
          showNavigationScreenReaders: false,
        }}
        getCurrentStep={(current) => setFocusElement(current, tours, history, tourRef)}
        onRequestClose={closeTour}
        steps={tours}
        isOpen={isTourOpen}
        maskClassName={classes.rootTour}
        nextButton={<ArrowForwardIcon />}
        prevButton={<ArrowBackIcon style={{ paddingLeft: 85, width: 'auto' }} />}
        disableInteraction={true}
        nextStep={nextStep}
        prevStep={prevStep}
        rounded={5}
        accentColor={theme.palette.primary.main}
        onAfterOpen={onAfterOpen}
        onBeforeClose={onBeforeClose}
        showNumber={false}
        showNavigation={false}
      />
    </Fragment>)
}
