
import { apiReducer } from 'utils/API'
import { sortBy } from 'lodash'
import { USER_CLEAR, USER_SET_ACCESS, USER_CLEAR_ACCESS } from 'modules/Login/constants'
import { HOME_REGISTRATION } from '../constants'

const mapSuccessData = (action, states) => {
  const registrations = action.data ? sortBy(action.data, ['studentname']) : []
  return registrations
}

export default apiReducer({
  entity: HOME_REGISTRATION,
  resetActions: [USER_CLEAR, USER_SET_ACCESS, USER_CLEAR_ACCESS],
  mapSuccessData
})
