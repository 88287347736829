
import {  makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  negociationOptions: {
    padding: '0 20px',
    display: 'flex',
    margin: '10px 0'
  },
  negociationGridButton: {
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  negociationButton: {
    maxHeight: 40
  }
}))
