import { appIdSelector, accessIdSelector } from 'modules/Login/selectors/user'
import { isEmpty } from 'lodash'
import { getHistoryNegotiation } from 'modules/Negotiation/History/service/financial'
import { isFetchInstallmentSelector, isMockInstallmentSelector } from 'modules/Negotiation/History/selectors/installments'
import { openedDataSelector } from 'modules/Finance/Pay/selectors/opened'
import moment from 'moment'

const urlRegex = /financial\/getfinancialagreementhistory/

const addMock = (mock, store) => {
  if (isMockInstallmentSelector(store.getState())) {
    const opened = openedDataSelector(store.getState())
    const [item] = opened
  
    if (!isEmpty(opened)) {
      mock
        .onPost(urlRegex).reply(200, {
          'response': [ response(item) ],
          'message': null
        })
    }
  }
}

const requestApi = (store) => {
  const appId = appIdSelector(store.getState())
  const accessId = accessIdSelector(store.getState())
  store.dispatch(getHistoryNegotiation({ accessId, appId }))
}

const initializeMock = (mock, store) => {
  if (isFetchInstallmentSelector(store.getState())) {
    requestApi(store, mock)
  } else {
    addMock(mock, store)
  }
}

const response = (invoice) => ({
  'CustAccount': 'CPTC-001235',
  'agreementDate': moment(),
  'agreementId': 'CPT-001074',
  'amountAgreement': invoice.correctvalue,
  'custAccountStudent': 'CPTC-000240',
  'listInvoices': [ invoice.invoice ],
  'origin': 'Portal',
  'paymWayId': 'Cartão',
  'studentName': invoice.studentname
})

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}
