import { forEach } from 'lodash'
import { defaultNames } from './registration'
import { isMockInstallment, isFetchInstallment } from 'modules/Finance/Pay/selectors/opened'

import { fetchOpened } from 'modules/Finance/Pay/actions'
import { accessIdSelector, cpfCnpjSelector } from 'modules/Login/selectors/user'

const urlRegex = /installment/

export const addMock = (mock, store) => {
  const elements = []

  const students = defaultNames(store)
  forEach(students, name => elements.push(...response(name)))

  mock
    .onGet(urlRegex).reply(200, {
      'response': {
        'elements': elements
      }
    })
}

export const requestApi = store => {
  const accessId = accessIdSelector(store.getState())
  const cpfCnpj = cpfCnpjSelector(store.getState())
  store.dispatch(fetchOpened({ accessId, cpfCnpj }))
}

const initializeMock = (mock, store) => {
  if (isFetchInstallment(store.getState())) {
    requestApi(store)
  } else if (isMockInstallment(store.getState())) {
    addMock(mock, store)
  }
}

const response = (studentname) => (
  [
    {
      'accord': null,
      'accountresponsible': 'SEBC-057230',
      'automaticdebit': false,
      'billetDemonstrativeGroupDetails': {
        'descricao': null,
        'itens': [{
          'descricao': 'MATERIAL DIDÁTICO - 8º ANO - TARDE',
          'referencia': 'true',
          'valor': 152.83
        }]
      },
      'canPay': true,
      'correctvalue': 100.0000000000000000,
      'dailyinterest': null,
      'dataareaid': 'SEB',
      'digitableline': '03399.88263 37400.000016 69649.101016 6 79970000015283',
      'documentvalue': '152.830000',
      'expirationdate': '2019-09-25T00:00:00',
      'installmentfine': null,
      'internalnumber': '000000169649-1',
      'invoice': '32322342',
      'journaltransrecid': 5644694970,
      'liquidated': null,
      'paymentavailability': 'AVAILABLE',
      'paymentstatus': 'Recieved',
      'paymenttype': 'Bol_Ribeir',
      'punctualitydiscount1': '0.0000000000000000',
      'punctualitydiscount2': '0.0000000000000000',
      'punctualitydiscount3': '0.0000000000000000',
      'referenceid': '2019-08-30SEBC-057230SEB-368381Bol_Ribeir56446949701',
      'registrationid': 'SEB-368381',
      'renegotiation': false,
      'rtacode': null,
      'scholarshipdiscount': '0.0000000000000000',
      'servicedescription': '',
      'servicegroup': '1',
      'studentname': studentname,
      'unityid': '1002',
      'unityname': 'ESCOLA'
    },
    {
      'accord': null,
      'accountresponsible': 'SEBC-057230',
      'automaticdebit': false,
      'canPay': false,
      'correctvalue': 100.0000000000000000,
      'dailyinterest': null,
      'dataareaid': 'SEB',
      'digitableline': '03399.88263 37400.000016 69649.101016 6 79970000015283',
      'documentvalue': '152.830000',
      'expirationdate': '2019-09-25T00:00:00',
      'installmentfine': null,
      'internalnumber': '000000169649-1',
      'invoice': '756834343',
      'journaltransrecid': 5644694970,
      'liquidated': null,
      'paymentavailability': 'WAITING_PAYMENT_PROCESSING',
      'paymentstatus': 'Recieved',
      'paymenttype': 'Bol_Ribeir',
      'punctualitydiscount1': '0.0000000000000000',
      'punctualitydiscount2': '0.0000000000000000',
      'punctualitydiscount3': '0.0000000000000000',
      'referenceid': '2019-08-30SEBC-057230SEB-368381Bol_Ribeir56446949701',
      'registrationid': 'SEB-368381',
      'renegotiation': false,
      'rtacode': null,
      'scholarshipdiscount': '0.0000000000000000',
      'servicedescription': '',
      'servicegroup': '1',
      'studentname': studentname,
      'unityid': '1002',
      'unityname': 'ESCOLA'
    }
  ])

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}
