import { prop, nthArg } from "ramda";

import { API_META_REDUCER_ATTRIBUTES } from "./constants";
import {
  apiErrorActionType,
  apiRequestActionType,
  apiSuccessActionType,
  apiResetActionType,
} from "./action-types";

export const apiInitialState = (defaultData = {}, defaultMeta = {}) => ({
  [API_META_REDUCER_ATTRIBUTES.LOADING]: false,
  [API_META_REDUCER_ATTRIBUTES.LOADED]: false,
  [API_META_REDUCER_ATTRIBUTES.DATA]: defaultData,
  [API_META_REDUCER_ATTRIBUTES.ERROR]: "",
  [API_META_REDUCER_ATTRIBUTES.PAGINATION]: {},
  [API_META_REDUCER_ATTRIBUTES.META]: defaultMeta,
});

export const apiLoadingState = (state, meta) => ({
  ...state,
  [API_META_REDUCER_ATTRIBUTES.LOADING]: true,
  [API_META_REDUCER_ATTRIBUTES.LOADED]: false,
  [API_META_REDUCER_ATTRIBUTES.ERROR]: "",
  [API_META_REDUCER_ATTRIBUTES.META]: meta,
});

export const apiSuccessState = (data, meta) => ({
  [API_META_REDUCER_ATTRIBUTES.LOADING]: false,
  [API_META_REDUCER_ATTRIBUTES.LOADED]: true,
  [API_META_REDUCER_ATTRIBUTES.DATA]: data,
  [API_META_REDUCER_ATTRIBUTES.ERROR]: "",
  [API_META_REDUCER_ATTRIBUTES.PAGINATION]: {},
  [API_META_REDUCER_ATTRIBUTES.META]: meta,
});

export const apiErrorState = (state, error, meta) => ({
  ...state,
  [API_META_REDUCER_ATTRIBUTES.LOADING]: false,
  [API_META_REDUCER_ATTRIBUTES.LOADED]: false,
  [API_META_REDUCER_ATTRIBUTES.ERROR]: error,
  [API_META_REDUCER_ATTRIBUTES.META]: meta,
});

const mapLoadingStateWithDefaultState = nthArg(2);

export const apiReducer = ({
  entity,
  defaultData,
  defaultMeta,
  mapLoadingState = mapLoadingStateWithDefaultState,
  mapSuccessData = prop(API_META_REDUCER_ATTRIBUTES.DATA),
  mapError = prop(API_META_REDUCER_ATTRIBUTES.ERROR),
  resetActions = [],
}) => {
  const defaultState = apiInitialState(defaultData, defaultMeta);

  return (state = defaultState, action) => {
    if (resetActions && resetActions.includes(action.type)) {
      return defaultState;
    }

    switch (action.type) {
      case apiRequestActionType(entity): {
        return apiLoadingState(
          mapLoadingState(action, state, defaultState),
          action[API_META_REDUCER_ATTRIBUTES.META]
        );
      }
      case apiSuccessActionType(entity): {
        const data = mapSuccessData(action, state);
        return apiSuccessState(data, action[API_META_REDUCER_ATTRIBUTES.META]);
      }
      case apiErrorActionType(entity): {
        return apiErrorState(
          mapError(action, state),
          action.error,
          action[API_META_REDUCER_ATTRIBUTES.META]
        );
      }
      case apiResetActionType(entity): {
        return defaultState;
      }
      default: {
        return state;
      }
    }
  };
};
