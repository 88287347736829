import React, { useState, useEffect } from 'react'
import './styles.css'

import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import * as _ambientes from '../../service/validateSignature-service';

const paramsObj = [
  {
    label: 'Unidade: ',
    value: 'school'
  },
  {
    label: 'Estudante: ',
    value: 'name'
  },
  {
    label: 'DOC do Estudante: ',
    value: 'document'
  },
  {
    label: 'Emitido em: ',
    value: 'issued'
  },
  {
    label: 'Expira em: ',
    value: 'expires'
  },
  {
    label: 'Matricula: ',
    value: 'enrollment'
  }
]
const ValidarCarteirinha = () => {
  const [params, setParams] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const signature = searchParams.get('validator');
    searchParams.delete('validator');
    const text = getTextFromParams(searchParams);
    validarCard(text, signature, searchParams);
  }, [])

  const validarCard = async (text, signature, params) => {
    signature = signature.replace(/ /g, '+');
    var isValid = await _ambientes.validateSignature(text, signature);
    if(isValid){
        setParams(params);
        if (params.has('expires')) {
            const expirationTimestamp = parseInt(params.get('expires'));
            const expirationDate = new Date(expirationTimestamp);
            const currentDate = new Date();
            setIsExpired(expirationDate < currentDate);
        }
    }
    setLoading(false);
    return
  }


    const getTextFromParams = (searchParams) => {
        const params = [];
        for (const [key, value] of searchParams) {
            params.push(`${key}=${encodeURIComponent(value)}`);
        }
        return params.join('&');
    }

  const generateTextLine = (label, value) => {
      if (label === 'Emitido em: ' || label === 'Expira em: ') {
          const date = new Date(parseInt(value));
          const month = date.toLocaleString('default', { month: 'long' })
          const formattedDate = `${month.charAt(0).toUpperCase() + month.slice(1)} ${date.getFullYear()}`;
          return (
            <div className='title-value'>
                {label} &nbsp;<div className='card-text'>{formattedDate}</div>
            </div>
        );
    } else if (label === 'DOC do Estudante: ') {
        if(value === 'null')
            return
        const isRg = value.includes('.')
        let mask = '-***-***-**'
        if(isRg)
           mask = '***.***.*'
        return (
            <div className='title-value'>
                {label} &nbsp;<div className='card-text'>{decodeURIComponent(value) + mask}</div>
            </div>
        )
    } else {
        return (
          <div className='title-value'>
            {label} &nbsp;<div className='card-text'>{decodeURIComponent(value)}</div>
          </div>
        )
    }
  }

    return (
      <div className='card-container'>
        <div className='card-validation'>
          {loading && <p>Carregando...</p>}
          {!loading && (
            <div>
              <h2>Resultado da Validação:</h2>
              <div className='icon'>
                <p className={params&& !isExpired ? 'valid' : 'invalid'}>
                  {params && !isExpired ? 'Carteirinha válida! ' : 'Carteirinha inválida! '}
                  {params && !isExpired ? (
                    <CheckCircleOutline color='primary' />
                  ) : (
                    <ErrorOutlineIcon color='error' />
                  )}
                </p>
              </div>
              {params && (
                <div>
                  {isExpired ? (
                    <p className='expired-message'>Esta carteirinha está expirada!</p>
                  ) : (
                    <>
                      <h3>Detalhes da Carteirinha</h3>
                      {paramsObj.map(obj => {
                      return generateTextLine(obj.label, params.get(obj.value))
                      })}
                    </>
                  )}
                  
                </div>
              )}
            </div>
          )}
        </div>
    </div>
  )
}

export default ValidarCarteirinha
