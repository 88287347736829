import FetchStatus from 'utils/fetchStatus'
import { REQUEST_ACCESS, RECEIVE_ACCESS, RESET_ACCESS, ERROR_ACCESS } from '../constants'

const defaultState = {
  status: FetchStatus.INITIAL,
  data: []
}

const accessReducer = (state = defaultState, action) => {
  switch (action.type) {
  case REQUEST_ACCESS:
    return {
      ...state,
      data: [],
      status: FetchStatus.FETCHING
    }
  case RECEIVE_ACCESS:
    return {
      ...state,
      status: FetchStatus.DONE,
      data: action.payload
    }
  case ERROR_ACCESS: 
    return {
      ...state,
      status: FetchStatus.ERROR,
    }
  case RESET_ACCESS: 
    return defaultState
  default:
    return state
  }
}

export default accessReducer
