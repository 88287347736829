import React from 'react'
import { alpha, makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import SearchIcon from '@material-ui/icons/Search'
import { useSelector, useDispatch } from 'react-redux'
import { clearAccess } from 'modules/Login/actions'
import { accessSelector } from 'modules/Login/selectors/user'
import Autocomplete from './components/AutoComplete'
import { Typography } from '@material-ui/core'
import { resetAccess } from 'layouts/actions'

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 250,
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chip: {
    backgroundColor: theme.palette.primary.light,
    color: '#ffffff'
  },
  deleteChip: {
    color: '#ffffff'
  }
}))

const ChipLabel = ({ name, class_ }) => 
  <Typography
    className={class_}
    noWrap 
    component='div' 
    variant='body2' 
    style={{ maxWidth: 200 }}>{name}</Typography>

export default () => {
  const classes = useStyles()
  const access = useSelector(accessSelector)
  const dispatch = useDispatch()
  
  function onClear() {
    dispatch(clearAccess())
    dispatch(resetAccess())
  }

  if(access) {
    return (
      <Chip
        data-tut='tour-home-search-cpf' 
        classes={{
          deleteIcon: classes.deleteChip
        }}
        className={classes.chip}
        label={<ChipLabel name={access.name} />}
        onDelete={onClear}
      />
    )
  }

  return <div data-tut='tour-home-search-cpf' className={classes.search}>
    <div className={classes.searchIcon}>
      <SearchIcon />
    </div>
    <Autocomplete />
  </div>
}