import React from 'react'
import { FINANCIAL_TAX } from 'utils/permissions'
import { PATH_ROUTE } from '../../../routes'
import ContentTour from '../components/ContentTour'
import { textIntl } from 'utils/intl'

export default [
  {
    permissions: [FINANCIAL_TAX],
    selector: '[data-tut="tur-negotitation-history-menu"]',
    prevTo: PATH_ROUTE.NEGOTIATION_FINANCE,
    content: () => (
      <ContentTour
        title={textIntl('tour-history')}
        content={textIntl('tour-history-menu-description')}
      />
    )
  },
  {
    permissions: [FINANCIAL_TAX],
    selector: '[title="Termo do acordo"]',
    nextTo: PATH_ROUTE.TAX,
    content: () => (
      <ContentTour
        title={textIntl('tour-history')}
        content={textIntl('tour-history-term-description')}
      />
    ),
  },
]