import { pipe, prop } from 'ramda'
import { isEmpty } from 'lodash'
import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'
import { createSelector } from 'reselect'

const baseHomeSelector = prop('home')

export const registrationSelector = pipe(baseHomeSelector, prop('registration'))

export const registrationDataSelector = getAPIDataSelector(registrationSelector)

export const hasLoadedRegistrationSelector = hasLoadedAPISelector(registrationSelector)

export const hasErrorRegistrationSelector = hasErrorAPISelector(registrationSelector)

export const isLoadingRegistrationSelector = isLoadingAPISelector(registrationSelector)

export const isMockRegistration = createSelector(
  hasLoadedRegistrationSelector,
  hasErrorRegistrationSelector,
  registrationDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
)

export const isFetchRegistration = createSelector(
  hasLoadedRegistrationSelector,
  isLoadingRegistrationSelector,
  hasErrorRegistrationSelector,
  registrationDataSelector,
  (hasLoaded, isLoading,  hasError, data) =>  isEmpty(data) && !hasLoaded && !isLoading && !hasError
)