import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import useStyles from "./style";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import TermAgreement from "modules/Finance/PaymentTypes/components/TermAgreement";
import { changePaymentTypeToSimpleMode } from "modules/Finance/PaymentTypes/actions/index";

const steps = [
  {
    label: "Aceite",
    id: "lbl-mdl-pag-agr",
    isConfirmar: true,
  },
];

export default injectIntl(
  ({ intl: { messages }, setIsLoading, onSuccess, onError, selectedMatricula, setAcceptanceTermObj }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const finishPayment = () => {
      setIsLoading(true);

      dispatch(changePaymentTypeToSimpleMode())
        .then(() => {
          setIsLoading(false);
          onSuccess();
        })
        .catch((errorMessage) => {
          setIsLoading(false);
          onError(errorMessage);
        });
    };

    const getStepContent = () => {
      switch (activeStep) {
        case 0:
          return <TermAgreement setDisabled={setDisabled} selectedMatricula={selectedMatricula} newPaymentType="Pagamento Básico" setAcceptanceTermObj={setAcceptanceTermObj} />;
        default:
          return;
      }
    };

    const controllActiveStep = activeStep;

    return (
      <Grid
        container
        justify="space-between"
        direction="row"
        alignItems="center"
        className={classes.content}
      >
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            Pagamento Básico
          </Typography>
          <Stepper
            activeStep={controllActiveStep}
            className={classes.stepper}
            alternativeLabel
          >
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel id={step.id}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Fragment>
          <Grid item xs={12} className={classes.contentStep}>
            {getStepContent()}
          </Grid>
          <Buttons activeStep={activeStep} finishPayment={finishPayment} disabled={disabled} />
        </Fragment>
      </Grid>
    );
  }
);

const Buttons = ({ activeStep, finishPayment, disabled }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.buttons}>
      {steps[activeStep].isConfirmar && (
        <Button
          id="btn-mdl-pag-cfm"
          variant="contained"
          color="primary"
          onClick={finishPayment}
          className={classes.button}
          disabled={!disabled}
        >
          Confirmar
        </Button>
      )}
    </Grid>
  );
};
