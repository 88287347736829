import { upperCase, mapValues, camelCase } from 'lodash'

export const API_REQUEST = 'api-request'

export const API_REQUEST_HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

export const API_META_TYPES = {
  DATA: 'DATA',
  PAGINATION: 'PAGINATION',
  META: 'META',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
}

export const API_META_REDUCER_ATTRIBUTES = mapValues(API_META_TYPES, camelCase)


export const API_STATUS_TYPES = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  RESET: 'RESET'
}

export const API_STATUS_ACTION_SUFFIXES = mapValues(API_STATUS_TYPES, upperCase)
