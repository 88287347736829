import { pipe, prop } from 'ramda'

import { API_META_REDUCER_ATTRIBUTES } from './constants'

/**
 * Get API data selector.
 *
 * @param {function} inputSelector - Selector
 * @return {function(state: any): any} Data
 */
export const getAPIDataSelector = inputSelector => pipe(inputSelector, prop(API_META_REDUCER_ATTRIBUTES.DATA))

/**
 * Get API headers selector.
 *
 * @param {function} inputSelector - Selector
 * @return {function(state: any): any} Data
 */
export const getAPIHeadersSelector = inputSelector => pipe(inputSelector, prop(API_META_REDUCER_ATTRIBUTES.HEADERS))

/**
 * Is loading API selector.
 *
 * @param {function} inputSelector - Selector
 * @return {function(state: any): boolean} - Is loading
 */
export const isLoadingAPISelector = inputSelector => pipe(inputSelector, prop(API_META_REDUCER_ATTRIBUTES.LOADING))

/**
 * Has loaded API selector.
 *
 * @param {function} inputSelector - Selector
 * @return {function(state: any): boolean} - Has loaded
 */
export const hasLoadedAPISelector = inputSelector => pipe(inputSelector, prop(API_META_REDUCER_ATTRIBUTES.LOADED))

/**
 * Get API error selector.
 *
 * @param {function} inputSelector - Selector
 * @return {function(state: any): Error} - Error
 */
export const getAPIErrorSelector = inputSelector => pipe(inputSelector, prop(API_META_REDUCER_ATTRIBUTES.ERROR))

/**
 * Has error on API selector.
 *
 * @param {function} inputSelector - Selector
 * @return {function(state: any): Boolean} - Has error
 */
export const hasErrorAPISelector = inputSelector => pipe(getAPIErrorSelector(inputSelector), Boolean)
