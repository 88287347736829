export const contact = {
  'response': [
    {
      'key': 'email',
      'value': 'exemplo@email.com'
    },
    {
      'key': 'telefone',
      'value': '9999999999'
    },
    {
      'key': 'whatsapp',
      'value': '99999999999'
    }
  ],
  'message': null
}
