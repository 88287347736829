import React, { Component } from 'react'
import './styles.css'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true }
  // }

  componentDidCatch(error, errorInfo) {
    // // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // eslint-disable-next-line no-console
    console.group('ErrorBoundary')
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)
    // eslint-disable-next-line no-console
    console.groupEnd()
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className='mui-appbar'>
            <table>
              <tr className='mui--appbar-height'>
                <td className='mui--text-title'>Portal Financeiro</td>
              </tr>
            </table>
          </div>

          <div id='content-wrapper' className='mui--text-center'>
            <div className='mui--appbar-height'></div>
            <div className='mui-imagem'>
              <img width='100%' height='100%' src='/education.jpg' />
            </div>
            <div className='mui-content'>
              <br />
              <br />
              <h2>Bem-vindo ao Portal Financeiro!</h2>
              <br />
              <p>Portal indisponível ou versão não suportada.</p>
              <br />
              <div>
                <p>Verifique os navegadores recomendados:</p>
                <ul>
                  <li><a href='https://www.google.com/intl/pt-BR/chrome/'>Google Chrome</a>, versão superior a 47.</li>
                  <li><a href='https://www.apple.com/br/safari/'>Safari</a>, versão superior a 9.</li>
                  <li><a href='https://www.mozilla.org/pt-BR/firefox/new/'>Firefox</a>, versão superior a 44.</li>
                  <li><a href='https://support.microsoft.com/pt-br/help/17621'>Internet Explorer</a>, versão  superior a 11.</li>
                </ul>
              </div>
            </div>
          </div>
          <script src='//cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js'></script>
        </>
      )
    }

    return this.props.children
  }
}
