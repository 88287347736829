import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  '@global': {
    body: {
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover'
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: theme.spacing(1)
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    padding: 40,
    background: 'white',
    borderRadius: 10,
    maxWidth: '70%',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
