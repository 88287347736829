import { createTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { appBarShowSelector } from '../layouts/selectors/appBar'

const themeDefault = {
  'appcolor': '#37b24a'
}

export default (appconfig) => {
  const isShowAppBar = useSelector(appBarShowSelector)
  let smoColor = '#0068B3';
  switch (process.env['REACT_APP_ENV'] ?? "PRODUÇÃO") {
    case 'LOCALHOST':
      smoColor = '#00d427';
      break;
    case 'DESENVOLVIMENTO':
      smoColor = '#fcba03';
      break;
    case 'HOMOLOGAÇÃO':
      smoColor = '#8b0000';
      break;
    default:
      console.info('PRODUÇÃO')
  }
  return createTheme({
    palette: {
      primary: {
        main: !isShowAppBar ? smoColor : appconfig ? appconfig.appcolor : themeDefault.appcolor,
        headers: '#8e8e8e',
        contrastText: !isShowAppBar ? smoColor : undefined,
        backgroundColor: isShowAppBar ? '#f0f0f1' : 'white',
      },
      secondary: {
        main: '#909090'
      },
      text: {
        primary: '#484848',
        secondary: '#757575',
      },
      background: {
        default: '#f5f5f5',
      },
    },
    user: {
      image: appconfig ? appconfig.image : 'https://www.escolaseb.com.br/newLandingStuff/img/logo-SEBCOC.svg'
    },
    overrides: {
      MuiTableBody: {
        root: {
          '& tr:last-child': {
            '& td': {
              borderBottom: 0
            }
          }
        }
      },
      MuiToolbar: {
        gutters: {
          padding: 0
        },
      },
      MuiButton: {
        containedPrimary: {
          color: '#ffffff'
        },
      },
    }
  })
}
