export const FINANCIAL = 'FINANCIAL'
export const FINANCIAL_ADMIN = 'FINANCIAL_ADMIN'
export const FINANCIAL_PAID_INSTALLMENTS = 'FINANCIAL_PAID_INSTALLMENTS'
export const FINANCIAL_SET_MESSAGE = 'FINANCIAL_SET_MESSAGE'
export const FINANCIAL_PRINT_BILLET = 'FINANCIAL_PRINT_BILLET'
export const FINANCIAL_NEGOTIATION = 'FINANCIAL_NEGOTIATION'
export const PAYMENTS_NEGOTIATION_ADMIN = 'PAYMENTS_NEGOTIATION_ADMIN'
export const FINANCIAL_TAX = 'FINANCIAL_TAX'
export const INSTALLMENT_CONFIGURATION = 'INSTALLMENT_CONFIGURATION'
export const SEND_SMS = 'SEND_SMS'
export const DASHBOARD_ADMIN = 'DASHBOARD_ADMIN'

export const DEBIT_FUNCTION = 'DEBIT_FUNCTION'

// criada e usada somente no front
export const DASHBOARD = 'DASHBOARD'

export function hasAny(permissions, list) {
  return permissions.some(permission => 
    list.some(desired => desired === permission))
}