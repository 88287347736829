import { combineReducers } from 'redux'
import access from './access'
import appBar from './appBar'
import contact from './contact'
import snack from './snack'
import tour from './tour'

export default combineReducers({
  access,
  appBar,
  contact,
  tour,
  ui: combineReducers({ snack }),
})