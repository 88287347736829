import { EventEmitter } from 'fbemitter'
import { isEqual } from 'lodash'
import { refreshPaymentOpen } from 'modules/Finance/Pay/actions'
import { financialAgreementDataSelector } from 'modules/Negotiation/Finance/selectors/financialAgreement'
import { enableTourModalPayment, resetTourModalPayment, stepTourPayment } from 'modules/Negotiation/Finance/actions'
import { InstallmentStatus, InstallmentStatusToLabel } from '../InstallmentStatus'
import { currency } from 'utils/utils'
import { openedDataSelector } from 'modules/Finance/Pay/selectors/opened'
import { expiratedSelectedSelector } from 'modules/Negotiation/Finance/selectors/expirated'
import moment from 'moment'
import { ERROR_HISTORY_NEGOTIATION } from 'modules/Negotiation/History/constants'
import { refreshMock } from '../mock/tour'

export const eventEmitter = new EventEmitter()

export  const EVENTS = {
  ENABLE_TOUR_MODAL: 'ENABLE_TOUR_MODAL',
  RESET_TOUR_MODAL: 'RESET_TOUR_MODAL',
  ADD_MOCKED_INSTALLMENT_OPENED: 'ADD_MOCKED_INSTALLMENT_OPENED'
}

export const EVENTS_ACTIONS = {
  [EVENTS.ENABLE_TOUR_MODAL]: {
    code: EVENTS.ENABLE_TOUR_MODAL,
    callback: (store) => {
      store.dispatch(enableTourModalPayment())
    }
  },
  [EVENTS.RESET_TOUR_MODAL]: {
    code: EVENTS.RESET_TOUR_MODAL,
    callback: (store) => {
      store.dispatch(resetTourModalPayment())
    }
  },
  [EVENTS.SET_STEP_TOUR_MODAL]: {
    code: EVENTS.SET_STEP_TOUR_MODAL,
    callback: (store, props) => {
      store.dispatch(stepTourPayment(props))
    }
  },
  [EVENTS.ADD_MOCKED_INSTALLMENT_OPENED]: {
    code: EVENTS.ADD_MOCKED_INSTALLMENT_OPENED,
    callback: (store) => {

      const opened = openedDataSelector(store.getState())
      const expired = expiratedSelectedSelector(store.getState())
      const financialAgreement = financialAgreementDataSelector(store.getState())
      const [ item ] = opened
      const newItem = {
        ...item,
        yearMonthFormatted: moment().format('MM/YY'),
        expirationdateFormatted: moment().add(3, 'days').format('DD/MM/YYYY'),
        automaticdebitFormatted: 'Não',
        documentvalueFormatted: currency(expired.reduce((prev, curr) => prev + Number(curr.documentvalue), 0)),
        correctvalue: financialAgreement && financialAgreement.list && financialAgreement.list.reduce((prev, curr) => prev + Number(curr.amountCur), 0),
        correctvalueFormatted: currency(financialAgreement && financialAgreement.list && financialAgreement.list.reduce((prev, curr) => prev + Number(curr.amountCur), 0)),
        paymentavailability: InstallmentStatus.WAITING_PAYMENT_PROCESSING,
        paymentavailabilityFormatted: InstallmentStatusToLabel(InstallmentStatus.WAITING_PAYMENT_PROCESSING),
      }
      if (!isEqual(newItem, item)) {
        store.dispatch(refreshPaymentOpen([newItem]))
        refreshMock(ERROR_HISTORY_NEGOTIATION, store)
      }
    }
  }
}

export const onEvent = (code, callback) => eventEmitter.addListener(code, callback)