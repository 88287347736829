import { apiReducer } from 'utils/API'
import { map } from 'lodash'
import {
  SMS_LIST
} from '../constants'

const mapSuccessData = (action, states) => {
  return (
    map(action.data, message => ({
      ...message,
      unities: message.unities
        ? message.unities.filter(i => i.unityId !== null).map(us => us.unityId)
        : []
    }))
  )
}

export default apiReducer({
  entity: SMS_LIST,
  mapSuccessData
})