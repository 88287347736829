//Acess import
import { access } from '../services/access'
import { 
  REQUEST_ACCESS,
  RECEIVE_ACCESS, 
  RESET_ACCESS, 
  UI_APP_BAR_SHOW, 
  UI_APP_BAR_HIDE,
  UI_SNACK_SET, 
  UI_SNACK_HIDE,
  UI_SNACK_SET_CONFIG,
  ACTIVE_TOUR,
  DEACTIVE_TOUR
} from '../constants'

//Acess actions
const requestAccess = () => ({
  type: REQUEST_ACCESS,
})

export const receiveAccess = (access) => ({
  type: RECEIVE_ACCESS,
  payload: access
})

export const resetAccess = () => ({
  type: RESET_ACCESS
})

export const fetchAccess = (appid, inputvalue) => async (dispatch, getState) => {
  dispatch(requestAccess())
  try {
    const result = await access(appid, inputvalue)
    dispatch(receiveAccess(result.elements))
  } catch (data) {
    dispatch(receiveAccess([]))
  }
}

// APP BAR
export const showAppBar = () => ({
  type: UI_APP_BAR_SHOW
})

export const hideAppBar = () => ({
  type: UI_APP_BAR_HIDE,
})

// Snack
export const setWithConfig = (payload) => ({
  type: UI_SNACK_SET_CONFIG,
  payload
})

export const set = (payload) => ({
  type: UI_SNACK_SET,
  payload
})

export const hide = () => ({
  type: UI_SNACK_HIDE,
})

// Tour
export const activeTour = () => ({
  type: ACTIVE_TOUR,
})

export const deactiveTour = () => ({
  type: DEACTIVE_TOUR,
})

// Same actions from above, but with better names
export const setMessageSnackWithConfig = (payload) => ({
  type: UI_SNACK_SET_CONFIG,
  payload
})

export const setMessageSnack = (payload) => ({
  type: UI_SNACK_SET,
  payload
})

export const hideMessageSnack = () => ({
  type: UI_SNACK_HIDE,
})
