import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseFinanceSelector = prop('finance')

export const billetSelector = pipe(baseFinanceSelector, prop('billet'))

export const billetDataSelector = getAPIDataSelector(billetSelector)

export const hasLoadedBilletSelector = hasLoadedAPISelector(billetSelector)

export const hasErrorBilletSelector = hasErrorAPISelector(billetSelector)

export const isLoadingBilletSelector = isLoadingAPISelector(billetSelector)

export const billetFileUrlSelector = pipe(billetDataSelector, prop('fileurl'))
