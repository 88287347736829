import React from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import { Radio, FormControlLabel } from '@material-ui/core'

export function TextCardNumber(props) {
  const { inputRef, mask,  ...other } = props

  return (
    <MaskedInput
      {...other}
      size={19}
      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TextCardNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export const TextCVV = (props) => {
  const { inputRef, mask,  ...other } = props

  return (
    <MaskedInput
      {...other}
      mask={[/\d/, /\d/, /\d/, /\d/]}
      size={4}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TextCVV.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export const CustomRadioComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <div>
    
    <FormControlLabel value={props.label} label={props.label} control={<Radio
      {...props}
    />} />
    {touched[field.name] &&
      errors[field.name] && <div className='error'>{errors[field.name]}</div>}
  </div>
)