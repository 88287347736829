import { apiErrorActionType, apiRequestActionType, apiSuccessActionType } from 'utils/API/action-types'

export const ADMIN_BILLET = 'admin-billet'

export const BILLET_FILTERS = 'BILLET_FILTERS'
export const REQUEST_BILLET_FILTERS = apiRequestActionType(BILLET_FILTERS)
export const SUCCESS_BILLET_FILTERS = apiSuccessActionType(BILLET_FILTERS)
export const ERROR_BILLET_FILTERS = apiErrorActionType(BILLET_FILTERS)

export const UNITIES_ALLOWED = 'UNITIES_ALLOWED'

export const GENERATE_BILLET_FILTERS = 'GENERATE_BILLET_FILTERS'

export const FILTER_SEGMENT = {
  UNITY: 'unity',
  PARENT: 'parent'
}
