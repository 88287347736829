import React, { useState } from 'react'
import classNames from 'classnames'
import { map, reduce, slice,sortBy } from 'lodash'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography, Avatar, Button } from '@material-ui/core'
import avatarBoy from 'assets/img/avatar-boy.svg'
import avatarGirl from 'assets/img/avatar-girl.svg'
import avatarUndefined from 'assets/img/avatar-undefined.png'
import { formatOwnName } from 'utils/formatString.js'
import useStyles from './style'
import ModalServices from '../ModalServices'

const Genders = {
  Unknown: '0',
  Male: '1',
  Female: '2',
}

const GendersAvatar = {
  [Genders.Male]: avatarBoy,
  [Genders.Female]: avatarGirl,
  [Genders.Unknown]: avatarUndefined,
}

const GenderAvatar = ({ gender, className }) => {
  const src = GendersAvatar[gender] || GendersAvatar[Genders.Unknown]
  return <Avatar src={src} className={className} />
}

export default ({ grouped, showMore }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  if (!grouped) {
    return null
  }
  
  const [{ studentname, gender, unity }] = grouped

  const services = sortBy(reduce(grouped ,(prev, curr) => {
    if (curr.services) {
      prev.push(...curr.services)
    }
    return prev
  }, []), 'productname')

  const serviceRegistration = showMore ? slice(services, 0 ,5) : services

  return (
    <Card className={classes.studantCard}>
      <ModalServices open={open} onClose={() => setOpen(false)} grouped={grouped} />
      <CardContent>
        <div className={classes.studantInfo}>
          <Grid container spacing={1} justify='space-evenly'>
            <Grid item xs={12}>
              <GenderAvatar gender={gender} className={classes.studantAvatar} />
              <div className={classes.studantContent}>
                <Typography gutterBottom variant='h6'
                  component='h5' color={'textPrimary'} className={classes.studantName}>
                  {formatOwnName(studentname)}
                </Typography>
                <Typography gutterBottom variant='caption' color={'secondary'} >
                  <div className={classes.studantUnity} >{`UNIDADE: ${unity.name}`}</div>
                </Typography>
                <Typography component='div' color={'textPrimary'}
                  className={classNames(classes.studantServices, !showMore && classes.studantServicesModal)}>
                  {map(serviceRegistration, (st, ii) => <div key={ii} >{st.productname}</div>)}
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.studentButton} xs={12}>
              {showMore &&  services.length > 5 &&
                <Button fullWidth onClick={() => setOpen(true)} className={classes.studantSeeMore} color='primary'>Ver mais</Button>
              }
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>)
}