import { 
  UI_SNACK_SET, 
  UI_SNACK_HIDE,
  UI_SNACK_SET_CONFIG
} from '../constants'

const defaultState = {
  autoHideDuration: 3000,
  open: false,
  message: '',
  customMessage: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
  case UI_SNACK_SET:
    return {
      ...state,
      open: true,
      message: action.payload,
    }
  case UI_SNACK_HIDE:
    return {
      ...state,
      ...defaultState,
    }
  case UI_SNACK_SET_CONFIG: {
    return {
      ...state,
      open: true,
      ...action.payload
    }
  }
  default:
    return state
  }
}