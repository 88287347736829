import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Dialog } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  dialogPaper: {
    backgroundColor: 'initial',
    boxShadow: 'none',
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default ({ loading }) => {
  const classes = useStyles()
  if(!loading) {
    return null
  }
  return (
    <Dialog open={true} onClose={() => {}} classes={{ paper: classes.dialogPaper }}>
      <CircularProgress size={60} className={classes.progress} />
    </Dialog>
  )
}
