import { apiGet } from 'utils/API/actions/request'
import { EXPIRATED_FINANCIALS } from '../constants'

export const expirated = (appid, params) => apiGet({
  entity: EXPIRATED_FINANCIALS,
  url:`/installment/${appid}/getrenegotiationinstallment`,
  config: {
    params
  },
  isChainable: true
})