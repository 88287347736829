/* eslint-disable no-use-before-define */

import React, { useEffect, useState } from 'react'
import { map, mapKeys, find, isEqual, isEmpty } from 'lodash'
import {
  Checkbox,
  TextField,
  makeStyles
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { useSelector } from 'react-redux'
import { fieldToTextField } from 'formik-material-ui'
import { unitiesAllOptionsSelector, unitiesDataSelector } from 'utils/request/selectors/unities'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const useStyles = makeStyles(theme => ({
  tag: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
}))

export default (props) => {

  const classes = useStyles()
  const { form : { setFieldValue } } = props
  const dataAllOptions = useSelector(unitiesAllOptionsSelector)
  const data = useSelector(unitiesDataSelector)
  const textFieldProps = fieldToTextField(props)
  const [ names, setNames ] = useState({})
  const [ values, setValues ] = useState([])
  const [ allSelected, setAllSelected ] = useState(false)

  useEffect(() => {
    setNames(mapKeys(dataAllOptions, ({ siteId }) => siteId))
  }, [dataAllOptions])

  
  useEffect(() => {
    setValues(isEmpty(textFieldProps.value) ? [] : map(textFieldProps.value, id => names[id]))
  }, [textFieldProps.value])
  
  const onChange = (event, target) => {
    if (find(target, ({ siteId }) => isEqual(siteId, 0))) {
      setFieldValue(textFieldProps.name, map(data,'siteId'))
      setAllSelected(true)
      return
    }
    setAllSelected(false)
    setFieldValue(textFieldProps.name, map(target,'siteId'))
  }

  return (
    <Autocomplete
      multiple
      id='checkboxes-tags-demo'
      options={allSelected ? data : dataAllOptions}
      classes={classes}
      disableCloseOnSelect
      getOptionLabel={(option) => option && option.name}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            value={option.siteId}
            checkedIcon={checkedIcon}
            checked={selected}
            color='primary'
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params) => {
        const { onChange, ...rest } = textFieldProps
        return (
          <TextField         
            {...params} 
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
            {...rest}
          />
        )}}
      onChange={onChange}
      value={values}
    />
  )
}