import { mapKeys, mapValues, pick, keys } from 'lodash'

import { ENTITY_VERSION } from 'utils/version'
import { EventEmitter } from 'fbemitter'
import axios from 'axios'

const errorEmitter = new EventEmitter()

export const onError = (code, callback) => errorEmitter.addListener(code, callback)
const version = ENTITY_VERSION['AUTHENTICATION']

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  baseURL: process.env.REACT_APP_API
}

const instance = axios.create(config)

export const REFRESH = 'refresh'

export const setCredentials = (token, refreshToken) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  instance.defaults['refreshToken'] = refreshToken
}

export const clearToken = () => {
  isRefreshing = false
  instance.defaults.headers.common['Authorization'] = undefined
}

const paginationHeaders = {
  'x-page': 'page',
  'x-page-count': 'totalPages',
  'x-page-length': 'size',
  'x-total-records': 'totalItems'
}

const paginationHeadersLength = keys(paginationHeaders).length

export const responseInterceptor = (response) => {

  const pag = pick(response.headers, keys(paginationHeaders))
  if (response.headers && keys(pag).length === paginationHeadersLength) {
    return {
      elements: response.data.response,
      pagination: mapKeys(mapValues(pag, (value) => Number(value)), (value, key) => paginationHeaders[key])
    }
  }
  return response.data.response || response.data
}

let isRefreshing = false
let subscribers = []

const requestRefreshToken = (refreshToken) => {
  return instance.get(process.env.REACT_APP_API + `${version}/authentication/oauth/refresh`, { headers: { 'Authorization': 'bearer ' + refreshToken } })
}

const subscribeTokenRefresh = (cb) => {
  subscribers.push(cb)
}

const onRrefreshed = (token) => {
  subscribers.map(cb => cb(token))
}

export const onResponseError = (request) => {
  const { config } = request
  const originalRequest = config

  if (request.response && request.response.status) {
    if (request.response.status === 401) {

      try {
        if (!isRefreshing) {

          isRefreshing = true
          requestRefreshToken(instance.defaults.refreshToken).then((response) => {
            isRefreshing = false
            onRrefreshed(response.token)
            setCredentials(response.token, response.refreshtoken)
            subscribers = []
            errorEmitter.emit(REFRESH, response)
          }).catch(() => {
            errorEmitter.emit(request.response.status, request)
            return Promise.reject(request)
          })
        }

        if (request.request.responseURL.includes(REFRESH)) {
          errorEmitter.emit(request.response.status, request)
          return Promise.reject(request)
        }

        const requestSubscribers = new Promise(resolve => {
          subscribeTokenRefresh(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(instance(originalRequest))
          })
        })
        return requestSubscribers

      } catch (request) {
        errorEmitter.emit(request.response.status, request)
        return Promise.reject(request)
      }
    }
  }
  return Promise.reject(request)
}

instance.interceptors.response.use(responseInterceptor, onResponseError)

export default instance
