import React from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { Grid, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isLoadingPostFinancialSelector } from 'modules/Negotiation/Finance/selectors/postFinancial'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  ckeckoutButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '15px'
  },
  ckeckoutButton: {
    marginLeft: theme.spacing(1),
    minWidth: 157,
    maxWidth: 300,
    height: 36,
  },
  ckeckoutCancel: {
    marginLeft: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    height: 36,
  },
}))

export default ({ activeStep, error, finish, handleClose, disabled }) => {
  const isLoadingFinance = useSelector(isLoadingPostFinancialSelector)
  const classes = useStyles()
  if (!activeStep.isVoltar && !activeStep.isConfirmar && !activeStep.isFechar) {
    return null
  }
  return (
    <Grid item xs={12} className={classes.ckeckoutButtons} >
      {(activeStep.isVoltar || (error && activeStep.isFechar)) &&
				<Button
				  id='btn-mdl-pag-can'
				  onClick={handleClose}
				  disabled={isLoadingFinance}
				  className={classes.ckeckoutCancel}
				>
				  <FormattedMessage id='finance-negotiation-comp-button-cancel' />
				</Button>
      }
      {activeStep.isConfirmar &&
				<Button
				  id='btn-mdl-pag-cfm'
				  variant='contained'
				  color='primary'
				  onClick={finish}
				  disabled={disabled || isLoadingFinance}
				  className={classes.ckeckoutButton}
				>
				  {isLoadingFinance ? <CircularProgress size={24} /> : activeStep.labelButton}
				</Button>
      }
      {activeStep.isFechar &&
				<Button
				  id='btn-mdl-pag-fch'
				  variant='contained'
				  color='primary'
				  onClick={handleClose}
				  className={classes.ckeckoutButton}
				>
				  <FormattedMessage id='finance-negotiation-comp-button-close' />
				</Button>
      }
    </Grid>
  )
}
