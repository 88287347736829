
import { apiReducer } from 'utils/API'

import { USER_CLEAR } from 'modules/Login/constants'
import { INCOME_TAX_DECLARATIONS } from '../constants'

export default apiReducer({
  entity: INCOME_TAX_DECLARATIONS,
  defaultData: {},
  resetActions: [USER_CLEAR],  
})
