import { PAYMENT_CREDIT_NEGOTIATION, ACTIVE_TOUR_MODAL, STEP_TOUR_MODAL } from '../constants'
import { nthArg } from 'ramda'
import { apiReducer } from 'utils/API'
import { USER_CLEAR } from 'modules/Login/constants'
import { reduceReducers } from 'utils/API/reducer/reducer-utils'
import { RESET_ACCESS } from 'layouts/constants'

const paymentCreditSuccessData = (action, states) => ({
  ...states.data,
  receipt: action.data,
  message: action.data.message
})

const paymentError = (action, states) => ({
  ...states,
  error: true,
  data: {
    ...states.data,
    message: (action.error && action.error.message) || 'Erro! Tente novamente mais tarde.'
  }
})

const payment = reduceReducers(
  apiReducer({
    entity: PAYMENT_CREDIT_NEGOTIATION,
    mapLoadingState: nthArg(1),
    mapSuccessData: paymentCreditSuccessData,
    mapError: paymentError,
    resetActions: [USER_CLEAR, RESET_ACCESS],
  }),
  (states, action) => {
    switch (action.type) {
    case ACTIVE_TOUR_MODAL:
      return {
        ...states,
        tour: {
          ...states.tour,
          enabled: action.data
        }
      }
    case STEP_TOUR_MODAL: {
      return {
        ...states,
        tour: {
          ...states.tour,
          step: action.data
        }
      }
    }
    default:
      return states
    }
  }
)

export default payment
