import { textIntl } from 'utils/intl'
import { isEqual } from 'lodash'

export const MESSAGE_TYPE = {
  POP_UP: '1',
  WINDOW: '2',
}

export const MESSAGE_TYPE_ENUM = {
  [MESSAGE_TYPE.WINDOW]: textIntl('cadastro-messagem-type-notification'),
  [MESSAGE_TYPE.POP_UP]: textIntl('cadastro-messagem-type-pop-up')
}

export const isWindowType = (messagingExhibitionType) => !messagingExhibitionType || isEqual(messagingExhibitionType, MESSAGE_TYPE.WINDOW)

export const isPopUpType = (messagingExhibitionType) => messagingExhibitionType && isEqual(messagingExhibitionType, MESSAGE_TYPE.POP_UP)