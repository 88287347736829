import React from 'react'
import { FINANCIAL_NEGOTIATION } from 'utils/permissions'
import { PATH_ROUTE } from '../../../routes'
import ContentTour from '../components/ContentTour'
import { EVENTS, eventEmitter } from 'utils/eventEmitter'
import { textIntl } from 'utils/intl'

export default [
  {
    permissions: [FINANCIAL_NEGOTIATION],
    selector: '[data-tut="tur-menu-negotiation"]',
    prevTo: PATH_ROUTE.HOME,
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation-menu')}
        content={textIntl('tour-negotiation-menu-description')}
      />
    )
  },
  {
    permissions: [FINANCIAL_NEGOTIATION],
    selector: '[data-tut="tur-negotitation-finance-menu"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation')}
        content={textIntl('tour-negotiation-menu-finance-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL_NEGOTIATION],
    selector: '[data-tut="tur-table-negotiation"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation')}
        content={textIntl('tour-negotiation-installment-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL_NEGOTIATION],
    selector: '[data-tut="tur-do-negotiation"]',
    nextAction: () => {
      eventEmitter.emit(EVENTS.ENABLE_TOUR_MODAL)
    },
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation')}
        content={textIntl('tour-negotiation-select-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL_NEGOTIATION],
    selector: '[data-tur="tut-payment-negotiation"]',
    prevAction: () => {
      eventEmitter.emit(EVENTS.RESET_TOUR_MODAL)
    },
    nextAction: () => {
      eventEmitter.emit(EVENTS.SET_STEP_TOUR_MODAL, 1)
    },
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation')}
        content={textIntl('tour-negotiation-info-pay-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL_NEGOTIATION],
    selector: '[data-tur="tut-payment-negotiation"]',
    prevAction: () => {
      eventEmitter.emit(EVENTS.SET_STEP_TOUR_MODAL, 0)
    },
    nextAction: () => {
      eventEmitter.emit(EVENTS.SET_STEP_TOUR_MODAL, 2)
    },
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation')}
        content={textIntl('tour-negotiation-paymnet-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL_NEGOTIATION],
    selector: '[data-tur="tut-payment-negotiation"]',
    prevAction: () => {
      eventEmitter.emit(EVENTS.SET_STEP_TOUR_MODAL, 1)
    },
    nextAction: () => {
      eventEmitter.emit(EVENTS.SET_STEP_TOUR_MODAL, 3)
      eventEmitter.emit(EVENTS.ADD_MOCKED_INSTALLMENT_OPENED)
    },
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation')}
        content={textIntl('tour-negotiation-term-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL_NEGOTIATION],
    selector: '[data-tur="tut-payment-negotiation"]',
    prevAction: () => {
      eventEmitter.emit(EVENTS.SET_STEP_TOUR_MODAL, 2)
    },
    nextAction: (history) => {
      history.push(PATH_ROUTE.HOME)
      eventEmitter.emit(EVENTS.RESET_TOUR_MODAL)
    },
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation')}
        content={textIntl('tour-negotiation-success-description')}
      />
    ),
  },
  {
    permissions: [FINANCIAL_NEGOTIATION],
    prevAction: (history) => {
      history.push(PATH_ROUTE.NEGOTIATION_FINANCE)
      eventEmitter.emit(EVENTS.SET_STEP_TOUR_MODAL, 3)
      eventEmitter.emit(EVENTS.ENABLE_TOUR_MODAL)
    },
    nextTo: PATH_ROUTE.NEGOTIATION_HISTORY,
    path: PATH_ROUTE.HOME,
    selector: '[data-tur="tut-row-opened"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-negotiation')}
        content={textIntl('tour-negotiation-home-description')}
      />
    ),
  },
]