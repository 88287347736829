import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from 'utils/API/selectors'

const baseNegotiationSelector = prop('negotiation')

const baseHistorySelector = pipe(baseNegotiationSelector, prop('history'))

export const acceptTermSelector = pipe(baseHistorySelector, prop('acceptTerm'))

export const acceptTermDataSelector = getAPIDataSelector(acceptTermSelector)

export const hasLoadedAcceptTermSelector = hasLoadedAPISelector(acceptTermSelector)

export const hasErrorAcceptTermSelector = hasErrorAPISelector(acceptTermSelector)

export const isLoadingAcceptTermSelector = isLoadingAPISelector(acceptTermSelector)
