import { apiReducer } from 'utils/API'
import { USER_CLEAR } from 'modules/Login/constants'
import { PAYMENTS_NEGOTIATION } from '../constants'


export default apiReducer({
  entity: PAYMENTS_NEGOTIATION,
  defaultData: [],
  resetActions: [USER_CLEAR],  
})
