import { isFetchRegistration, isMockRegistration, registrationDataSelector } from 'modules/Home/selectors/registrations'
import { appIdSelector, accessIdSelector } from 'modules/Login/selectors/user'
import { fetchRegistration } from 'modules/Home/services/registration'
import { isEmpty, forEach, map, groupBy, keys } from 'lodash'
import { openedDataSelector } from 'modules/Finance/Pay/selectors/opened'

export const defaultUnity = { 'name': 'ESCOLA' }

const urlRegex = /registration/

export const defaultNames = (store) => {
  if (store) {
    const registration = registrationDataSelector(store.getState())
    if (registration && !isEmpty(registration)) {
      return map(registration, 'studentname')
    }
    const opened = openedDataSelector(store.getState())
    if (opened && !isEmpty(opened)) {
      const group = groupBy(opened, 'studentname')
      return keys(group)
    }
  }

  return ['MARIA']
}

export const addMock = (mock, store) => {
  const elements = []

  const students = defaultNames(store)
  forEach(students, name => elements.push(response(name)))

  mock
    .onGet(urlRegex).reply(200, {
      'response': {
        'elements': elements
      }
    })
}

export const requestApi = (store) => {
  const accessId = accessIdSelector(store.getState())
  const appid = appIdSelector(store.getState())
  store.dispatch(fetchRegistration(appid, accessId))

}

const initializeMock = (mock, store) => {
  if (isFetchRegistration(store.getState())) {
    requestApi(store)
  } else if (isMockRegistration(store.getState())) {
    addMock(mock, store)
  }
}

const response = (studentname) => ({
  'registrationid': 'CDB-0386260',
  'studentid': 'CDBC-011715',
  'studentname': studentname,
  'classid': '9º ANO',
  'serviceid': null,
  'servicename': null,
  'schoolyear': '2020',
  'unitycode': '1401',
  'gender': '0',
  'unity': defaultUnity,
  'services': [
    {
      'registrationid': 'CDB-0386260',
      'productname': 'MATERIAL DIDÁTICO - 9º ANO - TARDE'
    },
    {
      'registrationid': 'CDB-0386260',
      'productname': 'REGULAR - 9º ANO - TARDE'
    },
    {
      'registrationid': 'CDB-0386260',
      'productname': 'REGULAR - 9º ANO - TARDE'
    }
  ]
})

export default {
  addMock,
  requestApi,
  initializeMock,
  urlRegex
}
