import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(0),
  },
  informations: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  paymentDetail:{
    justifyContent: 'flex-end',
    display: 'flex',
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  amount: {
    fontWeight: '700',
    fontSize: '0.875rem'
  },
  amountDetail:{
    '& span': {
      fontSize: '0.875rem',
      fontWeight: 700
    }
  },
  total: {
    fontWeight: '700',
    fontSize: '1rem'
  },
  totalDetail:{
    '& span': {
      fontWeight: 700
    }
  },
  paper: {
    marginBottom: theme.spacing(1)
  },
  toolbar: {
    minHeight: theme.spacing(6),
  },
  itemDetailTitle: {
    color: theme.palette.primary.main,
    fontSize: '0.8rem'
  },
  itemDetailContent: {
    fontSize: '0.75rem'
  },
  detailPanel: {
    display: 'none'
  },
  cardName: {
    textTransform: 'uppercase'
  },
  detail: {
    fontWeight: 'bold'
  },
  details:{
    textAlign: 'left'
  },
  imgBrand: {
    maxWidth: '20%',
    padding: '0.2rem'
  }
}))
