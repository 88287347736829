import { apiGet, apiPost } from 'utils/API/actions/request'
import { RECEIPT } from '../constants'
import { PAYMENT_DEBIT, PAYMENT_CREDIT, CONFIRM_PAYMENT } from '../Pay/constants'
import axios from 'utils/axios'

export const paymentRede = (body, cpfCnpj) => apiPost({
  entity: PAYMENT_CREDIT,
  url: `/financial/paymentgetnet?cpfCnpj=${cpfCnpj}`,
  data: body,
  analytics: {
    info: 'crédito'
  },
  isChainable: true
})

export const paymentDebit = (body, params, cpfCnpj) => apiPost({
  entity: PAYMENT_DEBIT,
  url: `/financial/paymentegetnetdebit?cpfCnpj=${cpfCnpj}`,
  data: body,
  config: {
    params
  },
  isChainable: true
})

export const processdebitpayment = (url, params) => axios.post(url, params, {
  baseURL: '',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    common: undefined
  },
})

export const receiptPayment = (params) => apiGet({
  entity: RECEIPT,
  url: '/financial/receiptgetnetpayment',
  config: {
    params
  },
  isChainable: true
})

export const checkDebit = (appId, orderNumber) => apiGet({
  entity: CONFIRM_PAYMENT,
  url: `/financial/paymentegetnetdebit/check/${appId}/${orderNumber}`,
  isChainable: true
})