import { makeStyles } from '@material-ui/core'
import hexToRgba from 'hex-to-rgba'

export default makeStyles(theme => ({
  studantAvatar: {
    width: 80,
    height: 80,
    margin: '10px auto',
    backgroundColor: '#404041'
  },
  studantServices: {
    fontSize: '0.875rem',
  },
  studantContent: {
    padding: '0 20px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  studantName: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    margin: 0
  },
  studantLoading: {
    margin: '0 auto',
    display: 'block'
  },
  studantCard: {
    height: '100%'
  },
  studantInfo: {
    cursor: 'pointer',
    border: '1px solid #909090',
    padding: '10px',
    boxShadow: '2px 2px 6px #909090',
    borderRadius: '3px',
    height: '100%'
  },
  studantInfoSelected: {
    boxShadow: `6px 5px 8px ${hexToRgba(theme.palette.primary.main, 0.4)}`,
  }
}))