import FetchStatus from 'utils/fetchStatus'
import { REQUEST_UNITIES, SUCCESS_UNITIES, ERROR_UNITIES } from '../constants'

const defaultState = {
  status: FetchStatus.INITIAL,
  data: []
}

const unities = (state = defaultState, action) => {
  switch (action.type) {
  case REQUEST_UNITIES:
    return {
      ...state,
      data: [],
      status: FetchStatus.FETCHING
    }
  case SUCCESS_UNITIES:
    return {
      ...state,
      status: FetchStatus.DONE,
      data: [...state.data, ...action.payload]
    }
  case ERROR_UNITIES: 
    return {
      ...state,
      status: FetchStatus.ERROR,
    }
  default:
    return state
  }
}

export default unities
